/**
 * @name Conformio - Donut Progress
 *
 */

.cf-c-donut-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $cf-color-gray-03;
    min-width: 16rem;
}

.cf-c-donut__title {
    font-size: $cf-font-size-stack;
    text-transform: uppercase;
}

.cf-c-donut__item {
    width: 100px;
    height: 100px;
}

.cf-c-donut__description {
    display: flex;
    align-items: center;
}
