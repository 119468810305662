/**
 * @name Conformio - Sortable table header item
 *
 */

.cf-c-sortable-th {
    @include flex-align-center;

    &.cf-c-sortable-th--has-icon {
        .cf-c-sortable-th__ctn {
            cursor: pointer;
        }
    }

    &.cf-c-sortable-th--text-align-center {
        justify-content: center;

        .cf-c-sortable-th__label {
            text-align: center;
        }
    }
}

.cf-c-sortable-th__ctn {
    @include flex-align-center;
    cursor: default;
}

.cf-c-sortable-th__icon {
    cursor: pointer;
}

.cf-c-sortable-th__label {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    text-align: left;
}
