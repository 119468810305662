.cf-c-about__title {
    font-size: $cf-font-size-lg;
    line-height: $cf-font-size-xxl;
    color: $cf-color-gray-03;
    margin-bottom: $cf-gutter-md;
    word-break: break-all;
}

.cf-c-about__content {
    font-size: $cf-font-size-stack;
    line-height: $cf-font-size-stack * $cf-font-line-height-sm;
    color: $cf-color-gray-03;
    word-break: break-all;

    > a {
        font-weight: bold;
        color: $cf-color-primary;
    }
}

.cf-c-about__legend {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    border-bottom: 1px solid $cf-color-border-main;
}

.cf-c-about__content--update {
    color: $cf-color-primary;
}

.cf-c-about__content--copyright {
    color: $cf-color-primary;
    font-weight: bold;
}

.cf-c-about {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    > iframe {
        width: 100%;
    }
}

.cf-c-about__footer-image {
    height: 40px;
    width: 170px;
    margin-top: $cf-gutter;
    margin-right: $cf-gutter-xl;
}

.cf-c-about__legend-image {
    height: 72px;
    width: 214px;
    margin-top: $cf-gutter;
}
