/**
 * @name Conformio - App tags
 *
 */

.cf-c-app-tags {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    align-content: flex-start;
    padding: $cf-gutter-xs;
    position: relative;
    border: 1px solid $cf-color-gray-02;
    border-radius: $cf-radius-sm;
}

.cf-c-app-tag {
    height: auto !important;
    min-width: 0;
    display: inline-flex;
    align-items: center;
    height: $cf-app-tag-height;
    margin: 0 $cf-gutter-xs $cf-gutter-xs 0;
    border-radius: $cf-radius-xl;
    overflow: hidden;
    position: relative;
    font-size: $cf-font-size-sm;
    background-color: currentColor;
    cursor: default;

    &.cf-c-app-tag--text {
        border-radius: 0;
        border-top: 1px solid $cf-color-gray-01;
        margin-top: 2px;
        width: 100%;

        .cf-c-app-tag__action {
            color: $cf-color-gray-03;
        }
    }

    &.cf-c-app-tag--thin {
        .cf-c-app-tag__ctn {
            font-weight: normal;
            padding: 2px 0;
        }

        button {
            font-weight: normal;
            padding: 2px 0;
            font-size: $cf-font-size-sm;
        }
    }
}

.cf-c-app-tag__ctn,
.cf-c-app-tag__action {
    color: $cf-color-white;
}

.cf-c-app-tag__ctn {
    margin: 0 $cf-gutter-xs;
    line-height: 1.333;
    max-width: 17rem;
    padding: $cf-gutter-xs;
}

.cf-c-app-tag__editable[contentEditable='true'] {
    background-color: #daedff;
    border-radius: 0.75rem;
    padding: 5px !important;
    outline: 0 solid transparent;
    cursor: text;
}

.cf-c-app-tag__action {
    display: flex;
    width: fit-content;
    box-sizing: content-box;
    height: 100%;
    margin-left: -$cf-gutter-xs;
    padding-right: 0.125rem;
}

.cf-c-app-tag__input-wrapper {
    position: relative;
    align-self: stretch;
    flex: 1 1 auto;
    min-width: 3rem;
    margin-right: $cf-gutter-xs;
    height: 0;
    opacity: 0;
    pointer-events: none;

    &.cf-is-visible {
        min-height: 1.333em;
        margin-bottom: $cf-gutter-xs;
        pointer-events: auto;
        opacity: 1;
    }
}

.cf-c-app-tag__input {
    @include absolute-full;
    @include input-clear;
}

.cf-c-app-tag__input-toggler {
    @include button-clear;
    @include absolute-full;

    &:not(:disabled) {
        cursor: text;
    }

    &:focus {
        outline: none;
    }
}

.cf-c-app-tag__desc {
    margin: 0 $cf-gutter-xs $cf-gutter-xs 0;
    line-height: $cf-font-line-height-sm;
}

.cf-c-app-tag__add-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    cursor: pointer;
}

.cf-c-tag-button-wrapper {
    position: absolute;
    bottom: 0;
    right: $cf-gutter-sm;
}
