/**
 * @name Conformio - Utility Classes
 */

// gaps

.cf-u-p-default {
    padding: $cf-gutter;
}

.cf-u-py-default {
    padding: $cf-gutter 0;
}

.cf-u-py-sm {
    padding: $cf-gutter-sm 0;
}

.cf-u-px-default {
    padding: 0 $cf-gutter;
}

.cf-u-p-none {
    padding: 0px;
}

.cf-u-p-sm {
    padding: $cf-gutter-sm;
}

.cf-u-p-125 {
    padding: 0 $cf-gutter-125 $cf-gutter-125 $cf-gutter-125;
}

.cf-u-p-md {
    padding: $cf-gutter-md;
}

.cf-u-p-xs {
    padding: $cf-gutter-xs;
}

.cf-u-p-lg {
    padding: $cf-gutter-lg;
}

.cf-u-p-xl {
    padding: $cf-gutter-xl;
}

.cf-u-pt-none {
    padding-top: 0;
}

.cf-u-pt-default {
    padding-top: $cf-gutter;
}

.cf-u-pt-xs {
    padding-top: $cf-gutter-xs;
}

.cf-u-pt-sm {
    padding-top: $cf-gutter-sm;
}

.cf-u-pt-lg {
    padding-top: $cf-gutter-lg;
}

.cf-u-pt-xl {
    padding-top: $cf-gutter-xl;
}

.cf-u-pt-md {
    padding-top: $cf-gutter-md;
}

.cf-u-pl-lg {
    padding-left: $cf-gutter-lg;
}

.cf-u-pl-xl {
    padding-left: $cf-gutter-xl;
}

.cf-u-pl-xxl {
    padding-left: $cf-gutter-xxl;
}

.cf-u-pl-sm {
    padding-left: $cf-gutter-sm;
}

.cf-u-pl-md {
    padding-left: $cf-gutter-md;
}

.cf-u-pl-075 {
    padding-left: $cf-gutter-075;
}

.cf-u-pr-075 {
    padding-right: $cf-gutter-075;
}

.cf-u-pr-xxl {
    padding-right: $cf-gutter-xxl;
}

.cf-u-pl-default {
    padding-left: $cf-gutter;
}

.cf-u-pr-default {
    padding-right: $cf-gutter;
}

.cf-u-pr-xl {
    padding-right: $cf-gutter-xl;
}

.cf-u-pr-sm {
    padding-right: $cf-gutter-sm;
}

.cf-u-pr-lg {
    padding-right: $cf-gutter-lg;
}

.cf-u-pb-075 {
    padding-bottom: $cf-gutter-075;
}

.cf-u-pb-default {
    padding-bottom: $cf-gutter;
}

.cf-u-pb-md {
    padding-bottom: $cf-gutter-md;
}

.cf-u-pb-sm {
    padding-bottom: $cf-gutter-sm;
}

.cf-u-pb-xs {
    padding-bottom: $cf-gutter-xs;
}

.cf-u-pb-xxl {
    padding-bottom: $cf-gutter-xxl;
}

.cf-u-pb-lg {
    padding-bottom: $cf-gutter-lg;
}

.cf-u-ph-xl {
    padding-left: $cf-gutter-xl;
    padding-right: $cf-gutter-xl;
}

.cf-u-ph-xxl {
    padding-left: $cf-gutter-xxl;
    padding-right: $cf-gutter-xxl;
}

.cf-u-ph-default {
    padding-left: $cf-gutter;
    padding-right: $cf-gutter;
}

.cf-u-ph-sm {
    padding-left: $cf-gutter-sm;
    padding-right: $cf-gutter-sm;
}

.cf-u-ph-md {
    padding-left: $cf-gutter-md;
    padding-right: $cf-gutter-md;
}

.cf-u-ph-075 {
    padding-left: $cf-gutter-075;
    padding-right: $cf-gutter-075;
}

.cf-u-ph-lg {
    padding-left: $cf-gutter-lg;
    padding-right: $cf-gutter-lg;
}

.cf-u-ph-lg-half {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
}

.cf-u-ph-xs {
    padding-left: $cf-gutter-xs;
    padding-right: $cf-gutter-xs;
}

.cf-u-ph-none {
    padding-left: 0;
    padding-right: 0;
}

.cf-u-pv-lg {
    padding-top: $cf-gutter-lg;
    padding-bottom: $cf-gutter-lg;
}

.cf-u-pv-075 {
    padding-top: $cf-gutter-075;
    padding-bottom: $cf-gutter-075;
}

.cf-u-pv-md {
    padding-top: $cf-gutter-md;
    padding-bottom: $cf-gutter-md;
}

.cf-u-pv-default {
    padding-top: $cf-gutter;
    padding-bottom: $cf-gutter;
}

.cf-u-pv-sm {
    padding-top: $cf-gutter-sm;
    padding-bottom: $cf-gutter-sm;
}

.cf-u-pv-xs {
    padding-top: $cf-gutter-xs;
    padding-bottom: $cf-gutter-xs;
}

.cf-u-ph-none {
    padding-left: 0;
    padding-right: 0;
}

.cf-u-mr-none {
    margin-right: 0;
}

.cf-u-my-auto {
    margin: auto 0;
}

.cf-u-mh-none {
    margin-left: 0;
    margin-right: 0;
}

.cf-u-mh-auto {
    margin-right: auto;
    margin-left: auto;
}

.cf-u-mr-auto {
    margin-right: auto;
}

.cf-u-mr-default {
    margin-right: $cf-gutter;
}

.cf-u-mr-xl {
    margin-right: $cf-gutter-xl;
}

.cf-u-mr-xxl {
    margin-right: $cf-gutter-xxl;
}

.cf-u-mr-lg {
    margin-right: $cf-gutter-lg;
}

.cf-u-ml-lg {
    margin-left: $cf-gutter-lg;
}

.cf-u-ml-auto {
    margin-left: auto;
}

.cf-u-mr-sm {
    margin-right: $cf-gutter-sm;
}

.cf-u-mr-md {
    margin-right: $cf-gutter-md;
}

.cf-u-mr-xs {
    margin-right: $cf-gutter-xs;
}

.cf-u-ml-md {
    margin-left: $cf-gutter-md;
}

.cf-u-ml-default {
    margin-left: $cf-gutter;
}

.cf-u-ml-xs {
    margin-left: $cf-gutter-xs;
}

.cf-u-ml-sm {
    margin-left: $cf-gutter-sm;
}

.cf-u-ml-xl {
    margin-left: $cf-gutter-xl;
}

.cf-u-mb-none {
    margin-bottom: 0;
}

.cf-u-mb-auto {
    margin-bottom: auto;
}

.cf-u-m-default {
    margin: $cf-gutter;
}

.cf-u-m-xs {
    margin: $cf-gutter-xs;
}

.cf-u-my-lg {
    margin-top: $cf-gutter-lg;
    margin-bottom: $cf-gutter-lg;
}

.cf-u-mt-default {
    margin-top: $cf-gutter;
}

.cf-u-mt-none {
    margin-top: 0;
}

.cf-u-mt-auto {
    margin-top: auto;
}

.cf-u-mt-xs {
    margin-top: $cf-gutter-xs;
}

.cf-u-mt-sm {
    margin-top: $cf-gutter-sm;
}

.cf-u-mt-075 {
    margin-top: $cf-gutter-075;
}

.cf-u-mt-md {
    margin-top: $cf-gutter-md;
}

.cf-u-mt-lg {
    margin-top: $cf-gutter-lg;
}

.cf-u-mt-xl {
    margin-top: $cf-gutter-xl;
}

.cf-u-mt-xs-negative {
    margin-top: -$cf-gutter-xs;
}

.cf-u-mt-sm-negative {
    margin-top: -$cf-gutter-sm;
}

.cf-u-mt-md-negative {
    margin-top: -$cf-gutter-md;
}

.cf-u-ml-negative {
    margin-left: -$cf-gutter;
}

.cf-u-mr-negative-sm {
    margin-right: -$cf-gutter-sm;
}

.cf-u-mb-xs-negative {
    margin-bottom: -$cf-gutter-xs;
}

.cf-u-mb-md-negative {
    margin-bottom: -$cf-gutter-md;
}

.cf-u-mh-lg-negative {
    margin-left: -$cf-gutter-lg;
    margin-right: -$cf-gutter-lg;
}

.cf-u-mb-16 {
    margin-bottom: 16px;
}

.cf-u-mb-sm-except-last {
    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }
}

.cf-u-mb-default-except-last {
    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}

.cf-u-ml-default-except-first {
    &:not(:first-child) {
        margin-left: $cf-gutter;
    }
}

.cf-u-ml-default-first {
    > :first-child {
        margin-left: $cf-gutter;
        margin-right: $cf-gutter;
    }
}

.cf-u-mr-sm-except-last {
    &:not(:last-child) {
        margin-right: $cf-gutter-sm;
    }
}

.cf-u-mb-default-except-last {
    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}

.cf-u-lg-default-except-last {
    &:not(:last-child) {
        margin-bottom: $cf-gutter-lg;
    }
}

.cf-u-mb-default {
    margin-bottom: $cf-gutter;
}

.cf-u-mb-md {
    margin-bottom: $cf-gutter-md;
}

.cf-u-mb-075 {
    margin-bottom: $cf-gutter-075;
}

.cf-u-mb-sm {
    margin-bottom: $cf-gutter-sm;
}

.cf-u-mb-xs {
    margin-bottom: $cf-gutter-xs;
}

.cf-u-mb-xl {
    margin-bottom: $cf-gutter-xl;
}

.cf-u-mb-xxl {
    margin-bottom: $cf-gutter-xxl;
}

.cf-u-mb-lg {
    margin-bottom: $cf-gutter-lg;
}

.cf-u-mx {
    margin: 0 $cf-gutter;
}

.cf-u-mx-sm {
    margin-left: $cf-gutter-sm;
    margin-right: $cf-gutter-sm;
}

.cf-u-mx-xs {
    margin-left: $cf-gutter-xs;
    margin-right: $cf-gutter-xs;
}

.cf-u-mx-default {
    margin-left: $cf-gutter;
    margin-right: $cf-gutter;
}

.cf-u-my-sm {
    margin-top: $cf-gutter-sm;
    margin-bottom: $cf-gutter-sm;
}

.cf-u-my-default {
    margin-top: $cf-gutter;
    margin-bottom: $cf-gutter;
}

.cf-u-mx-075-negative {
    margin-left: -$cf-gutter-075;
    margin-right: -$cf-gutter-075;
}

.cf-u-m-none {
    margin: 0;
}

.cf-u-uppercased {
    text-transform: uppercase;
}

.cf-u-capitalized {
    text-transform: capitalize;
}

.cf-u-italic {
    font-style: italic;
}

.cf-u-text-underlined {
    text-decoration: underline;
    text-decoration-color: currentColor;
}

.cf-u-text-bold {
    font-weight: bold;
}

.cf-u-text-ellipsis {
    @include text-ellipsis;
}

.cf-u-text-center {
    text-align: center;
}

.cf-u-text-right {
    text-align: right;
}

.cf-u-text-left {
    text-align: left;
}

.cf-u-text-justify {
    text-align: justify;
}

.cf-u-nowrap {
    white-space: nowrap;
}

.cf-u-font-size-xs {
    font-size: $cf-font-size-xs;
}

.cf-u-font-size-sm {
    font-size: $cf-font-size-sm;
}

.cf-u-font-size-md {
    font-size: $cf-font-size-md;
}

.cf-u-font-size-lg {
    font-size: $cf-font-size-lg;
}

.cf-u-font-size-stack {
    font-size: $cf-font-size-stack;
}

.cf-u-font-size-xl {
    font-size: $cf-font-size-xl;
}

.cf-u-font-size-xxl {
    font-size: $cf-font-size-xxl;
}

.cf-u-font-size-heading {
    font-size: $cf-font-size-document-heading-lg;
}

.cf-u-line-height-sm {
    line-height: $cf-font-line-height-sm;
}

.cf-u-font-weight-normal {
    font-weight: normal;
}

.cf-u-font-weight-bold {
    font-weight: bold;
}

.cf-u-bg-current-color {
    background-color: currentColor;
}

.cf-u-bg-white {
    background-color: $cf-color-white;
}

.cf-u-bg-gray {
    background-color: $cf-color-gray;
}

.cf-u-bg-gray-01 {
    background-color: $cf-color-gray-01;
}

.cf-u-bg-primary {
    background-color: $cf-color-primary;
}

.cf-u-bg-primary-light {
    background-color: $cf-color-primary-light;
}

.cf-u-color-primary {
    color: $cf-color-primary;
}

.cf-u-color-primary-light {
    color: $cf-color-primary-light;
}

.cf-u-color-black {
    color: $cf-color-black;
}

.cf-u-color-white {
    color: $cf-color-white;
}

.cf-u-color-secondary {
    color: $cf-color-secondary;
}

.cf-u-collor-gray-02 {
    color: $cf-color-gray-02;
}

.cf-u-color-secondary-light {
    color: $cf-color-secondary-light;
}

.cf-u-color-rose {
    color: $cf-color-rose;
}

.cf-u-color-gray-canceled {
    color: $cf-color-gray-canceled;
}

.cf-u-color-danger {
    color: $cf-color-danger;
}

.cf-u-color-warning {
    color: $cf-color-warning;
}

.cf-u-color-warning-dark {
    color: $cf-color-warning-dark;
}

.cf-u-color-gray-01 {
    color: $cf-color-gray-01;
}

.cf-u-color-gray-02 {
    color: $cf-color-gray-02;
}

.cf-u-color-unassigned {
    color: $cf-color-unassigned;
}

.cf-u-color-gray-03 {
    color: $cf-color-gray-03;
}

.cf-u-paragraph-color-gray {
    p {
        color: $cf-color-gray-03;
    }
}

.cf-u-color-brown-01 {
    color: $cf-color-brown-01;
}

.cf-u-color-cerise {
    color: $cf-color-cerise;
}

.cf-u-color-ocean-green {
    color: $cf-color-ocean-green;
}

.cf-u-color-teal {
    color: $cf-color-teal;
}

.cf-u-color-amber {
    color: $cf-color-amber;
}

.cf-u-color-gold {
    color: $cf-color-gold;
}

.cf-u-color-sky-blue {
    color: $cf-color-sky-blue;
}

.cf-u-color-gray-blue {
    color: $cf-color-gray-blue;
}

.cf-u-color-pink {
    color: $cf-color-pink;
}

.cf-u-color-blue-green {
    color: $cf-color-blue-green;
}

.cf-u-color-material-to-be-reviewed {
    color: $cf-color-material-to-be-reviewed;
}

.cf-u-display-flex {
    display: flex;
}

.cf-u-flex-align-center {
    display: flex;
    align-items: center;
}

.cf-u-align-start {
    align-items: flex-start;
}

.cf-u-justify-end {
    display: flex;
    justify-content: flex-end;
}

.cf-u-flex-justify-between {
    display: flex;
    justify-content: space-between;
}

.cf-u-flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.cf-u-flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
}

.cf-u-flex-justify-center {
    display: flex;
    justify-content: center;
}

.cf-u-align-self-start {
    align-self: flex-start;
}

.cf-u-align-self-center {
    align-self: center;
}

.cf-u-align-self-end {
    align-self: flex-end;
}

.cf-u-align-center {
    align-items: center;
}

.cf-u-align-end {
    align-items: flex-end;
}

.cf-u-flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cf-u-justify-end {
    justify-content: flex-end;
}

.cf-u-justify-space-between {
    justify-content: space-between;
}

.cf-u-word-break {
    word-break: break-word;
}

.cf-u-justify-space-evenly {
    justify-content: space-evenly;
}

.cf-u-flex-justify-center {
    display: flex;
    justify-content: center;
}

.cf-u-inline-flex {
    display: inline-flex;
}

.cf-u-inline {
    display: inline;
}

.cf-u-contents {
    display: contents;
}

.cf-u-flex-wrap {
    flex-wrap: wrap;
}

.cf-u-flex-grow {
    flex-grow: 1;
}

.cf-u-flex-1-auto {
    flex: 1 1 auto;
}

.cf-u-flex-1-0-auto {
    flex: 1 0 auto;
}

.cf-u-flex-1 {
    flex: 1 1 0;
}

.cf-u-flex-2 {
    flex: 2 1 0;
}

.cf-u-flex-3 {
    flex: 3 1 0;
}

.cf-u-flex-4 {
    flex: 4 1 0;
}

.cf-u-flex-5 {
    flex: 5 1 0;
}

.cf-u-flex-direction-column {
    flex-direction: column;
}

.cf-u-display-block {
    display: block;
}

.cf-u-display-inline-block {
    display: inline-block;
}

.cf-u-display-none {
    display: none;
}

.cf-u-position-relative {
    position: relative;
}

.cf-u-position-absolute {
    position: absolute;
}

.cf-u-position-sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.cf-u-position-sticky-bottom {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
}

.cf-u-position-bottom-left {
    top: 100%;
    left: 0;
}

.cf-u-position-bottom-right {
    right: 0%;
}

.cf-u-position-bottom-0 {
    bottom: 0px;
}

.cf-u-cursor-pointer {
    cursor: pointer;
}

.cf-u-cursor-not-allowed {
    cursor: not-allowed;
}

.cf-u-cursor-default {
    cursor: default !important;
}

.cf-u-cursor-disabled {
    cursor: not-allowed;
}

.cf-u-radius-pill {
    border-radius: $cf-radius-pill;
}

.cf-u-radius {
    border-radius: $cf-radius;
}

.cf-u-width-full {
    width: 100%;
}

.cf-u-width-fit-content {
    width: fit-content;
}

.cf-u-width-half {
    width: 50%;
}

.cf-u-height-full {
    height: 100%;
}

.cf-u-input-height-lg {
    height: $cf-size-input-lg;
}

.cf-u-input-height-sm {
    height: $cf-size-input-sm;
}

.cf-u-full-size {
    width: 100%;
    height: 100%;
}

.cf-u-min-height-100 {
    min-height: 100%;
}

.cf-u-min-height-70vh {
    min-height: 70vh;
}

.cf-u-min-height-2 {
    min-height: 2rem;
}

.cf-u-min-height-200 {
    min-height: 200px;
}

.cf-u-min-height-240 {
    min-height: 240px;
}

.cf-u-width-min-width-3 {
    width: 3rem;
    min-width: 3rem;
    max-width: 3rem;
}

.cf-u-width-min-width-4 {
    width: 4rem;
    min-width: 4rem;
    max-width: 4rem;
}

.cf-u-width-min-width-5 {
    width: 5rem;
    min-width: 5rem;
    max-width: 5rem;
}

.cf-u-width-min-width-6 {
    width: 6rem;
    min-width: 6rem;
    max-width: 6rem;
}

.cf-u-width-min-width-7 {
    width: 7rem;
    min-width: 7rem;
    max-width: 7rem;
}

.cf-u-width-min-width-8 {
    width: 8rem;
    min-width: 8rem;
    max-width: 8rem;
}

.cf-u-width-min-width-9 {
    width: 9rem;
    min-width: 9rem;
    max-width: 9rem;
}

.cf-u-width-min-width-10 {
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
}

.cf-u-width-min-width-11 {
    width: 11rem;
    min-width: 11rem;
    max-width: 11rem;
}

.cf-u-width-min-width-12 {
    width: 12rem;
    min-width: 12rem;
    max-width: 12rem;
}

.cf-u-width-min-width-13 {
    width: 13rem;
    min-width: 13rem;
    max-width: 13rem;
}

.cf-u-width-min-width-14 {
    width: 14rem;
    min-width: 14rem;
    max-width: 14rem;
}

.cf-u-width-min-width-15 {
    width: 15rem;
    min-width: 15rem;
    max-width: 15rem;
}

.cf-u-width-min-width-16 {
    width: 16rem;
    min-width: 16rem;
    max-width: 16rem;
}

.cf-u-width-min-width-17 {
    width: 17rem;
    min-width: 17rem;
    max-width: 17rem;
}

.cf-u-width-min-width-18 {
    width: 18rem;
    min-width: 18rem;
    max-width: 18rem;
}

.cf-u-width-min-width-19 {
    width: 19rem;
    min-width: 19rem;
    max-width: 19rem;
}

.cf-u-width-min-width-20 {
    width: 20rem;
    min-width: 20rem;
    max-width: 20rem;
}

.cf-u-width-min-width-22 {
    width: 22rem;
    min-width: 22rem;
    max-width: 22rem;
}

.cf-u-width-min-width-25 {
    width: 25rem;
    min-width: 25rem;
    max-width: 25rem;
}

.cf-u-width-min-width-28 {
    width: 28rem;
    min-width: 28rem;
    max-width: 28rem;
}

.cf-u-width-min-width-30 {
    width: 30rem;
    min-width: 30rem;
    max-width: 30rem;
}

.cf-u-width-max-width-7 {
    max-width: 7rem;
    width: 100%;
}

.cf-u-width-max-width-9 {
    max-width: 9rem;
}

.cf-u-width-max-width-15 {
    max-width: 15rem;
    width: 100%;
}

.cf-u-width-max-width-22 {
    max-width: 22rem;
}

.cf-u-width-max-width-35 {
    max-width: 35rem;
}

.cf-u-min-width-0 {
    min-width: 0;
}

.cf-u-max-height-10 {
    max-height: 10rem;
}

.cf-u-max-height-12 {
    max-height: 12rem;
}

.cf-u-overflow-auto {
    overflow: auto;
}

.cf-u-border-bottom-primary {
    border-bottom: 1px solid $cf-color-primary;
}

.cf-u-border-top-gray {
    border-top: 1px solid $cf-color-gray-01;
}

.cf-u-button-styles-clear {
    @include button-clear;
}

.cf-u-border-bottom {
    border-bottom: 1px solid $cf-color-gray-01;
}

.cf-u-border-bottom-gray-02 {
    border-bottom: 1px solid $cf-color-gray-02;
}

.cf-u-border-left {
    border-left: 1px solid $cf-color-gray-01;
}

.cf-u-border-box {
    border: 1px solid $cf-color-gray-01;
}

.cf-u-border-none {
    border: none;
}

.cf-u-border-box-danger {
    border: 1px solid $cf-color-danger;
    border-radius: $cf-radius-sm;
}

.cf-u-border-box-sm-02 {
    border: 1px solid $cf-color-gray-02;
    border-radius: $cf-radius-sm;
}

.cf-u-border-box-radius-sm {
    border: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius-sm;
    background: $cf-color-white;
}

.cf-u-border-amber-2 {
    border: 2px solid $cf-color-amber;
}

.cf-u-border-gray-02-2 {
    border: 2px solid $cf-color-gray-02;
}

.cf-u-border-danger {
    border: 1px solid $cf-color-danger;
}

.cf-u-zindex-2 {
    z-index: 2;
}

.cf-u-box {
    @include cf-box();
}

.cf-u-box-no-shadow {
    @include cf-box(0, $cf-radius);
}

.cf-u-box-no-radius {
    @include cf-box($cf-c-shadow-default, 0);
}

.cf-u-box-clear {
    @include cf-box(0, 0);
}

.cf-u-max-width-38 {
    max-width: 38rem;
}

.cf-u-list-style-none {
    list-style: none;
}

.cf-u-opacity-50 {
    opacity: 0.5;
}

.cf-u-icon-postion {
    top: 50%;
    right: $cf-gutter-sm;
    transform: translateY(-50%);
}

.cf-u-box-danger {
    border-radius: 4px;
    background: rgba(248, 47, 71, 0.1);
    padding: 1rem;
    margin-top: -1rem;
}

.cf-u-shadow-danger {
    box-shadow: 0 0 0 0.1rem $cf-color-danger;
    border-radius: $cf-radius-sm;
}

.cf-u-shadow-inset {
    box-shadow: $cf-c-shadow-inset;
}

.cf-u-dot-loader {
    &:before {
        animation: dots 2s linear infinite;
        content: '';
    }

    @keyframes dots {
        0%,
        20% {
            content: '.';
        }
        40% {
            content: '..';
        }
        60% {
            content: '...';
        }
        90%,
        100% {
            content: '';
        }
    }
}

.object-fit-contain {
    object-fit: contain;
}

.cf-u-link {
    color: $cf-color-primary;
    cursor: pointer;
    text-decoration: underline;
}

.p-0 {
    padding: 0 !important;
}

.m-0 {
    margin: 0 !important;
}

.display-content {
    display: contents;
}

.bold {
    font-weight: bold !important;
}
