/**
 * @name Conformio - Register section form group
 *
 */

.cf-c-reg-section-form-group {
    display: flex;
    flex-wrap: wrap;

    &.cf-c-reg-section-form-group--title-narrow {
        .cf-c-reg-section-form-group__title-wrap {
            flex-basis: 7rem;
        }
    }

    &.cf-c-reg-section-form-group--equal-columns {
        .cf-c-reg-section-form-group__title-wrap,
        .cf-c-reg-section-form-group__input-wrap {
            flex: 1 1 0;
        }
    }

    &.cf-c-reg-section-form-group--content-shrink {
        .cf-c-reg-section-form-group__input-wrap {
            flex-basis: 0;
        }
    }

    &.cf-c-reg-section-form-group--title-narrow-xs {
        .cf-c-reg-section-form-group__title-wrap {
            flex-basis: auto;
        }
    }

    &.cf-c-reg-section-form-group--margin-md {
        .cf-c-reg-section-form-group__title-wrap,
        .cf-c-reg-section-form-group__input-wrap {
            margin-bottom: $cf-gutter-md;
        }
    }

    &.cf-c-reg-section-form-group--no-padding {
        .cf-c-reg-section-form-group__title-wrap,
        .cf-c-reg-section-form-group__input-wrap {
            padding: 0;
        }
    }

    &.cf-c-reg-section-form-group--no-margin {
        .cf-c-reg-section-form-group__input-wrap {
            margin: 0;
        }
    }

    &.cf-c-reg-section-form-group--title-hight-auto {
        .cf-c-reg-section-form-group__title-text {
            min-height: auto;
        }
    }

    &.cf-c-reg-section-form-group--group-column {
        flex-direction: column;

        .cf-c-reg-section-form-group__title-wrap {
            flex: 1;
        }
    }

    &.cf-c-reg-section-form-group--label-center {
        .cf-c-reg-section-form-group__title-text {
            &,
            > div {
                width: 100%;
                text-align: center;
            }
        }
    }

    &.cf-c-reg-section-form-group--label-align-center {
        .cf-c-reg-section-form-group__title-wrap {
            display: flex;
            align-items: center;
        }
    }
}

.cf-c-reg-section-form-group__title-wrap,
.cf-c-reg-section-form-group__input-wrap {
    padding: 0 $cf-gutter-sm;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-reg-section-form-group__title-wrap {
    display: flex;
    align-items: flex-start;
    flex: 0 1 9rem;
}

.cf-c-reg-section-form-group__title-text,
.cf-c-reg-section-form-group__input-wrap-text {
    display: flex;
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    word-break: break-word;
}

.cf-c-reg-section-form-group__title-text {
    align-items: center;
    min-height: $cf-size-input-sm;
}

.cf-c-reg-section-form-group__input-wrap {
    flex: 1 0 10rem;
    min-width: 0;
}

.cf-c-reg-section-form-group__asterisk {
    position: relative;
    display: inline-block;
    height: 1em;
    color: $cf-color-danger;

    &:after {
        content: '*';
        display: inline-block;
        position: absolute;
    }
}

.cf-c-reg-section-form-group__input-wrap-text {
    @include flex-align-center;
    min-height: 100%;
}
