/**
 * @name Conformio - Document wizard input mode
 *
 */

.cf-c-dw-input-mode {
    @include absolute-full;
    height: auto;
    min-height: 100%;
    padding: $cf-gutter 0 0;
    overflow: auto;

    &.cf-c-dw-input-mode--grow-ctn {
        display: flex;
        flex-direction: column;

        .cf-c-dw-input-mode__body {
            flex: 1 1 0;
            overflow: auto;
        }
    }

    &.cf-c-dw-input-mode--sec-obj {
        .cf-c-dw-input-mode__body {
            margin: 0;
            padding: $cf-gutter-sm 0 0;
        }
    }
}

.cf-c-dw-input-mode__hash,
.cf-c-dw-input-mode__body {
    margin-bottom: $cf-gutter-sm;
    padding: 0 $cf-gutter;
}

.cf-c-dw-input-mode__hash {
    display: block;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-warning;
}

.cf-c-dw-input-mode__title {
    font-weight: bold;
}

.cf-c-dw-input-mode__desc {
    color: $cf-color-gray-03;
}

.cf-c-dw-input-mode__body {
    margin-bottom: $cf-gutter;
}

.cf-c-dw-input-mode__action,
.cf-c-dw-input-mode__discussion {
    padding: $cf-gutter;
}

.cf-c-dw-input-mode__action {
    display: flex;
    justify-content: space-between;
    @extend .cf-u-position-sticky-bottom;
    background-color: $cf-color-white;
    z-index: 1;
}

.cf-c-dw-input-mode__action-item {
    flex: 1 1 0;

    &:first-child {
        margin-right: $cf-gutter-md;
    }
}

.cf-c-dw-input-mode__discussion {
    margin-top: $cf-gutter;
}
