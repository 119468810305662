$bar-height: 2em;

.filter-bar {
    padding: 0.5rem;
    margin: 0 0 1rem 0;
    border: 1px solid #e5e7e8;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    color: #676767;
    font-size: 0.875em;

    div + & {
        margin-top: 1rem;
    }

    .VuePagination {
        margin: 2px 0;
        flex: 1;
        min-height: $bar-height;

        nav {
            display: flex;
            flex-direction: row;
        }

        .page-link {
            height: $bar-height;
            line-height: $bar-height;
            padding: 0 0.5em;
            min-width: $bar-height;
            border-color: #e5e7e8;
            border-radius: 0 !important;
            color: #4788c6;
            text-align: center;
            font-weight: 600;
            cursor: pointer;
            font-size: 1em;

            &:hover {
                background-color: inherit;
            }

            &.disabled {
                color: #9f9ea0;
            }

            &.active {
                background-color: #fff; // #4788c6;
                border-color: #4788c6;
                border: 2px solid #4788c6;
                // color: #fff;
            }
        }

        p {
            margin: 0.25em 1em;
            line-height: 1.5em;
        }
    }
}
