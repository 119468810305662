/**
 * @name Conformio - Tabs
 *
 */

.cf-c-tabs {
    &.cf-c-tabs--full-height {
        height: 100%;
        display: flex;
        flex-direction: column;

        .cf-c-tabs__content {
            flex: 1;
        }
    }
}

.cf-c-tabs__items {
    @include dw-box-heading;
    display: flex;
    z-index: $cf-z-index-tabs-items;
}

.cf-c-tabs__item {
    @include flex-center;
    flex: 1;
    height: $cf-size-tabs-height;
    border-bottom: 4px solid transparent;
    padding-top: 4px;
    color: $cf-color-gray-03;
    font-weight: bold;

    &:hover {
        color: $cf-color-primary;
    }

    &.cf-is-active {
        color: $cf-color-primary;
        border-bottom-color: $cf-color-primary;
    }
}

.cf-c-tabs__content {
    position: relative;
}
