.cf-c-app-modal-wrapper {
    &.cf-c-app-modal-wrapper--approval {
        min-width: 58rem;
        max-height: 40rem;

        .vs__dropdown-menu {
            min-width: 100%;
            margin-bottom: $cf-gutter;
        }

        .cf-c-collapsible-right__header-ctn {
            > :first-child {
                flex-grow: 1;
            }
        }

        .cf-c-collapsible-right__content {
            > div {
                > :first-child {
                    flex-grow: 1.5;
                }
            }
        }

        .cf-c-collapsible-right__content {
            padding-right: $cf-gutter;

            > .cf-u-ml-sm {
                margin: 0;
                padding-left: 0.5rem;
            }
        }

        .vs__dropdown-toggle {
            min-height: 2.5rem;
        }

        .cf-c-typeahead {
            .vs__actions::after {
                border-width: 7px 7px 0;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &.cf-c-typeahead--border-default {
                .vs__dropdown-toggle {
                    border: 1px solid $cf-color-gray-02;
                }

                .vs__actions::after {
                    border-color: $cf-color-gray-02 transparent transparent;
                }
            }

            &.cf-c-typeahead--border-approve {
                .vs__dropdown-toggle {
                    border: 1px solid $cf-color-primary;
                }

                .vs__actions::after {
                    border-color: $cf-color-primary transparent transparent;
                }
            }

            &.cf-c-typeahead--border-reject {
                .vs__dropdown-toggle {
                    border: 1px solid $cf-color-danger-light;
                }

                .vs__actions::after {
                    border-color: $cf-color-danger-light transparent transparent;
                }
            }
        }
    }
}
