.l-subscription {
    padding: $cf-gutter;
}

.c-subscription {
    background-color: $cf-color-white;
    border: 1px solid $cf-color-gray-01;
}

.c-subscription__tier {
    border: 1px solid $cf-color-gray-01;
    background-color: $cf-color-gray;
}

.c-subscription__empty-tier {
    border-top: 1px solid $cf-color-gray-01;
    border-left: 1px solid $cf-color-gray-01;
    border-right: 1px solid $cf-color-gray-01;
    background-color: $cf-color-gray;
}

.c-subscription__table {
    display: flex;
    flex-direction: column;
    padding: $cf-gutter-lg $cf-gutter-md $cf-gutter-md $cf-gutter-md;
}

.c-subscription__th,
.c-subscription__tr {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    > :first-child {
        padding-left: $cf-gutter;
        padding-right: $cf-gutter;
    }
}

.c-subscription__tr-border-left {
    position: absolute;
    top: 0;
    width: 33%;
    height: stretch;
    border-right: 1px solid $cf-color-gray-01;
    border-bottom: 1px solid $cf-color-gray-01;
}

.c-subscription__tr-border-middle {
    position: absolute;
    top: 0;
    left: 33%;
    width: 33%;
    height: stretch;
    border-right: 1px solid $cf-color-gray-01;
    border-bottom: 1px solid $cf-color-gray-01;
}

.c-subscription__features {
    display: flex;
    align-items: flex-end;
    font-size: $cf-font-size-md;
    font-weight: bold;
    color: $cf-color-gray-03;
    padding-bottom: $cf-gutter-md;
    padding-left: 1em;
}

.c-subscription__trial {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    height: fit-content;
    text-align: center;
    font-size: $cf-font-size-md;
    color: $cf-color-primary;
    padding: $cf-gutter;
    border: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius-lg 0 0 0;
}

.c-subscription__paid {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 1.25em;
    padding-bottom: 1.9em;
}

.c-subscription__advanced-paid {
    background-color: $cf-color-primary;
}

.c-subscription__table-body {
    position: relative;
    border-bottom: 1px solid $cf-color-gray-01;
}

.c-subscripion__feature,
.c-subscription__offered {
    min-height: 4.5rem;
    flex: 1 1 auto;
    min-width: 0;
    display: flex;
    align-items: center;
    border-right: 1px solid $cf-color-gray-01;
    border-left: 1px solid $cf-color-gray-01;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.c-subscripion__feature {
    color: $cf-color-gray-03;
    font-size: $cf-font-stack;
    background-color: $cf-color-gray;
}

.c-subscripion__feature-inline {
    display: inline;
}

.c-subscription__offered {
    justify-content: center;
    color: $cf-color-secondary;
}

.c-subscription__cancel-offered {
    color: $cf-color-gray-02;
}

.c-subscription__subtable {
    position: relative;
    padding: $cf-gutter-md 0;

    > :not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }
}

.c-subscripion__subtable-feature {
    font-size: $cf-font-stack;
    color: $cf-color-gray-02;
}

.c-subscription__subtable-offered {
    @include flex-center;
}

.c-subscription__partial {
    @include flex-center;
    color: $cf-color-black;

    > p {
        margin-bottom: 0;
    }
}

.c-subscripion__subscribtion-border {
    background-color: $cf-color-red;
    border: 1px solid $cf-color-gray-01;
}

.c-subscription__gray-background {
    position: absolute;
    top: 0;
    right: 25%;
    width: 25%;
    height: 100%;
    background-color: #e5e7e85e;
}
