/**
 * @name Conformio - Links list item
 *
 */

.cf-c-links-list-item {
    @include flex-align-center;
    min-height: 2.5rem;
    font-size: $cf-font-size-sm;
}

.cf-c-links-list-item__label {
    flex-shrink: 0;
    margin-left: $cf-gutter-sm;
}

.cf-c-links-list-item__dot {
    @include flex-center;
    flex-shrink: 0;
    margin: 0 $cf-gutter-sm;
    color: $cf-color-gray-02;
}

.cf-c-links-list-item__desc {
    @include text-ellipsis;
    flex-shrink: 1;
    margin-right: $cf-gutter;
    color: $cf-color-gray-03;
}

.cf-c-links-list-item__link {
    @include flex-center;
    display: flex;
    align-self: stretch;
    flex-shrink: 0;
    min-width: 3.5rem;
    margin-left: auto;
    padding: $cf-gutter-xs;
    color: $cf-color-primary;
    font-weight: bold;

    &:hover {
        color: $cf-color-primary-dark;
    }
}
