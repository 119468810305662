/**
 * @name Conformio - Checkbox
 *
 */

.cf-c-checkbox-wrapper {
    display: flex;
    align-items: center;
}

.cf-c-checkbox-input {
    margin-left: 0;
    width: 1.3rem;
    min-width: 1.3rem;
    height: 1.3rem;
    min-height: 1.3rem;
    border: 2px solid $cf-color-primary;
    -webkit-appearance: none;
    background-color: $cf-color-white;
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    outline: none;
    margin-top: 0;

    &:active,
    &:checked:active {
        outline: none;
    }

    &:checked {
        background-color: $cf-color-white;
        outline: none;
    }

    &:checked:after {
        content: '✓';
        font-size: 27px;
        position: absolute;
        top: -16px;
        left: 1px;
        color: $cf-color-primary;
        outline: none;
    }
}
