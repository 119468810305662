/**
 * @name Conformio - Icons
 *
 */

.cf-c-icon-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cf-c-icon-wrap-inline {
    display: inline;
}

.cf-c-icon-cursor-pointer {
    cursor: pointer;
}

.cf-c-icon-disabled {
    cursor: not-allowed;
    opacity: 0.25;
}

.cf-c-icon {
    width: $cf-size-icon;
    height: $cf-size-icon;

    svg {
        fill: currentColor;
    }

    // temporary here
    > img {
        vertical-align: top;
    }
}

// temporary here
img.cf-c-icon {
    object-fit: contain;
}

.cf-c-icon--xs {
    width: $cf-size-icon-xs;
    height: $cf-size-icon-xs;
}

.cf-c-icon--sm {
    width: $cf-size-icon-sm;
    height: $cf-size-icon-sm;
}

.cf-c-icon--md {
    width: $cf-size-icon-md;
    height: $cf-size-icon-md;
}

.cf-c-icon--lg {
    width: $cf-size-icon-lg;
    height: $cf-size-icon-lg;
}

.cf-c-icon--xl {
    width: $cf-size-icon-xl;
    height: $cf-size-icon-xl;
}

.cf-c-icon--xxxl {
    width: $cf-size-icon-xxxl;
    height: $cf-size-icon-xxxl;
}

.cf-c-icon--full {
    width: 100%;
    height: 100%;
}

.cf-rtp-recource-wrap {
    position: relative;

    .cf-rtp-remove-recource {
        position: absolute;
        right: 4px;
        top: 4px;
        cursor: pointer;
    }
}
