/**
 * @name Conformio - Pill
 *
 */

.cf-c-pill {
    display: inline-flex;
    align-items: center;
    text-align: center;
    min-width: 0;
    max-width: 100%;
    height: 1.5rem;
    background-color: $cf-color-primary;
    border-radius: $cf-radius-pill;
    padding: 0 0.3rem 0 0.3rem;
}

.cf-c-pill__text,
.cf-c-pill__action {
    color: $cf-color-white;
}

.cf-c-pill__text {
    @include text-ellipsis;
    margin: 0 $cf-gutter-xs;
    line-height: 1.333;
    color: $cf-color-white;
    font-size: $cf-font-size-stack;
    font-weight: bold;
}

.cf-c-pill__action {
    display: flex;
    height: calc(100% - #{$cf-gutter-xs});
    width: 1.25rem;
    margin-left: -$cf-gutter-xs;
    align-items: center;
}
