
@import '@style/sfc.scss';

.read-more__link {
    display: inline-block;
    text-decoration: underline;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
        color: $color-primary-epsilon;
    }
}
