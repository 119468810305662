.page {
    &__box {
        position: relative;
        display: flex;
        min-height: rem(115);
        margin-bottom: rem(24);
        border: 1px solid $color-gray-alpha;
        border-radius: rem(4);
        flex-direction: row;
        background-color: $color-white;

        &:last-child {
            margin-bottom: 0;
        }

        &--dir-column {
            flex-direction: column;
        }
    }
}
