.cf-c-single-step__awareness-input-wrapper {
    display: flex;
    align-items: center;
    min-height: 3rem;
}

.cf-c-single-step__awareness-checkbox-input {
    margin: 0 $cf-gutter;
    width: 1.3rem;
    height: 1.3rem;
    border: 2px solid $cf-color-primary;
    -webkit-appearance: none;
    background-color: $cf-color-white;
    padding: 9px;
    border-radius: 3px;
    display: inline-block;
    position: relative;
    outline: none;

    &:active,
    &:checked:active {
        outline: none;
    }

    &:checked {
        background-color: $cf-color-white;
        outline: none;
    }

    &:checked:after {
        content: '✓';
        font-size: 27px;
        position: absolute;
        top: -16px;
        left: 1px;
        color: $cf-color-primary;
        outline: none;
    }
}

.cf-c-single-step__awareness-checkbox-label {
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 $cf-gutter;
    align-self: stretch;
    border-left: 1px solid $cf-color-gray-01;
}

.cf-c-single-step__awareness-row {
    border-bottom: 1px solid $cf-color-gray-01;
    border-top: none;
    background-color: $cf-color-white;
    min-height: 3rem;
    flex-direction: column;

    &:first-child {
        border-top-right-radius: $cf-radius;
        border-top-left-radius: $cf-radius;
    }

    .checkboxes {
        min-height: 3rem;
    }
}

.cf-c-single-step__awareness-checkbox-wrapper {
    text-align: center;
    padding: $cf-gutter-sm;
}

.cf-c-single-step__awareness-checkbox-title {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    margin-bottom: $cf-font-size-sm;
    color: $cf-color-gray-03;
}

.cf-c-single-step__awareness-material-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: $cf-gutter-sm 0;

    > span:nth-child(1) {
        margin-bottom: $cf-gutter-075;
    }

    > span:nth-child(2) {
        font-weight: bold;
    }
}

.cf-c-single-step__awareness-row-content {
    width: 100%;
}

.cf-c-single-step__awareness-row-select {
    width: 100%;
    padding-left: $cf-gutter-md;

    .multiselect__tags {
        border-left: none;
        border-right: none;
    }
}

.ml-4 {
    .cf-c-single-step__input-container {
        margin: 3rem 0 0;
        padding: 0;
    }
}

.cf-c-single-step__input-container {
    margin: 3rem 0 0;
    padding: 0;
}

.cf-c-single-step__input-select-main {
    width: 17rem;
}

.cf-c-single-step__content-text {
    font-size: $cf-font-size-stack;
    color: $cf-color-gray-03;
}

.cf-c-single-step__conditional-text {
    li {
        list-style-position: inside;
    }
}

.cf-c-onboarding-heading__back-icon {
    background-color: transparent;
    border-color: transparent;
    width: $cf-size-icon-xl;
    height: $cf-size-icon-xl;
    padding: 0;

    &:hover {
        background-color: transparent;
        border-color: transparent;
    }
}

.cf-c-people-assigned__wrapper {
    background-color: $cf-color-white;
    max-width: 40rem;
    border: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius;
}
.cf-c-input-resources__wrapper {
    .cf-c-input__ctn {
        width: 40rem;
    }
}
