/**
 * @name Conformio - Document wizard preview
 *
 */

.cf-c-doc-wiz-preview {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &.cf-c-doc-wiz-preview--heading-has-button {
        .cf-c-doc-wiz-preview__heading {
            align-items: flex-start;
        }

        .cf-c-doc-wiz-heading-info {
            margin-bottom: -2.5rem;
        }
    }

    &.cf-c-doc-wiz-preview--heading-multiline {
        .cf-c-doc-wiz-preview__heading {
            align-items: flex-start;
            margin-bottom: rem(-10);
        }
    }

    &.cf-c-doc-wiz-preview--overflow-hidden {
        .cf-c-doc-wiz-preview__ctn-inner {
            overflow: hidden;
        }
    }
}

.cf-c-doc-wiz-preview__heading {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1rem;
    min-height: 3.75rem;
}

.cf-c-doc-wiz-preview__ctn {
    flex-grow: 1;
    position: relative;
}

.cf-c-doc-wiz-preview__ctn-inner {
    @include absolute-full;
    @include cf-box;
    overflow: overlay;
    height: 100%;
}

.cf-c-doc-wiz-preview__over-margin-top {
    margin-top: $cf-gutter * 4.5;
    height: calc(100% - 4.5 * $cf-gutter);
}
