/**
 * @name Conformio - Data Card
 *
 */

.cf-c-data-card {
    @include cf-box;
    display: flex;
    flex-direction: column;
    padding: $cf-gutter $cf-gutter $cf-gutter-sm $cf-gutter;

    &.cf-c-data-card--in-grid {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-width: 0;
        margin-right: $cf-gutter;
    }
}

.cf-c-data-card__heading {
    margin-bottom: $cf-gutter-md;
    word-break: break-word;
}

.cf-c-data-card__body {
    flex-grow: 1;
}

.cf-c-data-card__heading-suggest {
    margin-top: $cf-gutter-md;
    margin-bottom: $cf-gutter;
    font-weight: bold;
    color: $cf-color-gray-03;
}

.cf-c-data-card__footer {
    margin-bottom: $cf-gutter-sm;
    margin-top: $cf-gutter-md;
}

.cf-c-data-card-body {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.cf-c-data-card-body__row {
    display: flex;
    width: 100%;
    margin-bottom: $cf-gutter;
}

.cf-c-data-card-body__label {
    width: 50%;
    font-size: 0.75rem;
    font-weight: normal;
    line-height: 1.25;
    color: $cf-color-primary;
    text-transform: uppercase;
    padding-right: $cf-gutter-sm;
}

.cf-c-data-card-body__value {
    width: 50%;
    font-size: 0.75rem;
    font-weight: bold;
    line-height: 1.25;
    color: $cf-color-gray-03;
    word-break: break-word;
    display: flex;
    align-items: center;
}
