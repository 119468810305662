/**
 * @name Conformio - Inputs
 *
 */

.cf-c-input {
    position: relative;
    min-width: $cf-size-input-min-width;

    &.cf-has-error {
        .cf-c-input__field {
            @include cf-input-has-error;
        }
    }

    &.cf-has-text {
        .cf-c-input__field {
            @include cf-input-has-text;
        }
    }

    &.cf-has-absolute-errors {
        .cf-c-input__error {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            white-space: nowrap;
        }
    }

    &.cf-c-input--full-width {
        min-width: 100%;
    }

    &.cf-c-input--no-borders {
        .cf-c-input__field {
            border-color: transparent;
        }
    }

    &.cf-c-input--border-radius {
        .cf-c-input__field {
            border-radius: $cf-radius;
        }
    }

    &.cf-c-input--within-text {
        display: inline-block;
        vertical-align: top;
        margin-top: -$cf-gutter-xs;
    }

    &.cf-c-input--h-sm {
        &:not(.cf-c-input--w-xs) {
            .cf-c-input__field {
                font-size: $cf-font-size-sm;
            }
        }

        .cf-c-input__ctn {
            height: $cf-size-input-sm;
        }
    }

    &.cf-c-input--h-xs {
        &:not(.cf-c-input--w-xs) {
            .cf-c-input__field {
                font-size: $cf-font-size-sm;
            }
        }

        .cf-c-input__ctn {
            height: $cf-size-input-xs;
        }
    }

    &.cf-c-input--h-md {
        .cf-c-input__ctn {
            height: $cf-size-input-md;
        }
    }

    &.cf-c-input--h-auto {
        height: 100%;

        .cf-c-input__ctn {
            height: 100%;
        }
    }

    &.cf-c-input--w-xs {
        min-width: auto;
        width: 2rem;

        .cf-c-input__field {
            padding: 0 $cf-gutter-xs;
            text-align: right;
        }
    }

    &.cf-c-input--w-sm {
        min-width: auto;
        width: 3rem;

        .cf-c-input__field {
            padding: 0 $cf-gutter-xs;
            text-align: right;
        }
    }

    &.cf-c-input--w-22 {
        .cf-c-input__ctn {
            width: 22rem;
        }
    }

    &.cf-c-input--w-30 {
        min-width: auto;
        width: 30%;

        .cf-c-input__field {
            padding: 0 $cf-gutter-xs;
        }
    }

    &.cf-c-input--w-60 {
        min-width: auto;
        width: 60%;

        .cf-c-input__field {
            padding: 0 $cf-gutter-xs;
        }
    }

    &.cf-c-input--bg-gray {
        .cf-c-input__field {
            background: $cf-color-gray;
        }
    }

    &.cf-c-input--text-center {
        .cf-c-input__field {
            text-align: center;
        }
    }

    &.cf-c-input--inside-collapsible {
        .cf-c-input__ctn {
            height: 1.5rem;
        }

        .cf-c-input__field {
            text-align: center;
            font-weight: bold;
            font-size: $cf-font-size-stack;
            color: $cf-color-gray-03;
        }
    }

    &.cf-c-input--small {
        font-size: $cf-font-size-sm;
        line-height: 2;

        .cf-c-input__ctn {
            height: 2rem;
        }
    }

    &.cf-c-input--single {
        .cf-c-input__ctn {
            width: 2.5rem;
            height: 2.5rem;
        }

        .cf-c-input__field {
            font-size: $cf-font-size-md;
        }
    }

    &.cf-c-input--integer {
        text-align: center;

        .cf-c-input__field {
            text-align: center;
        }

        .cf-c-input__error {
            width: min-content;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    // parent override - currently in doc wiz / input mode
    .cf-c-dw-sections-wrapper & {
        min-width: auto;
    }
}

.cf-c-input__ctn {
    height: $cf-size-input;
    position: relative;
}

.cf-c-input__field {
    @include absolute-full;
    @include cf-input;
    padding: 0 $cf-gutter-075;
    transition: border-color $cf-animation-speed-default;

    @include placeholder {
        color: $cf-color-input-placeholder;
    }

    &[required] {
        ~ .cf-c-input__required {
            opacity: 1;
        }
    }

    &:disabled,
    &[disabled] {
        -webkit-text-fill-color: $cf-color-input-placeholder;
        color: $cf-color-input-placeholder;
        background-color: $cf-color-input-disabled-bg;
        cursor: not-allowed;
    }

    &:focus {
        @include focus-default;
    }
}

.cf-c-input__required {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: $cf-gutter-xs;
    color: $cf-color-primary;
    line-height: 1;
    pointer-events: none;
    opacity: 0;
}

.cf-c-input__error {
    @include error-msg;
}

.cf-c-input__info {
    @include error-msg;
    color: $cf-color-gray-03;
    position: absolute;
}

.cf-c-input__info__regular {
    @include error-msg;
    color: $cf-color-gray-03;
    margin-bottom: 0;
}

.cf-has-absolute-errors-border {
    border: 1px solid red;
}
