/**
 * @name Conformio - SOA Item
 *
 */

.cf-c-soa-item {
    display: flex;
    position: relative;
    min-height: 5.5rem;

    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }

    &:before {
        content: '';
        display: block;
        width: 0.5rem;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        background-color: transparent;
    }

    &.cf-c-soa-item--applicable {
        &:before {
            border-right: 1px solid $cf-color-gray-01;
        }
    }

    &.cf-c-soa-item--implemented {
        &:before {
            background-color: $cf-color-sky-blue;
        }
    }

    &.cf-c-soa-item--under_way {
        &:before {
            background-color: $cf-color-amber;
        }
    }

    &.cf-c-soa-item--planned {
        &:before {
            background-color: $cf-color-gold;
        }
    }

    &.cf-c-soa-item--review_needed {
        &:before {
            background-color: $cf-color-danger;
        }
    }
}

.cf-c-soa-item__main {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 1px solid $cf-color-gray-01;
    padding: $cf-gutter-075 $cf-gutter-xs $cf-gutter-075 $cf-gutter;
    white-space: nowrap;
}

.cf-c-soa-item__version {
    color: $cf-color-gray-03;
}

.cf-c-soa-item__desc {
    padding: $cf-gutter-sm;
    color: $cf-color-gray-03;
}

.cf-c-soa-item__switch-wrapper {
    padding: $cf-gutter-sm;
}

.cf-c-soa-item__justification,
.cf-c-soa-item__implementation {
    display: flex;
    flex-direction: column;
    padding: $cf-gutter-sm;
}

.cf-c-soa-item__implementation {
    flex: 1 1 0;
}

.cf-c-soa-item__textarea-placehoder-temp {
    flex-grow: 1;
    border: 1px solid $cf-color-gray-02;
    border-radius: $cf-radius;
}
