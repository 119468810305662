.cf-l-login {
    max-width: $cf-size-main-container;
    width: 100%;
    margin: 0 auto;
    padding: 3.5rem $cf-gutter 2rem $cf-gutter;
    text-align: center;
}

.cf-c-login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: $cf-size-login-form-max-width;
    width: 100%;
    margin: 0 auto;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    padding: $cf-gutter-lg;
    position: relative;

    &.cf-c-app-login-form--width-27 {
        max-width: 27rem;
        min-width: 27rem;
    }

    &.cf-c-app-login-form--width-30 {
        max-width: 30rem;
        min-width: 30rem;
    }

    &.cf-c-app-login-form--width-40 {
        max-width: 40rem;
        min-width: 40rem;
    }

    &.cf-c-app-login-form--width-50 {
        max-width: 50rem;
        min-width: 50rem;
    }

    &.cf-c-app-login-form--width-60 {
        max-width: 60rem;
        min-width: 60rem;
    }

    &.cf-c-app-login-form--left {
        text-align: left;
    }
}

.cf-c-login__input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-bottom: $cf-gutter-md;

    .cf-c-main-section__ctn {
        width: 100%;
    }
}

.cf-c-login__error-wrap {
    max-width: $cf-size-login-form-max-width;
    width: 100%;
    margin: 0 auto;

    .alert {
        font-size: $cf-font-size-sm;
        color: $cf-color-white;
        font-weight: bold;
        padding: $cf-gutter-xs 0;
        max-width: $cf-size-login-form-max-width;
        width: 100%;
        border-radius: 0;
        border-width: 0;
    }

    .alert-danger {
        background-color: $cf-color-danger-light;
    }
}

.cf-c-login__remember {
    .cf-c-labeled-checkbox__ctn {
        display: flex;
        align-items: center;
    }

    .cf-c-labeled-checkbox__text {
        color: $cf-color-gray-02;
        font-size: $cf-font-size-sm;
        line-height: 1.25;
    }
}

.cf-l-login__image-wrapper {
    width: 10.687rem;
    height: auto;
    margin: 0 auto;
}

.mfa {
    &__box-heading {
        margin: 0 0 $cf-gutter-xl 0;
    }

    &__need-help {
        margin: $cf-gutter-md 0;
        display: inline-block;
        font-size: $cf-font-size-md;
    }

    &__verification-code {
        .title {
            height: auto;
            padding: 0;
            font-weight: 700;
        }

        .react-code-input-container {
            width: auto !important;
        }

        .react-code-input {
            display: flex;
            justify-content: center;
            margin: $cf-gutter-md -5px;

            & > input {
                width: 50px !important;
                height: 60px !important;
                margin: 0 5px;
                font-family: $cf-font-stack !important;
                font-size: $cf-font-size-md;
                border-radius: $cf-radius !important;
                border: 1px solid $cf-color-gray-01 !important;
            }
        }
    }
}
