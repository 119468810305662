/**
 * @name Conformio - Underlined collapsible empty state
 *
 */

.cf-c-underlined-collapsible-empty-state {
    padding: 2.5rem $cf-gutter $cf-gutter-lg;
    font-size: $cf-font-size-stack;
    text-align: center;
    line-height: 1.5;
}

.cf-c-underlined-collapsible-empty-state__text {
    color: $cf-color-gray-03;
    opacity: 0.4;
    font-weight: bold;
    margin-bottom: 0;
}

.cf-c-underlined-collapsible-empty-state__button {
    margin-top: $cf-gutter-md;
}
