.cf-c-profile {
    background-color: $cf-color-white;
    padding: $cf-gutter-xl;
    padding-bottom: $cf-gutter-md;
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-profile__description {
    display: flex;
    flex-direction: row;
    padding-bottom: $cf-gutter-lg;
    border-bottom: 1px solid $cf-color-primary;
}

.cf-c-profile__image-wrap {
    height: 10.5rem;
    width: 10.5rem;
    min-width: 10.5rem;
    position: relative;
    margin-right: $cf-gutter-lg;
}

.cf-c-profile__image-wrap:hover {
    .cf-c-profile__image--hover {
        filter: brightness(50%);
    }

    .cf-c-profile__hover-icon {
        display: block;
    }
}

.cf-c-profile__image {
    height: 100%;
    width: 100%;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-profile__image-input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.cf-c-profile__hover-icon {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 3.6rem;
    height: 4.3rem;

    > svg {
        width: 100%;
        height: 100%;
    }
}

.cf-c-profile__title {
    color: $cf-color-primary;
    font-size: $cf-font-size-xl;
    line-height: $cf-font-size-xl * $cf-font-line-height-sm;
    word-break: break-all;
    padding-right: $cf-gutter;
}

.cf-c-profile__email {
    margin-top: auto;
    margin-bottom: 0;
    font-size: $cf-font-size-md;
    line-height: $cf-font-size-md * $cf-font-line-height-sm;
}

.cf-c-profile__input-container {
    display: block;
    flex-direction: column;
    justify-content: space-between;

    .cf-c-profile__input-wrap {
        height: 2.5rem;
        margin-bottom: $cf-gutter-md;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.cf-c-profile__input-wrap {
    .cf-c-main-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 30rem;
        padding: 0;
        margin-bottom: 0;
    }

    .cf-c-main-section__title {
        margin-bottom: 0;
        font-size: $cf-font-size-stack;
        line-height: $cf-font-size-stack * $cf-font-line-height-sm;
    }

    .cf-c-input__error {
        margin-top: 0.2em;
    }
}
