.cf-c-security-objective {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $cf-color-gray-01;
    margin-top: 1rem;
    padding: 1.5rem 0;
    margin-right: 3rem;
}

.cf-c-security-objective__wrap {
    max-width: 32rem;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.cf-c-new-security-objective__form {
    display: flex;
    flex-direction: column;

    .cf-c-reg-section-form-group__title-text,
    .cf-c-reg-section-form-group__input-wrap-text {
        font-size: 1rem;
    }

    .cf-c-reg-section-form-group__title-wrap {
        flex: 1 1 1rem;
    }

    .cf-c-new-security-objective__first-row {
        .cf-c-reg-section-form-group__title-wrap {
            flex: 1.2 8 auto;
        }

        .cf-c-reg-section-form-group__input-wrap {
            flex: 8.8 1 auto;
        }
    }
}

.cf-c-security-objective__form {
    display: flex;
    flex-direction: column;

    .cf-c-reg-section-form-group__title-wrap,
    .cf-c-reg-section-form-group__input-wrap {
        padding: 0;
        flex: 0;
    }

    .cf-c-reg-section-form-group__title-text {
        min-height: 0;
    }

    .cf-c-reg-section-form-group__input-wrap {
        margin-bottom: 1rem;
    }
}

.cf-c-security-objective__input {
    .cf-c-input__ctn {
        height: 2.5rem;

        .cf-c-input__field {
            border: 1px solid $cf-color-gray-02;
            border-radius: 2px;
        }
    }
}
