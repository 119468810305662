/**
 * @name Conformio - Progress bar
 *
 */

.cf-c-progress-bar {
    @include cf-box;
    display: flex;
    height: $cf-size-progress-bar;
    overflow: hidden;
    font-size: $cf-font-size-sm;
    border-bottom-width: 0;
    border-radius: $cf-radius-sm;
    width: 100%;
    border: none;

    &.cf-c-progress-bar--has-width-160 {
        width: 160px;
    }

    &.cf-c-progress-bar--is-warning {
        .cf-c-progress-bar__progress {
            background-color: $cf-color-warning;
        }
    }

    &.cf-c-progress-bar--is-danger {
        .cf-c-progress-bar__progress {
            background-color: $cf-color-danger;
        }
    }

    &.cf-c-progress-bar--is-gray {
        .cf-c-progress-bar__progress {
            background-color: $cf-color-gray-01;
        }
    }

    &.cf-c-progress-bar--is-primary {
        .cf-c-progress-bar__progress {
            background-color: $cf-color-primary;
        }
    }

    &.cf-c-progress-bar--is-amber {
        background-color: $cf-color-gray-01;

        .cf-c-progress-bar__progress {
            background-color: $cf-color-amber;
        }
    }

    &.cf-c-progress-bar--no-border {
        border: none;
    }

    &.cf-c-progress-bar--is-thick {
        height: $cf-gutter-md;
        background-color: $cf-color-gray-01;
    }

    &.cf-c-progress-bar--is-micro {
        height: $cf-size-progress-bar-micro;
        border: none;
        box-shadow: $cf-c-shadow-progress-bar-micro;
        background-color: $cf-color-gray-01;
    }
}

.cf-c-progress-bar__progress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $cf-radius-sm 0 0 $cf-radius-sm;
    color: $cf-color-white;
    text-align: center;
    white-space: nowrap;
    background-color: $cf-color-secondary;
    transition: width $cf-animation-speed-default ease;
}
