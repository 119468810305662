/**
 * @name Conformio - Date Picker
 *
 */

// Overrides

.cf-c-date-picker {
    position: relative;

    .cf-c-date-picker__content {
        position: relative;
        height: 100%;
    }

    .cf-c-date-picker__icon-wrap {
        @include flex-center;
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: $cf-size-input;
        color: $cf-color-primary;
        pointer-events: none;
    }

    .flatpickr-input {
        @include cf-input;
        @include text-ellipsis;
        color: $cf-color-black;
        padding-right: 2.5rem;
        height: 100%;

        &:focus {
            @include focus-default;
        }

        &[disabled] {
            background-color: $cf-color-input-disabled-bg;
            cursor: not-allowed;
        }
    }

    &.cf-c-date-picker--sm {
        .cf-c-date-picker__icon-wrap {
            width: 2.5rem;
        }

        .flatpickr-input {
            height: $cf-size-input-sm;
            font-size: $cf-font-size-sm;
        }
    }

    &.cf-has-text {
        .flatpickr-input {
            @include cf-input-has-text;
        }
    }

    .cf-c-date-picker__error-msg {
        position: relative;
        top: 100%;
        left: 0;
        right: 0;
        @include error-msg;
    }
}
