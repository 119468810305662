/**
 * @name Conformio - Radio Dot
 *
 */

.cf-c-radio-dot {
    @include flex-center;
    width: $cf-size-radio-dot;
    height: $cf-size-radio-dot;
    border: 2px solid $cf-color-primary;
    border-radius: $cf-radius-round;
    margin-top: calc(
        1em * #{$cf-font-line-height-stack} - (1em * 1.5) / 2 - #{$cf-size-radio-dot} /
            2
    );
    pointer-events: none;
    position: relative;

    &:after {
        content: '';
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: $cf-radius-round;
        background-color: $cf-color-primary;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
