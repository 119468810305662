/**
 * @name Conformio - Radio card
 *
 */

@include radio-dot-active-selector(
        '.cf-c-radio-label__input:checked ~ .cf-c-radio-label__dot-wrapper'
    )
    .cf-c-radio-label {
    position: relative;
    display: inline-flex;
}

.cf-c-radio-label__text {
    display: block;
    position: relative;
    padding: 0 1em 0 2em;
}

.cf-c-radio-label__text--disabled {
    color: $cf-color-gray-02;
}

.cf-c-radio-label__input {
    position: absolute;
    opacity: 0;
}

.cf-c-radio-label__dot-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $cf-size-radio-label-left-space;
    padding-left: 0.25em;
}
