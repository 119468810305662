.cf-c-modal__content {
    flex-direction: column;
    align-items: 'flex-start';
}

.cf-c-modal__content-title,
.cf-c-modal__content-text {
    text-align: left;
    font-size: $cf-font-size-sm;
}

.cf-c-modal__content-item {
    list-style: none;
}

.cf-c-modal__content-title {
    font-size: $cf-font-size-md;
    font-weight: bold;
}

.cf-c-modal__dialog {
    max-width: 400px;
}

.cf-c-modal__content-wrapper {
    border-radius: 12px;
}

.cf-c-modal__footer {
    border-top: none;
}
