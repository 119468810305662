/**
 * @name Conformio - My work comment item
 *
 */

.cf-c-my-work-comment {
    padding-top: $cf-gutter;
}

.cf-c-my-work-comment__media {
    @include flex-align-center;
    flex-wrap: wrap;
    margin-bottom: $cf-gutter;
}

.cf-c-my-work-comment__media-ctn {
    @include flex-justify-center;
    flex-direction: column;
}

.cf-c-my-work-comment__avatar {
    width: $cf-size-avatar-sm;
    height: $cf-size-avatar-sm;
    border-radius: $cf-radius-round;
    overflow: hidden;
    margin-right: $cf-gutter;
}

.cf-c-my-work-comment__author {
    color: $cf-color-primary;
}

.cf-c-my-work-comment__type {
    color: $cf-color-gray-03;
}

.cf-c-my-work-comment__date {
    color: $cf-color-gray-03;
    font-size: $cf-font-size-sm;
    line-height: 1.33;
}

.cf-c-my-work-comment__avatar-img {
    @include full-size;
    object-fit: cover;
}

.cf-c-my-work-comment__ctn {
    display: flex;
    flex-direction: column;
    margin-left: $cf-size-avatar-sm + $cf-gutter;

    p {
        margin-bottom: 1.5em;
    }
}
