
@import '@style/sfc.scss';

.app {
    display: flex;
    min-height: 100vh;

    &--with-header {
        height: 100vh;
        flex-direction: column;
    }

    &__content {
        @include thin-scrollbar;
        position: relative;
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: overlay;

        .app--with-nav & {
            height: 100vh;
            margin-left: rem($width-app-navigation-collapsed);
        }

        &--scrollable {
            @include thin-scrollbar;
            overflow-y: auto;
        }
    }

    &__side-panel {
        position: relative;
        width: rem($cf-side-panel-width);
        min-width: rem($cf-side-panel-width);
    }

    &__impersonated {
        padding: rem(8) rem(15);
        font-size: rem(18);
        font-weight: 700;
        line-height: calc(24 / 18);
        text-align: center;
        background-color: $cf-color-danger;
        color: $color-white;
    }
}
