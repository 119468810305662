/**
 * @name Conformio - Add file
 *
 */

.cf-pricing-plan-limits-alert {
    border: 1px solid #9fcffc;
    border-radius: 0.5rem;
    background-color: #c1e7ff;
    color: #4c8cc9;
    padding: 1.2rem;
    margin-bottom: 1rem;
}
.cf-pricing-plan-limits-alert .align-center {
    text-align: center;
    justify-content: center;
}
.cf-pricing-plan-limits-alert .align-left {
    text-align: left;
    justify-content: left;
}
.cf-pricing-plan-limits-alert .align-right {
    text-align: right;
    justify-content: right;
}
.cf-pricing-plan-limits-alert .flex-wrapper {
    display: flex;
}
.cf-pricing-plan-limits-alert .icon {
    margin-right: 5px;
}
.cf-pricing-plan-limits-alert .buttons-block {
    margin-top: 10px;
    width: 100%;
}
.cf-pricing-plan-limits-alert .buttons-block #learn-more {
    background-color: transparent;
    text-decoration: underline;
    color: #4788c6;
    font-weight: normal;
    text-transform: capitalize;
    margin-right: 10px;
}
.cf-pricing-plan-limits-alert .buttons-block #learn-more:focus {
    box-shadow: none;
}

.cf-c-app-modal--subscription-reached-limits {
    .cf-c-app-modal__header {
        justify-content: left;
    }
}
