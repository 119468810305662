/**
 * @name Conformio - Incident collapsible header\
 *
 */

.cf-c-incident-collapsible-header {
    @include flex-align-center;
    justify-content: space-between;
    flex: 1 1 0;
    min-width: 0;
}

.cf-c-incident-collapsible-header__title {
    margin-bottom: 0;
}

.cf-c-incident-collapsible-header__desc {
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
    font-weight: normal;
}

.cf-c-incident-collapsible-header__state-wrapper {
    @include flex-center;
    padding: $cf-gutter-sm $cf-gutter;
    gap: rem(20);
}

.cf-c-incident-collapsible-header__state-circle {
    @include shape-circle;
}
