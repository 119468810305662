/**
 * @name Conformio - Discussion section
 *
 */

.cf-c-discussions-section {
    &.cf-c-discussions-section--no-content {
        padding-top: $cf-gutter-lg;
    }
}

.cf-c-discussions-section:last-child {
    margin-bottom: $cf-gutter-xl;
}

.cf-c-discussions-section__item {
    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}
