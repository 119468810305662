/**
 * @name Conformio - My work card collapse button
 *
 */

.cf-c-my-work-task-card-collapse-btn__ctn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid $cf-color-gray;
    border-radius: 2px;
    width: 1.5rem;
    height: 1.5rem;
    color: $cf-color-primary;
    font-weight: bold;
    font-size: 16px;

    &:hover {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: pointer;
    }
}
