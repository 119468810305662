/**
 * @name Conformio - Status Dard Reporting Module Dashboard
 *
 */

.cf-c-status-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include cf-box;
    color: $cf-color-gray-03;
    padding: $cf-gutter-075 $cf-gutter-md;
    max-height: 11rem;
    min-height: 11rem;
    max-width: 14rem;
    width: 100%;
    text-align: center;
    justify-content: space-between;

    &.cf-c-status-card--long-title {
        padding: $cf-gutter-sm $cf-gutter-md;
        justify-content: space-evenly;

        .cf-c-status-card__title {
            font-size: $cf-gutter-075;
        }
    }

    &.cf-c-status-card--three-column-item {
        max-height: 8.25rem;
        min-height: 8.25rem;
        max-width: calc(100% / 3);
        margin: 0 $cf-gutter-lg;
    }

    &.cf-c-status-card--single-task {
        justify-content: flex-start;

        .cf-c-status-card__title {
            margin-bottom: $cf-gutter-md;
        }
    }

    &.cf-c-status-card--hovered {
        &:hover {
            border: 1px solid $cf-color-gray-02;
            cursor: pointer;
            transition: ease-in-out 0.3s;
        }
    }
}

.cf-c-status-card__title {
    font-size: $cf-font-size-stack;
}

.cf-c-status-card__percentage {
    font-size: $cf-font-size-xxl;
    color: $cf-color-primary;
}

.cf-c-status-card_percentage-info {
    font-size: $cf-font-size-sm;
}

.cf-c-status-card__footer {
    display: flex;
    align-items: center;
}
