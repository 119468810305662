.cf-c-modal--cancel-subscription {
    .cf-c-app-modal-wrapper.cf-c-app-modal--header-underlined {
        height: auto !important;

        .cf-c-app-modal {
            .cf-c-app-modal__body {
                margin: 0;
                padding: 0.5rem 1rem 1rem 1rem;
            }
        }
    }

    p {
        font-size: $cf-font-size-14;
        color: $cf-color-gray-03;
    }

    .cf-c-app-modal__footer {
        justify-content: space-between;
    }
}

.cancel-subscription-survey {
    h4 {
        margin: 16px 0 8px 0;
        font-size: $cf-font-size-md;
        line-height: $cf-font-line-height-stack;

        span {
            color: $cf-color-danger;
        }
    }

    .cf-c-radio-group {
        margin: 16px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .cf-c-radio-group__item {
            flex-basis: calc(50% - 8px);
            margin: 0 0 8px 0;
            background-color: $cf-color-gray;
        }

        .cf-c-radio-label {
            width: 100%;
            padding: 10px 16px;
            border-radius: 4px;
            border: 1px solid $cf-color-gray;
            font-size: $cf-font-size-14;
            line-height: $cf-font-size-md;
            color: $cf-color-gray-03;
            cursor: pointer;

            &.cf-is-checked {
                background-color: #fff;
                border-color: $cf-color-primary;
            }

            &__dot-wrapper {
                // Reset existing style
                position: relative;
                top: auto;
                bottom: auto;
                left: auto;
                width: auto;
                padding-left: 0;
            }

            &__text {
                padding: 0 8px;
            }
        }
    }

    .cf-c-textarea {
        min-height: 80px;
        border-radius: 4px;
        border-width: 1px;
        line-height: 1rem;
    }
}

.cancel-subscription-tooltip {
    .tooltip-inner {
        max-width: 16rem;
        font-size: $cf-font-size-sm;
    }
}
