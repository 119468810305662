/**
 * @name Conformio - Related non conformities
 *
 */

.cf-c-rel-non-conformities {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    background-color: $cf-color-gray;
    padding: $cf-gutter $cf-gutter-md;

    &.cf-c-rel-non-conformities--bg-white {
        background-color: $cf-color-white;
    }

    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }
}

.cf-c-rel-non-conformities__title-wrap {
    display: flex;
    align-items: center;
}

.cf-c-rel-non-conformities__title {
    border-bottom: 1px solid $cf-color-primary;
    color: $cf-color-primary;
    font-size: $cf-font-size-stack;
    line-height: $cf-font-line-height-xs;
}

.cf-c-rel-non-conformities__content {
    display: flex;
    align-items: center;
    height: 100%;
}

.cf-c-rel-non-conformities__content-text {
    max-width: 13em;
    text-align: right;
    color: $cf-color-warning;
    margin-right: $cf-gutter-xs;
}

.cf-c-rel-non-conformities__first-icon-wrap {
    margin: 0 $cf-gutter;
}

.cf-c-rel-non-conformities__last-icon-wrap {
    color: $cf-color-gray-02;
}
