.cf-c-vf-collapsible {
    display: flex;
    flex-direction: row;
    font-size: $cf-font-size-sm;

    & + & {
        border-top: none;
    }

    &.cf-c-vf-collapsible--border-top {
        & + & {
            border-top: 1px solid $cf-color-gray-01;
        }
    }

    &.cf-c-vf-collapsible--no-checkbox {
        & + & {
            border-top: 1px solid $cf-color-gray-01;
        }

        .cf-c-vf-chk-collapsible__header {
            box-shadow: $cf-c-shadow-default;
        }

        .cf-c-vf-chk-collapsible__icon-wrapper {
            border: none;
        }

        .cf-c-vf-chk-collapsible__trigger {
            height: 100%;
            border: none;
        }

        .cf-c-vf-chk-collapsible__checkbox {
            display: none;
        }

        .cf-c-vf-chk-collapsible__ctn {
            .cf-c-vf-btn {
                margin-left: auto;
            }
        }
    }

    &.cf-c-vf-collapsible--highlighted {
        > .cf-c-vf-collapsible__header {
            background-color: $cf-color-gray;
            font-size: $cf-font-size-stack;
            font-weight: bold;
        }
    }

    &.cf-c-vf-collapsible--toggle-full-height {
        .cf-c-vf-collapsible__icon-wrap {
            height: auto;
        }
    }

    &.cf-c-vf-collapsible--horizontal {
        display: flex;

        > .cf-c-vf-collapsible__content {
            border-top: none;
            border-left: 1px solid $cf-color-gray-01;
        }
    }

    &.cf-c-vf-collapsible--shadow {
        border: none;
        margin-bottom: $cf-gutter-md;

        .cf-c-vf-collapsible__header {
            box-shadow: $cf-c-shadow-default;
            border: 1px solid $cf-color-gray-01;
            padding-left: $cf-gutter-sm;
            flex-direction: row-reverse;
            font-size: $cf-font-size-stack;
            font-weight: bold;
        }

        .cf-c-vf-collapsible__content {
            > div {
                border-bottom: 1px solid $cf-color-gray-01;
                padding: $cf-gutter 0 $cf-gutter $cf-gutter-sm;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $cf-color-black;
                font-size: $cf-font-size-stack;
            }
        }
    }

    &.cf-is-open {
        .cf-c-vf-collapsible__header {
            .cf-c-vf-collapsible__icon {
                transform: rotate(-180deg);
            }
        }
    }

    .cf-c-vf-collapsible__content > & {
        border: none;
        border-bottom: 1px solid $cf-color-gray-01;

        &:last-child {
            margin-bottom: -1px;
        }
    }
}

.cf-c-vf-collapsible__content-wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.cf-c-vf-collapsible__header {
    display: flex;
    min-height: $cf-size-collapsible-min-height;
    color: $cf-color-gray-03;
}

.cf-c-vf-collapsible__icon-wrap {
    @include flex-center;
    flex-shrink: 0;
    cursor: pointer;
    user-select: none;
    &--no-cursor {
        cursor: default;
    }
}

.cf-c-vf-collapsible__icon {
    @include css-shape-arrow-down($size: 0.4rem);
    transition: transform $cf-animation-speed-fast ease-in-out;
    margin-left: 0.33rem;
    &--visible {
        border-color: $cf-color-gray-03 transparent;
    }
    &--should-show {
        border-color: $cf-color-primary transparent;
    }
    &--should-not-show {
        border-color: $cf-color-gray-02 transparent;
    }
}

.cf-c-vf-collapsible__header-ctn {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    &--visible {
        color: $cf-color-gray-03;
    }
    &--should-show {
        color: $cf-color-primary;
    }
    &--should-not-show {
        color: $cf-color-gray-02;
    }
}

.cf-c-vf-collapsible__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    word-break: break-word;
}

.cf-c-vf-collapsible__empty-ctn {
    @include flex-center;
    flex: 1 1 0;
    min-height: $cf-size-collapsible-min-height;
    padding: 0 $cf-gutter;
    text-align: center;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
    opacity: 0.21;
}

.cf-f-vf-collapsible__marker-wrap {
    padding-top: 0.625rem;
    margin-right: 0.375rem;
}

.cf-f-vf-collapsible__marker {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    &--visible {
        background-color: $cf-color-primary-light;
        @include regular-border;
    }
    &--should-show {
        background-color: $cf-color-primary-light;
        @include regular-border($color: $cf-color-primary-light);
        box-shadow: $cf-c-shadow-centred;
    }
    &--should-not-show {
        background-color: $cf-color-white;
        @include regular-border($color: $cf-color-gray-01);
        box-shadow: $cf-c-shadow-centred;
    }
}

.cf-f-vf-collapsible__marker-line {
    width: 0.25rem;
    height: 100%;
    background-color: $cf-color-primary;
    margin: 0 auto;
    &--should-show {
        background-color: $cf-color-primary-light;
    }
    &--should-not-show {
        background-color: $cf-color-gray-01;
    }
}
