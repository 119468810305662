/**
 * @name Conformio - Register section form
 *
 */

.cf-c-reg-section-form {
    display: flex;
    flex-wrap: wrap;
    padding: $cf-gutter $cf-gutter-sm 0;
    border: 2px solid $cf-color-primary;
    border-radius: $cf-radius;

    &.cf-c-reg-section-form--filled {
        border: 1px solid $cf-color-gray-03;
    }

    &.cf-c-reg-section-form--border-shadow {
        border: 1px solid $cf-color-gray-01;
        border-radius: $cf-radius-sm;
        box-shadow: $cf-c-shadow-default;
    }

    &.cf-c-reg-section-form--border {
        border: 1px solid $cf-color-gray-01;
        border-radius: $cf-radius-sm;
    }

    &.cf-c-reg-section-form--border-2x {
        border: 2px solid $cf-color-gray-01;
        border-radius: $cf-radius-sm;
    }

    &.cf-c-reg-section-form--border-primary {
        border-left: 8px solid $cf-color-primary;
    }

    &.cf-c-reg-section-form--no-border {
        border: none;
    }

    &.cf-c-reg-section-form--grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: max-content max-content auto;
        padding-top: $cf-gutter-sm;
        padding-bottom: $cf-gutter-sm;

        @supports not (display: grid) {
            .cf-c-reg-section-form__item {
                &:first-child {
                    flex-basis: 100%;
                }
            }
        }

        .cf-c-reg-section-form__item {
            margin-bottom: 0;

            &:first-child {
                grid-row: span 3;
            }
        }

        .cf-c-reg-section-form-group__title-wrap {
            flex-basis: 100%;
            margin-bottom: 0;
        }
    }

    &.cf-c-reg-section-form--list-item {
        &:not(:last-child) {
            margin-bottom: $cf-gutter;
        }
    }
}

.cf-c-reg-section-form__item {
    display: flex;
    flex-direction: column;
    flex: 0 1 50%;
    min-width: 0;
    margin-bottom: $cf-gutter;

    &.cf-c-reg-section-form__item--full {
        flex: 0 1 100%;
    }
}

.cf-c-reg-section-form__actions {
    display: flex;
    align-self: flex-end;
    margin-top: auto;
}

.cf-c-reg-section-form__actions-item {
    min-width: 5.5rem;
    margin-left: $cf-gutter;
}
