/**
 * @name Conformio - My Work Tasks Card
 *
 */

.cf-c-my-work-card {
    @include cf-box(0, 0);
    font-weight: bold;

    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}

.cf-c-my-work-card__title {
    color: $cf-color-gray-03;
    font-weight: bold;
}

.cf-c-my-work-card__stats {
    display: flex;
    justify-content: space-between;
    padding: $cf-gutter-md 0 $cf-gutter-sm $cf-gutter;
}

.cf-c-my-work-card__list {
    flex: 3;
    color: $cf-color-gray-03;
    font-size: $cf-font-size-sm;
}

.cf-c-my-work-card__list-item {
    display: flex;
    align-items: center;
    margin-bottom: $cf-gutter-sm;

    &:first-child {
        color: $cf-color-danger;
    }

    &:nth-child(2) {
        color: $cf-color-amber;
    }

    &:nth-child(3) {
        color: $cf-color-secondary-light;
    }

    &:nth-child(4) {
        color: $cf-color-gray-03;
    }
}

.cf-c-my-work-card__action-wrapper {
    display: flex;
    justify-content: flex-end;
    color: $cf-color-primary;
    border-bottom: 1px solid $cf-color-gray-01;
    padding-bottom: $cf-gutter-sm;
}

.cf-c-my-work-card__overdue-container {
    padding: $cf-gutter;
    max-height: (3.125rem * 7) + 2rem; /* Multiply by height of each item in row */
    overflow: auto;
}

.cf-c-my-work-card__overdue-title {
    color: $cf-color-danger;
    text-transform: capitalize;
    font-size: $cf-font-stack;
    margin-left: $cf-gutter-md;
    margin-top: $cf-gutter-sm;
}

.cf-c-my-work__donut {
    width: 12rem;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
