/**
 * @name Conformio - Dropdown List
 *
 */

.cf-c-dropdown-list {
    display: flex;
    flex-direction: column;
    margin-bottom: $cf-gutter;
}

.cf-c-dropdown-list__header {
    color: $cf-color-gray-03;
    padding: $cf-gutter-075 $cf-gutter;
    cursor: pointer;

    &:hover {
        background-color: $cf-color-background;
    }
}

.cf-c-dropdown-list__header--open {
    background: $cf-color-background;
}

.cf-c-dropdown-list__list {
    border: 1px solid $cf-color-gray-01;
    color: $cf-color-gray-03;
    background: $cf-color-white;
    list-style: none;
    padding: $cf-gutter 3.18rem 0 $cf-gutter-lg;
}

.vs__dropdown-option--disabled {
    font-style: italic;
}
