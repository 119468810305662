/**
 * @name Conformio - History card
 *
 */

.cf-c-history-card {
    @include box-outline(
        $cf-color-discussion-card-border-active,
        $cf-color-primary-lighter
    );
}

.cf-c-history-card__title {
    min-height: 4rem;
    margin-bottom: 0;
    padding: $cf-gutter-sm;
    font-size: $cf-font-size-sm;
    line-height: 2;
}

// history cards wrapper

.cf-c-history-card-list {
    list-style: none;
}

.cf-c-history-card-list__item {
    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }
}
