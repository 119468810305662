/**
 * @name Conformio - Discussions box
 *
 */

.cf-c-discussions-box {
    @include box-outline;

    // NOTE: if we change here the name of the class we need to change it in discussion-card.scss file too
    &.cf-is-active {
        background-color: $cf-color-primary-lighter;
        border-color: $cf-color-discussion-card-border-active;
    }
}

.cf-c-discussions-box__replies {
    margin-top: $cf-gutter;
    list-style: none;
}

.cf-c-discussions-box__item {
    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}
