// Fade
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.fade-leave-active {
    position: absolute;
}

// Slide left
.slide-left-enter-active,
.slide-left-leave-active {
    transition: transform 0.3s ease-in-out;
}

.slide-left-enter,
.slide-left-leave-to {
    transform: translateX(100%);
}
