/**
 * @name Conformio - File item
 *
 */

.cf-c-file-item {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    color: $cf-color-primary;
}

.cf-c-file-item__icon-wrap {
    position: relative;
}

.cf-c-file-item__name {
    display: flex;
    justify-content: center;
    width: 2.75rem;
    font-size: $cf-font-size-xs;
    text-align: center;
    cursor: default;
}

.cf-c-file-item__name-link {
    @include text-ellipsis;
}

.cf-c-file-item__file-name {
    @include text-ellipsis;
    flex-shrink: 1;
    min-width: 0;
}

.cf-c-file-item__name-ext {
    flex-shrink: 0;
    white-space: nowrap;
}

.cf-c-file-item__remove {
    cursor: pointer;
    position: absolute;
    top: -3px;
    right: -3px;
}
