/**
 * @name Conformio - Register form group
 *
 */

.cf-c-register-form-group__row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $cf-gutter;
}

.cf-c-register-form-group__col {
    flex: 1 1 0;
    min-width: 0;
    padding: 0 $cf-gutter;
}
