/**
 * @name Conformio - Register Heading
 *
 */

.cf-c-reg-heading {
    @include flex-align-center;
    width: 100%;

    &.cf-has-options-disabled {
        .cf-c-reg-heading__options {
            opacity: 0.4;
            pointer-events: none;
        }
    }
}

.cf-c-reg-heading__options {
    display: flex;
    max-width: 100%;
    justify-content: flex-end;
    flex: 1 1 0;
}
