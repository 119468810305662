/**
 * @name Conformio - Step by step
 *
 */

.cf-c-step-by-step {
    max-width: rem(1100);
    margin-top: $cf-gutter-sm;
    border-top: 1px solid $cf-color-border-main;
}

.cf-c-step-by-step-section-item__heading {
    margin: 0;
    padding-top: $cf-gutter-md;
    padding-bottom: $cf-gutter-md;
    color: $cf-color-gray-03;
    font-weight: normal;
}

.cf-c-step-by-step-section-item {
    display: flex;

    &:nth-child(odd) {
        padding-right: rem(25);
    }

    &:nth-child(even) {
        padding-left: rem(25);
    }
}

// overview & output

.cf-c-step-by-step-overview,
.cf-c-step-by-step-output {
    display: flex;
    flex-direction: column;
    margin-bottom: rem(50);
    flex: 1;
    min-width: 0;
}

// overview

.cf-c-step-by-step-overview {
    position: relative;
}

.cf-c-step-by-step-overview__arrow-down,
.cf-c-step-by-step-overview__arrow-right {
    color: $cf-color-primary;
}

.cf-c-step-by-step-overview__arrow-down {
    @include flex-center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: rem(50);
}

.cf-c-step-by-step-overview__arrow-right {
    @include flex-center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 100%;
    width: rem(50);
}
