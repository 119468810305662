/**
 * @name Conformio - Controls List
 *
 */

.cf-c-controls-list {
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius-sm;
    line-height: $cf-font-line-height-sm;

    // parent override (modifier)
    .cf-c-collapsible__content > & {
        border: none;
        box-shadow: none;
    }
}

.cf-c-controls-list__item {
    display: flex;
    align-items: flex-start;
    color: $cf-color-gray-03;

    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }
}

.cf-c-controls-list__col {
    @include flex-align-center;
    padding: $cf-gutter-sm $cf-gutter-sm $cf-gutter-sm 0;
    min-height: 3rem;

    &:first-child {
        padding-right: 0;
    }
}
