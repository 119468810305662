/**
 * @name Conformio - Color box
 *
 */

.cf-c-color-box {
    width: 1rem;
    height: 1rem;
    background-color: currentColor;
    border-radius: $cf-radius-sm;
}
