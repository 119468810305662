@media print {
    body {
        line-height: 1;
    }

    table {
        width: 100%;
    }

    thead {
        display: table-row-group;
    }

    tr {
        border-bottom: $cf-radius-sm solid black !important;
    }

    td,
    th {
        border-right: $cf-radius-sm solid black !important;
    }

    .page-break {
        page-break-after: always;
    }

    .dw-editor__document-highlight {
        padding: $cf-gutter-lg $cf-gutter-xxl * 1.5;

        table:not(#records) {
            -webkit-print-color-adjust: exact;
            border: $cf-radius-sm solid black !important;
        }

        #records {
            border-top: $cf-radius-sm solid black !important;
            border-left: $cf-radius-sm solid black !important;
        }

        &.cf-is-highlighted {
            &:after {
                opacity: 0;
            }
        }
    }

    .dw-editor__document-content {
        :not(#records) {
            font-size: $cf-font-size-31;

            h3,
            #cover-page > .center {
                font-size: $cf-font-size-34;
            }

            h2.main-title {
                font-size: $cf-font-size-xxl !important;
            }
        }

        .preview {
            margin-top: 500px;

            .document-content {
                margin-top: 0;
            }
        }

        .table-user-data {
            margin-bottom: 0;
        }

        .cf-c-input__ctn {
            .cf-c-input__field {
                border: none;
                color: #212529;
                background-color: #ffffff;
                -webkit-text-fill-color: #212529;
            }
        }

        .cf-c-parsed-table {
            table-layout: auto;
        }
    }

    .dw-editor__document-highlight:after {
        display: none;
    }

    .custom-paragraph__action-block {
        display: none;
    }
}
