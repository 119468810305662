/**
 * @name Conformio - Collapsible top header
 *
 */

.cf-c-collapsible-top-header {
    display: flex;

    &.cf-c-collapsible-top-header--sm {
        margin-bottom: $cf-gutter;
        font-size: $cf-font-size-sm;
        line-height: 1.3;
    }
}

.cf-c-collapsible-top-header__col {
    @include flex-align-center;
    flex: 1 1 0;
    padding: $cf-gutter-xs $cf-gutter-xs $cf-gutter-xs $cf-gutter-sm;

    &--min-width-0 {
        min-width: 0;
    }

    .cf-c-icon {
        cursor: pointer;
    }
}

.cf-c-collapsible-top-header__text {
    @include text-ellipsis;
    max-width: max-content;
    flex: 1;
    margin: 0;
}

.cf-u-color-limit-orange {
    color: #fa6400 !important;
}

.cf-u-color-limit-red {
    color: #f82f47 !important;
}

.cf-u-limit-default {
    color: #676767;
}

.cf-u-limit-default-counter {
    font-size: 16px;
}

.cf-u-limit-default-text {
    font-size: 12px;
}

.cf-c-collapsible-top-header-fixed {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: #fff;
    padding-top: 20px;
    border-bottom: 1px solid #e5e7e8;
}
