/**
 * @name Conformio - Search
 *
 */

.cf-c-search {
    position: relative;

    &.cf-c-search--sm {
        .cf-c-search__field-wrapper {
            height: $cf-size-input-sm;
        }

        .cf-c-search__field {
            font-size: $cf-font-size-sm;
        }

        .cf-c-search__icon-wrapper {
            width: $cf-size-input-sm;
        }
    }
}

.cf-c-search__field-wrapper {
    height: $cf-size-input;
}

.cf-c-search__field {
    @include input-clear;
    @include absolute-full;
    @include cf-input;
    padding: $cf-gutter-sm;
}

.cf-c-search__icon-wrapper {
    @include flex-center;
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    width: $cf-size-input;
    border-radius: $cf-radius;
    color: $cf-color-primary;
    background-color: $cf-color-white;
}

.cf-c-search-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 18rem;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: $cf-gutter-lg;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    list-style: none;
    z-index: $cf-z-index-search-risks-options;
}
