/**
 * @name Conformio - Data Card Table
 *
 */

.cf-c-data-card-table {
    width: 100%;
    table-layout: fixed;
}

.cf-c-data-card-table__th,
.cf-c-data-card-table__td {
    width: 50%;
    word-break: break-word;
}

.cf-c-data-card-table__th {
    @include data-card-highlight-title;
}

.cf-c-data-card-table__td {
    @include data-card-text-default;
    padding-left: $cf-gutter-sm;
}
