
@import '@style/sfc.scss';

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 1500;
    background-color: rgba(0, 0, 0, 0.1);

    > svg {
        height: 96px;
        width: 96px;
    }

    &--is-absolute {
        position: absolute;
        z-index: $z-index-loader-overlay;
        background-color: rgba(255, 255, 255, 0.6);
    }
}
