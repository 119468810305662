/**
 * @name Conformio - Step card
 *
 */

.cf-c-step-card {
    @include cf-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
    min-height: $cf-size-step-card-min-height;
    padding: $cf-gutter;
    color: $cf-color-gray-03;
}

.cf-c-step-card__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.cf-c-step-card__title-wrap {
    position: relative;
    min-height: $cf-font-size-md * $cf-font-line-height-stack;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-step-card__title {
    font-weight: bold;
    margin-bottom: 0;
}

.cf-c-step-card__desc,
.cf-c-step-card__documents {
    min-height: $cf-size-step-card-sec-row-min-height;
    margin-bottom: $cf-gutter;
}

.cf-c-step-card__desc {
    font-size: $cf-font-size-sm;
    color: $cf-color-black;
}

.cf-c-step-card__progress {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    margin-bottom: rem(10);
}

.cf-c-step-card__progress-title {
    flex: 1 1 100%;
    margin-bottom: $cf-gutter-xs;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-black;
}

.cf-c-step-card__progress-bar-wrap {
    @include flex-align-center;
    flex: 1;
}

.cf-c-step-card__progress-val {
    margin-left: $cf-gutter-sm;
    vertical-align: middle;
    font-weight: bold;
}

.cf-c-step-card__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: auto;
}

.cf-c-step-card__footer-performer,
.cf-c-step-card__footer-actions {
    margin-top: $cf-gutter-sm;
}

.cf-c-step-card__footer-performer {
    @include flex-align-center;
    flex: 1;
    max-width: calc(100% - #{$cf-gutter});
    margin-right: $cf-gutter;
}

.cf-c-step-card__footer-actions {
    display: flex;
    align-items: flex-end;
}

.cf-c-performer {
    @include flex-align-center;
    max-width: 100%;

    &.cf-c-performer--editable {
        flex-direction: column;
        align-items: flex-start;
    }
}

.cf-c-performer__name {
    @include flex-align-center;
    font-weight: bold;
}

.cf-c-performer__name-dot {
    width: $cf-gutter-xs;
    height: $cf-gutter-xs;
    border-radius: $cf-radius-round;
    background-color: $cf-color-gray-03;
    margin: 0 $cf-gutter-sm;
    flex-shrink: 0;
}

.cf-c-performer__title {
    max-width: 100%;
    text-transform: uppercase;
}

.cf-c-step-card__documents-item {
    margin-bottom: $cf-gutter-sm;
}

.cf-c-step-card-doc-item-status {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    text-transform: uppercase;

    &.cf-c-step-card-doc-item-status--is-approved,
    &.cf-c-step-card-doc-item-status--is-up-to-date {
        color: $cf-color-secondary;
    }

    &.cf-c-step-card-doc-item-status--is-to-do {
        color: $cf-color-danger;
    }

    &.cf-c-step-card-doc-item-status--is-in-progress {
        color: $cf-color-warning;
    }
}

.cf-c-step-card-doc-item-status__progress {
    margin-bottom: $cf-gutter-xs;
}

.cf-c-step-card__content-empty {
    opacity: 0.4;
}

.cf-c-step-card__content-empty-text {
    max-width: 300px;
}

.cf-c-step-card__btn--disabled,
.cf-c-step-card__btn--disabled:hover {
    color: $cf-color-white;
    background-color: $cf-color-gray-01;
    border-color: $cf-color-gray-01;
}
