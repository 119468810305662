
@import '@style/sfc.scss';

::v-deep .app-modal-new {
    position: relative;
    left: 0 !important;
    max-width: rem(600);
    width: calc(100% - #{rem(30)}) !important;
    margin: 0 auto;

    &--lg {
        max-width: rem(800);
    }

    &--with-overflow-visible {
        overflow: visible;
    }
}

.app-modal-new {
    &__wrap {
        position: relative;
        padding: rem(24);
    }

    &__cancel-action {
        position: absolute;
        top: rem(24);
        right: rem(24);
        display: flex;
        height: rem(24);
        width: rem(24);
        padding: 0;
        border: 0;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        color: $color-primary-beta;
        outline: none;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }

    &__title {
        margin: 0;
        padding-right: rem(40);
        padding-bottom: rem(22);
        font-size: rem(24);
        font-weight: 700;
        line-height: calc(32 / 24);
        color: $color-primary-beta;
    }

    &__content {
        padding-bottom: rem(40);
        font-size: rem(16);
        font-weight: 400;
        line-height: calc(22 / 16);
        color: $color-primary-beta;

        &--no-padding {
            padding-bottom: 0;
        }
    }

    &__actions-wrap {
        display: flex;
        gap: rem(24);
        justify-content: flex-end;
    }
}
