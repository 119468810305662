// Modal overrides
.vm--modal {
    border: 1px solid $color-primary-gamma;
    border-radius: rem(4);
    box-shadow: 0 rem(4) rem(4) rem(2) rgba(51, 51, 51, 0.3);
}

.vm--overlay {
    background-color: $color-modal-overlay;
    backdrop-filter: blur(2px);
}

.v--modal-background-click {
    display: flex;
    padding: $cf-gutter;
}

.cf-c-app-modal-wrapper {
    top: auto;
    left: auto;
    width: auto;
    margin: auto;
    max-width: 25rem;
    background-color: white;
    text-align: left;
    border-radius: 0;
    box-shadow: $cf-c-shadow-modal-box;
    padding: 0;
    overflow: visible !important;

    &.v--modal-box.v--modal-box.v--modal-box {
        overflow: visible;
    }

    &.cf-c-app-modal-wrapper--min-width-23 {
        min-width: 23rem;
    }

    &.cf-c-app-modal-wrapper--width-23 {
        max-width: 23rem;
        min-width: 23rem;
    }

    &.cf-c-app-modal-wrapper--max-width-53 {
        max-width: 53rem;
    }

    &.cf-c-app-modal-wrapper--max-width-63 {
        max-width: 63rem;
    }

    &.cf-c-app-modal-wrapper--max-width-38 {
        max-width: 38rem;
    }

    &.cf-c-app-modal-wrapper--max-width-25 {
        max-width: 25rem;
        min-width: 25rem;
    }

    &.cf-c-app-modal-wrapper--max-width-30 {
        max-width: 30rem;
        min-width: 30rem;
    }

    &.cf-c-app-modal-wrapper--max-width-27 {
        max-width: 27rem;
        min-width: 27rem;
    }

    &.cf-c-app-modal-wrapper--max-width-42 {
        max-width: 42rem;
    }

    &.cf-c-app-modal-wrapper--min-width-44 {
        min-width: 44rem;
    }

    &.cf-c-app-modal-wrapper--min-width-42 {
        min-width: 42rem;
    }

    &.cf-c-app-modal-wrapper--min-width-50 {
        min-width: 50rem;
    }

    &.cf-c-app-modal-wrapper--min-width-70 {
        min-width: 70rem;
    }

    &.cf-c-app-modal-wrapper--modal-explorer {
        min-width: 30rem;
        max-width: 50rem;

        .cf-c-modal-table {
            width: auto;

            .cf-c-modal-table__td:first-child {
                width: auto;
            }
        }
    }

    &.cf-c-app-modal--no-border.cf-c-app-modal-wrapper--approval {
        .cf-c-app-modal {
            .cf-c-app-modal__body {
                border-top: 0;
                padding-top: 0;
            }
        }
    }

    &.cf-c-app-modal-wrapper--scrollable-body {
        .cf-c-app-modal__body {
            max-height: calc(100vh - 12.5rem);
            overflow: auto;
        }
    }

    &.cf-c-app-modal-wrapper--scrollable-body-md {
        .cf-c-app-modal__body {
            max-height: calc(100vh - 22.5rem);
            overflow: auto;
        }
    }

    &.cf-c-app-modal--header-underlined {
        .cf-c-app-modal {
            .cf-c-app-modal__header {
                padding: $cf-gutter 0 $cf-gutter-sm;
                margin: 0 $cf-gutter;

                .cf-c-modal-title {
                    font-size: 1rem;
                }
            }

            .cf-c-modal-underline {
                display: block;
                border-top: 1px solid $cf-color-gray-01;
            }

            .cf-c-app-modal__body {
                margin-bottom: 0.5rem;
                margin-left: -0.5rem;
                padding: 0 1rem 0 1.5rem;

                .cf-c-reg-section-form {
                    box-shadow: none;
                    border: none;
                    margin: $cf-gutter-075 0;
                    padding: $cf-gutter 0 0;
                }
            }

            .cf-c-app-modal__footer {
                padding-top: 0;
            }
        }
    }

    &.cf-c-app-modal--flat {
        .cf-c-app-modal-wrapper {
            border-radius: 0;
            box-shadow: $cf-c-shadow-default;
        }

        .cf-c-app-modal__body {
            padding-left: $cf-gutter-xl;
            padding-right: $cf-gutter-lg;
            padding-bottom: $cf-gutter-xl;
            margin-bottom: 0;
        }

        .cf-c-app-modal__header > .cf-c-icon {
            color: $cf-color-primary;
        }
    }

    &.cf-c-app-modal-wrapper--approval {
        .cf-c-app-modal__body {
            margin: 0 $cf-gutter;
            padding: $cf-gutter 0 0 0;
            border-top: 1px solid $cf-color-gray-01;

            > .cf-u-box {
                max-height: 31rem;
                min-height: 31rem;
                height: 31rem;
                overflow: auto;
            }
        }
    }

    &.cf-c-app-modal--vulnerability {
        .cf-c-app-modal__header {
            margin-bottom: 0;
            font-size: 1.125rem;
            line-height: 1.55;
        }

        .cf-c-modal__text {
            margin-bottom: 2.5rem;
            line-height: 1.33;
        }

        .cf-c-app-modal__body {
            margin-bottom: 3.75rem;
        }
    }

    &.cf-c-app-modal--stepper {
        .cf-c-app-modal__header {
            margin-bottom: 0.75rem;
            font-size: 1.125rem;
            line-height: 1.56;
            color: $cf-color-black;
        }

        .cf-c-app-modal__body {
            margin-bottom: 2rem;

            .cf-c-searchable-list {
                .cf-c-searchable-list__search {
                    .cf-c-input__field {
                        font-size: 0.75rem;
                        line-height: 1.33;
                        border-radius: 2px 2px 0 0;
                        border: 1px solid $cf-color-gray-02;
                        background-color: $cf-color-gray;

                        &::placeholder {
                            font-size: 0.75rem;
                            line-height: 1.33;
                        }

                        &:focus {
                            box-shadow: none;
                            border-color: $cf-color-focus;
                        }
                    }
                }

                .cf-c-searchable-list__items {
                    margin-top: -1px;
                    border-radius: 0 0 2px 2px;
                    max-height: 10.4rem;
                }
            }
        }
    }
}

// custom styles

.cf-c-app-modal__header {
    padding: $cf-gutter;
    margin: 0;
    line-height: $cf-font-line-height-stack;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .icon {
        margin-right: 5px;
    }

    > .cf-u-cursor-pointer {
        float: right;
    }

    > h4 {
        margin-bottom: 0;
        word-break: break-word;
    }
}

.cf-c-app-modal__body {
    display: flex;
    flex-direction: column;
    padding: 0 $cf-gutter $cf-gutter $cf-gutter;
}

.cf-c-app-modal__footer {
    display: flex;
    padding: $cf-gutter;
    justify-content: flex-end;
}

.cf-c-app-modal__footer-item {
    &:not(:last-child) {
        margin-right: $cf-gutter;
    }

    .cf-c-btn {
        height: 100%;
        max-width: none;
    }
}

.cf-c-modal__subheading {
    font-weight: bold;
    line-height: $cf-font-line-height-stack;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-modal__note {
    font-size: $cf-font-size-stack;
    margin-bottom: $cf-gutter-md;
}

.cf-c-modal__text {
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;

    &.padded {
        padding-bottom: 10px;
    }
}

.cf-v-modal-embedded {
    position: absolute !important;
    background-color: transparent !important;
    background: transparent !important;
    height: auto !important;
    min-height: auto !important;
    top: -10% !important;
    width: auto !important;
    left: 50% !important;
    transform: translateX(-50%) !important;

    .close {
        cursor: pointer;
        font-size: 20px;
        position: relative;
        top: -9px;
    }
}

.wtlm {
    display: inline-block;
    position: relative;
    margin-right: 24px;
    cursor: pointer;
    font-style: normal;
    padding-right: 10px;
    margin-left: 5px;
    &::after {
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        position: absolute;
        bottom: -2px;
        right: 2px;
        transform: translateX(50%);
        background: url('./assets/italic-info-icon.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
}

.modal-layer-0 {
    z-index: 998 !important;
}

.modal-layer-1 {
    z-index: 999 !important;
}

.cf-modal-black-center-txt {
    text-align: center;
    color: black;
}

.cf-modal-list {
    width: 90%;
    margin: 0 auto;

    li {
        padding: 0.5rem 0;
    }
}
