// Config
@import 'variables/_variables';
@import '_mixins';

// Fonts
@import 'font-face/_font-face';

@import 'partials/variables', 'partials/layouts', 'components/icons',
    'components/buttons', 'components/input/input',
    'components/input/input-enable', 'components/select-native',
    'components/single-activity', 'components/components',
    'components/security-objective', 'components/sidebar/sidebar-doc-wiz',
    'components/labeled-icon', 'components/progress-bar',
    'components/steps/step-by-step', 'components/steps/step-card',
    'components/steps/step-card-stat', 'components/steps/single-step',
    'components/steps/finish-step-modal', 'components/onboarding-heading',
    'components/main-section', 'components/input/radio/radio-dot',
    'components/radio-card', 'components/input/radio/radio-label',
    'components/input/radio/radio-group', 'components/add-file',
    'components/pagination', 'components/video-responsive',
    'components/data-card/data-card', 'components/data-card/data-card-section',
    'components/data-card/data-card-table', 'components/department',
    'components/document-wizard/document-wizard-ctn',
    'components/document-wizard/document-wizard-header',
    'components/document-wizard/document-wizard-heading-info',
    'components/document-wizard/document-wizard-document',
    'components/document-wizard/document-wizard-input-mode',
    'components/document-wizard/document-wizard-help',
    'components/document-wizard/document-wizard-document-print',
    'components/document-wizard/document-wizard',
    'components/document-wizard/document-wizard-document-content',
    'components/tabs/tabs', 'components/tabs/pill-tabs',
    'components/tabs/tab-content-accordion',
    'components/tabs/tab-content-accordion-trigger',
    'partials/overrides/overrides', 'partials/overrides/multiselect',
    'partials/overrides/multiselect-checkbox',
    'components/discussion/discussions-box',
    'components/discussion/discussion-card',
    'components/discussion/discussions-tab-ctn',
    'components/discussion/discussions-section', 'components/history-card',
    'components/dms/file-and-directory-explorer', 'components/dms/file-preview',
    'components/checkbox/checkbox', 'components/checkbox/labeled-checkbox',
    'components/checkbox/labeled-checkbox-list', 'components/table',
    'components/about-modal', 'components/approval-modal',
    'components/list-mode-card', 'components/list-mode-list',
    'components/box-card', 'components/register-section/register-heading',
    'components/register-section/register-section',
    'components/register-section/register-section-form',
    'components/register-section/register-section-form-group',
    'components/form-switch', 'components/add-comment', 'components/my-profile',
    'components/basic-dropdown', 'components/modal', 'components/training-input',
    'components/map', 'components/trainee-list', 'components/modal-explorer',
    'components/modal-explorer-breadcrumbs', 'components/modal-table',
    'components/modal-wtlm', 'components/date-picker', 'components/typeahead',
    'components/step-bar', 'components/collapsible/collapsible',
    'components/collapsible/collapsible-top-header',
    'components/collapsible/collapsible-visual-flow',
    'components/multiple-checklist',
    'components/advanced-collapsible/advanced-collapsible',
    'components/advanced-collapsible/advanced-collapsible-subsection',
    'components/vertical-switch-options', 'components/links-list/links-list',
    'components/links-list/links-list-item', 'components/checkbox-grid-list',
    'components/soa-item', 'components/color-box', 'components/color-box-list',
    'components/controls-list', 'components/ror-review-header',
    'components/searchable-list', 'components/checkbox-list',
    'components/app-tags', 'components/search', 'components/related-risk',
    'components/attach-file', 'components/incident-collapsible-header',
    'components/underlined-collapsible',
    'components/underlined-collapsible-empty-state',
    'components/subsection-primary', 'components/task-collapsible',
    'components/register-form-group', 'components/pill',
    'components/adaptation-section', 'components/draggable-item',
    'components/adaptation-content', 'components/internal-audit-card',
    'components/file-item', 'components/related-non-conformities',
    'components/internal-audit-search', 'components/donut-progress',
    'components/StatusCardDash',
    'components/collapsible-right-toggle/collapsible-right-toggle',
    'components/hr-text', 'components/dropdown-list/drop-down-list',
    'components/dropdown-list/list-item',
    'components/my-work/my-work-tasks-card',
    'components/my-work/my-work-single-task',
    'components/my-work/my-work-custom-card',
    'components/my-work/my-work-task-card-collapse-button',
    'components/cube-tag', 'components/collapsible/collapsible-with-checkbox',
    'components/icon-wrapper', 'components/sortable-table-header-item',
    'components/my-work-discussion-card', 'components/my-work-comments',
    'components/my-work-comment',
    'components/RegistersAndModules/registers_custom-card',
    'components/single-training',
    'components/corrective-actions/corrective-actions-item',
    'components/login/login', 'components/register/register',
    'components/subscription', 'components/plan', 'components/price',
    'components/notification-settings', 'components/suggested-card',
    'components/preonboarding/preonboarding', 'components/paid-plan',
    'components/certification', 'components/switch', 'components/transitions',
    'components/app-current-plan-limits-restrictions-alert',
    'components/notifications', 'components/modals/modal-cancel-subscription',
    'components/consultant/_select-company',
    'components/modals/modal-payment-support', 'components/filter-bar',
    'partials/utility', 'components/input/custom-paragraph';

// Refactor this at later point
.cf-l-user-management {
    display: flex;
    height: 100%;
    width: 100%;
    padding: rem($gutter * 2) rem($gutter) rem($gutter);
    flex-direction: column;
}

// Global
@import '_global';

// Imports
@import '_animations';
@import '_color-classes';
@import 'layouts/_layout';
@import 'components/_headlines';
@import 'components/_texts';
@import 'components/_tooltips';
