.title {
    margin: 0;
    font-family: $font-open-sans;

    &--h1 {
        font-size: rem(40);
        line-height: calc(48 / 40);
        font-weight: 700;
    }

    &--h2 {
        font-size: rem(30);
        line-height: calc(38 / 30);
        font-weight: 700;
    }

    &--h3 {
        font-size: rem(24);
        line-height: calc(32 / 24);
        font-weight: 700;
    }

    &--h4 {
        font-size: rem(20);
        line-height: calc(28 / 20);
        font-weight: 700;
    }

    // Color variations
    &--alpha {
        color: $color-primary-beta;
    }
}
