/**
 * @name Conformio - My work comments
 *
 */

.cf-c-my-work-comments {
    list-style-type: none;
}

.cf-c-my-work-comments__item {
    &.is-reply {
        margin-left: $cf-size-avatar-sm + $cf-gutter;
        border-top: 1px solid $cf-color-border-main;
    }
}
