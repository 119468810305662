.cf-c-modal--payment-support {
    .cf-c-app-modal__header > h4 {
        font-size: 1.75rem;
    }

    .cf-c-reg-section-form-group__title-text {
        font-size: 1.25rem;
    }

    .cf-c-modal--subject-input {
        font-size: 0.8rem;
    }
}
