/**
 * @name Conformio - Step card statistics
 *
 */

.cf-c-step-card-stat {
    font-size: $cf-font-size-sm;
    font-weight: bold;
}

.cf-c-step-card-stat__table {
    width: 100%;
    margin-bottom: $cf-gutter;

    td {
        padding-top: $cf-gutter-sm;
        padding-bottom: $cf-gutter-sm;
    }
}

.cf-c-step-card-stat__col-1,
.cf-c-step-card-stat__col-2,
.cf-c-step-card-stat__col-3 {
    padding: 0;
}

.cf-c-step-card-stat__col-1,
.cf-c-step-card-stat__col-2 {
    width: $cf-gutter-sm;
}

.cf-c-step-card-stat__col-1 {
    text-transform: uppercase;
}

.cf-c-step-card-stat__col-2 {
    padding: 0 $cf-gutter-sm;
}
