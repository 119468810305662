/**
 * @name Conformio - Typeahead
 *
 */

// overrides

.cf-c-typeahead {
    &.cf-c-typeahead--height-sm {
        .vs__dropdown-toggle {
            min-height: $cf-size-input-sm;
        }

        .vs__clear {
            top: 1rem;
            transform: translateY(-50%);
        }
    }

    &.cf-c-typeahead--has-error {
        .vs__dropdown-toggle {
            @include cf-input-has-error;
        }
    }

    &.cf-c-typeahead--fsz-sm {
        .v-select {
            font-size: $cf-font-size-sm;
        }

        .vs__actions {
            &:after {
                top: $cf-size-input-sm / 2;
            }
        }
    }

    &.cf-c-typeahead--has-text {
        .vs__dropdown-toggle {
            border-color: $cf-color-input-has-text-border;
        }
    }

    &.cf-c-typeahead--min-width-8 {
        .v-select {
            min-width: 8rem;
        }
    }

    &.cf-c-typeahead--opacity-70 {
        .v-select {
            .vs__dropdown-toggle {
                opacity: 0.7;
            }
        }
    }

    &.cf-c-typeahead--full-width {
        .v-select {
            width: 100%;
        }
    }

    &.cf-c-typehead--placeholder-ellipsis {
        input {
            @include text-ellipsis;
        }
    }

    &.cf-c-typehead--is-tagged {
        .vs--single.vs--open {
            .vs__selected {
                min-height: auto;
            }
        }

        .vs__selected-options {
            display: flex;
            align-items: center;
            margin-right: $cf-gutter-075;
            max-width: 100%;
        }

        .vs__selected {
            @include text-ellipsis;
            padding: 0 0.5rem 0 0.5rem;
            color: $cf-color-white;
            font-size: $cf-font-size-stack;
            font-weight: bold;
            border-radius: 100px;
            background-color: $cf-color-primary;
            min-height: auto;
            display: block;
            position: relative;
            transform: translate(0);
            top: 0;
        }

        .vs__search {
            width: 0;
            border: none;
            padding: 0;
            display: none;
        }
    }

    .v-select {
        &.vs--disabled {
            .vs__dropdown-toggle {
                background: $cf-color-gray;

                .vs__actions {
                    display: none;
                }
            }
        }
    }

    .vs--disabled .vs__search {
        background-color: transparent;
    }

    .vs__dropdown-toggle {
        @include cf-input;
        height: auto;
        min-height: $cf-size-input;
        padding: 0 $cf-size-select-native-right-icon-width 0 $cf-gutter-075;
    }

    .vs__selected-options {
        padding: 0;
    }

    .vs__search {
        margin: 0;
        border: none;
        padding: 0;

        @include placeholder {
            color: $cf-color-input-placeholder;
        }
    }

    .vs__actions {
        @include select-native-right-icon;
        padding: 0;
        pointer-events: auto;

        &:after {
            display: inline-block;
            position: absolute;
            top: $cf-size-input / 2;
            right: 1.25rem;
            transform: translate(50%, -50%);
            pointer-events: none;
            cursor: default;
            top: 50%;
        }
    }

    .vs--single.vs--open .vs__selected {
        min-height: calc(100% - 0.5rem);
    }

    .vs__clear {
        position: absolute;
        margin: 0;
        right: 2.5rem;
        display: flex;
    }

    .vs__open-indicator {
        display: none;
    }

    .vs__selected {
        @include text-ellipsis;
        margin: $cf-gutter-xs $cf-gutter-sm $cf-gutter-xs 0;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translateY(-60%);
        min-width: 0;
        width: 90%;
        display: block;
    }

    .vs__dropdown-option {
        @include text-ellipsis;
    }

    &.cf-c-typeahead--large {
        .vs__dropdown-toggle {
            height: 2.5rem;
            border: 1px solid $cf-color-gray-02;
            border-radius: $cf-radius-sm;
            box-shadow: $cf-c-shadow-default;

            .vs__selected {
                position: relative;
                top: 0;
                transform: translate(0);
            }

            .vs__actions:after {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    &.cf-c-typeahead--single-risk {
        width: 100%;

        .vs__dropdown-toggle {
            padding: 0;
        }

        .vs__actions {
            display: none;
        }

        .vs__search {
            padding-left: 0.25rem;
        }

        .vs__selected {
            @include text-ellipsis;
            display: block;
            margin-right: 0;
            min-width: 0;
            width: 100%;
            margin: 0;
            padding: 0;
            padding-top: 0.3rem;
            padding-left: 0.25rem;
        }

        .vs__actions:after {
            right: 0.25rem;
            transform: translateY(-50%);
        }
    }
}

.cf-c-typeahead.cf-c-typeahead--border {
    .vs__dropdown-menu {
        padding: 0;
    }

    .vs__dropdown-option {
        border-bottom: 1px solid $cf-color-gray-01;
        height: $cf-size-collapsible-min-height;
        display: flex;
        align-items: center;
        font-size: $cf-font-size-stack;
        line-height: $cf-font-line-height-stack * $cf-font-size-stack;
    }

    .vs--open {
        .vs__dropdown-toggle {
            @include focus-default(true);
        }
    }
}

.vs__dropdown-menu {
    .vs__dropdown-option {
        white-space: normal;
    }
}
