/**
 * @name Conformio - Links list
 *
 */

.cf-c-links-list {
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius-sm;
}

.cf-c-links-list--separated {
    border: 0;
    box-shadow: none;

    .cf-c-links-list__item {
        box-shadow: $cf-c-shadow-default;
        border: 1px solid $cf-color-gray-01;
        border-radius: $cf-radius-sm;
        &:not(:last-child) {
            margin-bottom: $cf-gutter-sm;
        }
    }
}

.cf-c-links-list__item {
    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }
}

//

.ajsdiofj {
    @include flex-align-center;
    min-height: 2.5rem;
}
