/**
 * @name Conformio - Document wizard help and support
 *
 */

.cf-c-dw-help {
    padding: $cf-gutter $cf-gutter $cf-gutter $cf-gutter-sm;
    list-style: none;
    color: $cf-color-primary;
}

.cf-c-dw-help__item {
    margin: $cf-gutter 0 $cf-gutter-md;
}

.cf-c-dw-help__item-link {
    display: block;
}
