/**
 * @name Conformio - Tab content accordion
 *
 */

.cf-c-tab-ctn-accordion {
    @include absolute-full;
    display: flex;
    flex-direction: column;

    &.cf-c-tab-ctn-accordion--min-collapse-first {
        .cf-c-tab-ctn-accordion__item:first-child {
            min-height: 26%;
        }
    }

    &.cf-c-tab-ctn-accordion--shadowed-second {
        .cf-c-tab-ctn-accordion__item:nth-child(2) {
            box-shadow: $cf-c-shadow-accordion-item;
        }
    }
}

.cf-c-tab-ctn-accordion__item {
    display: flex;
    flex-direction: column;
    flex: 0.00001 1 auto;
    position: relative;
    transition: flex-grow $cf-animation-speed-fast ease-out;
    background-color: $cf-color-white;

    &.cf-is-active {
        flex-grow: 1;
    }
}

.cf-c-tab-ctn-accordion__trigger {
    cursor: pointer;
}

.cf-c-tab-ctn-accordion__item-body {
    flex: 1;
    position: relative;
}

.cf-c-tab-ctn-accordion__item-body-ctn {
    @include absolute-full;
    overflow: auto;
}
