/**
 * @name Conformio - List mode card
 *
 * Note: currently it's visible in document wiz / wizard tab / list mode (in pill tab)
 *
 */

.cf-c-lm-card {
    padding: $cf-gutter-sm;
    border: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius-lg;
}

.cf-c-lm-card__heading {
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: $cf-gutter-sm;
    font-size: $cf-font-size-sm;
}

.cf-c-lm-card__status {
    text-transform: uppercase;
}

.cf-c-lm-card__body {
    margin-bottom: 0;
}
