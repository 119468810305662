
@import '@style/sfc.scss';

.preonboarding-notification {
    &__wrapper {
        position: relative;
        padding: rem(8) rem(20);
        background-color: $color-primary-gamma;
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(12);
        line-height: 1;
        color: $color-primary-beta;
    }

    &__icon {
        margin-right: rem(8);
    }

    &__action {
        margin: 0 0 0 rem(2);
        padding: 0;
        border: 0;
        font-size: rem(12);
        font-weight: 700;
        line-height: 1;
        text-transform: uppercase;
        text-decoration: underline;
        background-color: transparent;
        color: $color-primary-beta;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }

    &__dismiss {
        position: absolute;
        top: rem(8);
        right: rem(20);
        margin: 0;
        padding: 0;
        border: 0;
        background-color: transparent;
        color: $color-primary-beta;
        transition: color 0.25s ease;

        &:hover {
            color: $color-primary-epsilon;
        }
    }
}
