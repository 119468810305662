/**
 * @name Conformio - Advanced collapsible
 *
 */

.cf-c-adv-collapsible {
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;
    margin-top: 1rem;

    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }

    &.cf-is-open {
        .cf-c-adv-collapsible__trigger {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }
}

.cf-c-adv-collapsible__header {
    @include flex-1;
    display: flex;
}

.cf-c-adv-collapsible__reg-state,
.cf-c-adv-collapsible__review-state {
    height: $cf-size-collapsible-min-height;
    width: 0.75rem;
}

.cf-c-adv-collapsible__reg-state {
    background-color: #f5a623;
}

.cf-c-adv-collapsible__review-state {
    background-color: $cf-color-gray-02;
}

.cf-c-adv-collapsible__trigger,
.cf-c-adv-collapsible__extra-state {
    @include flex-center;
    height: $cf-size-collapsible-min-height;
    width: 2rem;
}

.cf-c-adv-collapsible__trigger {
    border-bottom: 1px solid $cf-color-gray-01;
    cursor: pointer;
    user-select: none;

    &:after {
        @include css-shape-arrow-down($size: 0.5rem);
        content: '';
        display: block;
        transition: transform $cf-animation-speed-fast ease-in-out;
    }
}

.cf-c-adv-collapsible__col,
.cf-c-adv-collapsible__extra-states {
    border-left: 1px solid $cf-color-gray-01;
}

.cf-c-adv-collapsible__extra-states {
    margin-left: auto;
    color: $cf-color-primary;
}

.cf-c-adv-collapsible__col {
    padding: $cf-gutter-sm;
    font-size: $cf-font-size-sm;
}

.cf-c-adv-collapsible__extra-state {
    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }
}

.cf-c-adv-collapsible__ctn {
    border-top: 1px solid $cf-color-gray-01;
}

.cf-c-adv-collapsible__draft {
    @include flex-align-center;
    min-height: 2.5rem;
    padding: 0.75rem 1rem;
    background-color: $cf-color-gray;
    border-bottom: 1px solid $cf-color-gray-01;
    font-size: $cf-font-size-sm;

    &-title {
        color: $cf-color-gray-02;
        font-weight: bold;
        min-width: 5rem;
    }

    &-common {
        color: $cf-color-gray-02;
        width: 100%;
        margin-left: 1em;
    }

    &-error {
        color: $cf-color-danger;
        width: 100%;
        margin-left: 1em;
    }

    &-common {
        color: $cf-color-gray-02;
        width: 100%;
    }

    &-success {
        color: $cf-color-secondary;
        width: 100%;
    }

    .cf-c-btn__remove {
        margin-left: auto;
        margin-right: 0;
        position: relative;
        float: right;
        display: flex;
        justify-content: space-around;

        .cf-c-icon {
            width: 1rem;
        }
    }

    .cf-c-btn__saved {
        margin-left: auto;
        margin-right: $cf-radius-xl;
        position: relative;
        float: right;
        display: flex;
        justify-content: flex-end;
        font-weight: bolder;
    }
}
