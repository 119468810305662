
@import '@style/sfc.scss';

.experta {
    position: fixed;
    width: rem($cf-side-panel-width);
    top: 0;
    right: 0;
    bottom: 0;
    background-color: $cf-color-white;
    display: flex;
    flex-direction: column;

    &__heading {
        padding: $cf-gutter;
        background-color: $color-primary-beta;
        color: $cf-color-white;

        h4 {
            margin: 0;
            line-height: calc(26 / 20);
        }

        span {
            display: block;
            line-height: calc(22 / 16);
        }
    }

    &__info {
        padding: $cf-gutter;
        border-left: 1px solid $color-gray-alpha;
        font-size: rem(12);
        color: $color-primary-beta;

        .btn-link {
            margin-top: $cf-gutter;
        }
    }

    &__info-help {
        display: block;
    }

    &__chat {
        flex: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $color-gray-alpha;

        &-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            border-top: 1px solid $color-gray-alpha;
            border-bottom: 1px solid $color-gray-alpha;
            @include thin-scrollbar;
        }

        &-spinner {
            padding: 0 $cf-gutter;
            line-height: calc(24 / 12);
            font-size: rem(12);
            color: $color-primary-beta;
            font-weight: bold;
        }

        &-messages {
            flex: 1;
            padding: $cf-gutter;
        }

        &-form {
            display: flex;
            padding: $cf-gutter;

            textarea {
                margin: 0 $cf-gutter-sm 0 0;
                padding: 0;
                border: 0;
                font-size: rem(12);
                line-height: calc(18 / 12);
                color: $color-primary-beta;
                resize: none;
                box-shadow: none;

                &:focus {
                    outline: none;
                }
            }

            button {
                align-self: flex-start;
            }
        }
    }
}
