.c-switch {
    display: flex;
    &__button {
        margin: 0 auto;
        pointer-events: all !important;
        width: 100%;
        text-align: center;
    }
    &__subscription-text {
        color: $cf-color-white;
    }
}

.c-popular {
    top: -30px;
    border-radius: $cf-radius-sm;
    &__text {
        font-size: $cf-font-size-sm;
        color: $cf-color-white;
        font-weight: bold;
        margin: 0;
        background-color: $cf-color-gray-03;
        padding: 5px 25px;
    }
}

.c-tier {
    text-align: center;
    color: $cf-color-gray-03;
    &__name-text {
        font-weight: bold;
        font-size: $cf-font-size-xl;
        margin: $cf-gutter 0;
    }
    &__advanced-name-text {
        color: $cf-color-white;
    }
    &__advanced-employee-recomendation-text {
        color: $cf-color-white;
    }
}

.c-price {
    color: $cf-color-primary;
    font-weight: bold;
    display: flex;
    justify-content: center;
    margin-bottom: $cf-gutter;
}

.c-advanced-price {
    color: $cf-color-white;
}

.c-price__sup {
    font-size: $cf-font-size-lg;
}

.c-price__cost {
    font-size: $cf-font-size-md;
    margin-bottom: 0;
}

.c-price__sub {
    font-size: $cf-font-size-md;
    display: flex;
    align-items: flex-end;
}

.c-price__sub:before {
    content: '\00a0 ';
}

.c-price__annual {
    font-size: $cf-font-size-stack;
    line-height: $cf-font-line-height-stack;
    color: $cf-color-white;
}

.c-price__subscribe-button {
    color: $cf-color-white;
    font-size: $cf-font-size-md;
    font-weight: bold;
    background-color: $cf-color-primary;
    padding: 0.5em 2em;
    border-radius: $cf-radius-sm;
    transition: 0.2s;
}

.c-price__current-plan {
    color: $cf-color-gray-03;
    font-size: $cf-font-size-md;
    font-weight: bold;
    padding: 0.5em 2em;
}

.c-price__subscribe-advanced-button {
    color: $cf-color-primary;
    background-color: $cf-color-white;
}

.c-price__subscribe-button:hover {
    color: $cf-color-white;
    background-color: $cf-color-sky-blue;
    box-shadow: 0px 4px 7px 1px #0000004f;
    transition: 0.2s;
    border-radius: $cf-radius-sm;
    border: 0;
}

.c-price__subscribe-advanced-button:hover {
    color: $cf-color-primary;
    background-color: $cf-color-gray;
}
