
@import '@style/sfc.scss';

.app-nav {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    width: rem($width-app-navigation-collapsed);
    height: 100vh;
    z-index: $z-index-app-navigation;
    flex-direction: column;
    background-color: $color-primary-beta;
    transition: width 0.3s ease;

    &__top-wrap {
        padding: rem(24) rem(20);
        border-bottom: 1px solid $cf-color-primary-light;
    }

    &__logo-img {
        height: auto;
        min-width: rem(100);
        transition: min-width 0.3s ease;
    }

    &__home-action {
        display: flex;
        height: rem(46);
        width: rem(34);
        margin: 0 0 rem(40);
        padding: 0;
        border: 0;
        align-items: center;
        background-color: transparent;
        overflow: hidden;
        transform: translateX(#{rem(-7)});
        transition: width 0.3s ease;
    }

    &__whitelabel-wrap {
        height: rem(45);
        width: rem(45);
        transition: height 0.4s ease, width 0.4s ease;
    }

    &__whitelabel-img {
        height: 100%;
        object-fit: contain;
        transition: width 0.4s ease, opacity 0.4s ease;

        &--big {
            width: 0;
            opacity: 0;
        }

        &--small {
            width: 100%;
        }
    }

    &__standard {
        display: flex;
        margin: 0;
        gap: rem(12);
        align-items: center;
        font-size: rem(20);
        font-weight: 700;
        color: $color-white;
    }

    &__standard_text {
        width: 0;
        opacity: 0;
        white-space: nowrap;
        overflow: hidden;
        transition: opacity 0.2s ease 0.3s;
    }

    &__list {
        padding: rem(20) rem(8) rem(20) 0;
        list-style-type: none;

        &--is-primary {
            overflow-y: overlay;

            // Hide scrollbar
            -ms-overflow-style: none; /* Internet Explorer 10+ */
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none; /* Safari and Chrome */
            }
        }

        &--is-secondary {
            margin-top: auto;
            border-top: 1px solid $cf-color-primary-light;
        }
    }

    &__list-item {
        padding: rem(7) 0;

        &--is-secondary {
            padding: rem(4) 0;
        }
    }

    &__action {
        display: flex;
        width: 100%;
        padding: rem(4) 0 rem(4) rem(20);
        gap: rem(7);
        border: 0;
        border-radius: rem(40) 999em 999em rem(40); // pill shape radius
        font-size: rem(16);
        font-weight: 700;
        line-height: calc(22 / 16);
        text-align: left;
        align-items: center;
        background-color: transparent;
        color: $color-white;
        transition: background-color 0.25s ease, color 0.25s ease;

        .cf-c-icon:not(.cf-c-icon-arrow) {
            flex-shrink: 0;
        }

        .cf-c-icon-arrow {
            position: relative;
            top: rem(1);
            height: rem(8);
            width: 0;
            opacity: 0;
            overflow: hidden;
            transition: transform 0.25s ease, opacity 0.2s ease 0.3s;

            ::v-deep .cf-c-icon--custom {
                height: rem(8);
                width: rem(12);
            }
        }

        &--is-active {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }

        &--is-sub-nav-expanded {
            .cf-c-icon-arrow {
                transform: rotate(180deg);
            }
        }

        &:hover {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }
    }

    &__sub-list {
        max-height: 0;
        overflow: hidden;
        list-style-type: none;
        transition: all 0.4s ease;
    }

    &__sub-list-item {
        padding: rem(4) 0;
    }

    &__sub-action {
        display: inline-block;
        width: 100%;
        padding: rem(4) rem(20) rem(4) rem(50);
        border: 0;
        border-radius: rem(40) 999em 999em rem(40); // pill shape radius
        font-size: rem(14);
        line-height: calc(19 / 14);
        text-align: left;
        background-color: transparent;
        color: $color-white;
        transition: background-color 0.25s ease, color 0.25s ease;

        &--is-active {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }

        &:hover {
            background-color: $cf-color-primary-light;
            color: $cf-color-primary-dark;
        }
    }

    &__action-text,
    &__sub-action-text {
        max-height: rem(22);
        width: 0;
        opacity: 0;
        overflow: hidden;
    }

    &__action-tag {
        display: block;
        width: fit-content;
        margin-top: rem(5);
        padding: rem(2) rem(10);
        border-radius: rem(4);
        font-size: rem(12);
        font-weight: 700;
        line-height: calc(15 / 12);
        text-transform: uppercase;
        background-color: $color-primary-alpha;
        color: $color-white;
    }

    &__copyright {
        margin: 0;
        padding-left: rem(12);
        font-size: rem(10);
        line-height: calc(18 / 10);
        color: $color-white;
        white-space: nowrap;
        overflow: hidden;
        transition: padding-left 0.2s ease;

        span {
            display: inline-block;
            width: 0;
            opacity: 0;
            transition: opacity 0.2s ease 0.3s;
        }
    }

    &:hover {
        width: rem($width-app-navigation);

        .app-nav__home-action {
            width: rem(175);
            transform: translateX(0px);
        }

        .app-nav__logo-img {
            min-width: rem(175);
        }

        .app-nav__whitelabel-wrap {
            height: rem(50);
            width: rem(170);
        }

        .app-nav__whitelabel-img {
            &--big {
                width: 100%;
                opacity: 1;
            }

            &--small {
                width: 0;
                opacity: 0;
            }
        }

        .app-nav__copyright {
            padding-left: rem(20);
        }

        .app-nav__standard_text,
        .app-nav__copyright span {
            width: auto;
            opacity: 1;
        }

        .cf-c-icon-arrow {
            width: rem(12);
            opacity: 1;
        }

        .app-nav__action-text,
        .app-nav__sub-action-text {
            max-height: 100vh;
            width: auto;
            opacity: 1;
            transition: max-height 2s ease 0.4s, opacity 0.2s ease 0.3s;
        }

        .app-nav__sub-action-text {
            white-space: normal;
        }
    }
}
