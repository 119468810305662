/**
 * @name Conformio - Labeled Checkbox
 *
 */

.cf-c-labeled-checkbox {
    position: relative;
    display: inline-flex;
    min-height: #{$cf-font-line-height-stack}em;
    user-select: none;
    line-height: $cf-font-line-height-stack;

    &.cf-c-labeled-checkbox--inside-collapsible {
        .cf-c-labeled-checkbox__icon-wrapper {
            left: calc(#{$cf-size-collapsible-min-height} / 2);
            padding-left: 0;
            transform: translate(-0.5rem, -50%);
        }

        .cf-c-labeled-checkbox__text {
            padding-left: $cf-size-collapsible-min-height;
        }
    }

    &.cf-c-labeled-checkbox--pure-checkbox {
        min-height: 0;

        .cf-c-labeled-checkbox__icon-wrapper {
            position: relative;
            width: auto;
            height: auto;
            transform: none;
            top: auto;
            left: auto;
            padding: 0;
        }
    }

    &.cf-c-labeled-checkbox--truncated {
        max-width: 100%;

        .cf-c-labeled-checkbox__text {
            @include text-ellipsis;
        }
    }

    &.cf-c-labeled-checkbox--text-justified {
        .cf-c-labeled-checkbox__ctn {
            flex: 1;
        }

        .cf-c-labeled-checkbox__text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
        }
    }

    &.cf-c-labeled-checkbox--clickable-sm {
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: -$cf-gutter-sm;
            right: -$cf-gutter-sm;
            bottom: -$cf-gutter-sm;
            left: -$cf-gutter-sm;
        }
    }

    &.cf-c-labeled-checkbox--small,
    &.cf-c-labeled-checkbox--big {
        .cf-c-labeled-checkbox__text {
            padding-left: $cf-gutter-lg;
            padding-right: $cf-gutter;
        }

        .cf-c-labeled-checkbox__icon-wrapper {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.cf-c-labeled-checkbox--small {
        font-size: $cf-font-size-sm;
        padding: $cf-gutter-xs $cf-gutter-sm;
        line-height: 2;
    }

    &.cf-c-labeled-checkbox--big {
        font-size: $cf-font-size-stack;
        line-height: 1.5;
        padding: $cf-gutter-sm $cf-gutter-sm;
    }
}

.cf-c-labeled-checkbox__ctn {
    position: relative;
    min-width: 0;
}

.cf-c-labeled-checkbox__text {
    display: block;
    position: relative;
    padding: 0 1em 0 2em;
}

.cf-c-labeled-checkbox__input {
    position: absolute;
    opacity: 0;
}

.cf-c-labeled-checkbox__icon-wrapper {
    display: flex;
    position: absolute;
    left: 0;
    top: calc(#{$cf-font-line-height-stack}em / 2);
    transform: translateY(-50%);
    width: $cf-size-labeled-input-left-space;
    padding-left: 0.25em;
    color: $cf-color-gray-02;

    &.cf-is-checked {
        color: $cf-color-primary;
    }

    &.cf-is-disabled {
        color: $cf-color-gray-02;
    }
}

.cf-c-labeled-checkbox--border {
    border: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius;
}
