/**
 * @name Conformio - Main Section
 *
 */

.cf-c-dw-sections-wrapper {
    .cf-c-main-section {
        padding: 0 rem($gutter);
    }
}

.cf-c-main-section {
    margin-bottom: $cf-gutter-lg;

    &.cf-c-main-section--in-grid {
        display: flex;
        flex-direction: column;

        .cf-c-main-section__ctn {
            margin-top: auto;
        }
    }

    &.disable-margin-bottom {
        margin-bottom: 0 !important;
    }

    &.cf-c-main-section--missing-data {
        background-color: $cf-color-missing-data-background;
        border: 1px solid $cf-color-amber;
        border-radius: 4px;
        display: inline-block;
        padding-bottom: $cf-gutter-sm;
    }

    &.cf-c-main-section--no-padding {
        padding: 0;
    }

    &.cf-c-main-section--subsection {
        padding: 0;
        margin: $cf-gutter-lg 0 $cf-gutter-md;

        .cf-c-main-section__title {
            font-weight: bold;
            margin-bottom: $cf-gutter-md;
        }
    }

    // parent override - currently in doc wiz / input mode
    .cf-c-dw-sections-wrapper & {
        margin-bottom: $cf-gutter-md;

        .cf-c-main-section__title {
            font-size: $cf-font-size-stack;
        }
    }
}

.cf-c-dw-sections-wrapper {
    ol {
        counter-reset: list;
    }
    ol > li {
        list-style: none;
    }

    .cf-c-dw-alphabetical:before {
        content: counter(list, lower-alpha) ') ';
        counter-increment: list;
    }

    .cf-c-dw-numerical:before {
        content: counter(list) ') ';
        counter-increment: list;
    }
}

.cf-c-main-section__title {
    max-width: $cf-size-main-section-max-width;
}

.cf-c-main-section__desc {
    color: $cf-color-gray-03;

    li {
        margin-left: $cf-gutter-md;
    }
}

.cf-c-main-section__content-limited {
    max-width: $cf-size-main-section-max-width;
}

.cf-c-main-section__ctn-empty {
    opacity: 0.4;
    padding: $cf-gutter-xl 0 $cf-gutter-lg;
    font-weight: bold;
    color: $cf-color-gray-03;
}

.cf-c-main-section__top-wrapper {
    @include flex-align-center;
}

.cf-c-main-section__title-wrapper {
    flex: 1;
}
