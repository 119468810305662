// Sizing
@function rem($pxval) {
    @if not unitless($pxval) {
        $pxval: strip-units($pxval);
    }

    $base: $em-base;
    @if not unitless($base) {
        $base: strip-units($base);
    }

    @return ($pxval / $base) * 1rem;
}

@mixin thin-scrollbar {
    scrollbar-width: thin; /* set the width of the scrollbar */
    scrollbar-color: $color-gray-beta transparent; /* set the color of the scrollbar */

    &::-webkit-scrollbar {
        width: rem(8);
        height: rem(8);
    }

    &::-webkit-scrollbar-thumb {
        border: rem(2) solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        border-radius: rem(4);
        background-color: $color-gray-beta;
    }
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }
    &:-moz-placeholder {
        @content;
    }
    &::-moz-placeholder {
        @content;
    }
    &:-ms-input-placeholder {
        @content;
    }
}

@mixin input-clear {
    border: none;
    padding: 0;
    background-color: transparent;
    appearance: none;
    outline: none;
}

@mixin text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin absolute-full {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@mixin absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin full-size {
    width: 100%;
    height: 100%;
}

@mixin flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin flex-align-center {
    display: flex;
    align-items: center;
}

@mixin flex-justify-center {
    display: flex;
    justify-content: center;
}

@mixin flex-1 {
    flex: 1 1 0;
    min-width: 0;
}

@mixin button-clear {
    border: none;
    padding: 0;
    background-color: transparent;
}

@mixin outline-default {
    outline-color: rgb(77, 144, 254); // #4D90FE
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
}

@mixin focus-default($inset: false) {
    @if ($inset) {
        box-shadow: 0 0 0 0.2rem $cf-color-focus inset;
    } @else {
        box-shadow: 0 0 0 0.2rem $cf-color-focus;
    }
    outline: 0;
}

@mixin focus-light {
    outline-color: $cf-color-outline-gray-light;
}

@mixin cf-box($box-shadow: $cf-c-shadow-default, $radius: $cf-radius) {
    border: 1px solid $cf-color-border-main;
    border-radius: $radius;
    box-shadow: $box-shadow;
    background-color: $cf-color-white;
}

@mixin cf-input {
    border: 1px solid $cf-color-border-main;
    border-radius: $cf-radius-sm;
    background-color: $cf-color-white;
}

@mixin cf-input-has-error {
    border-color: $cf-color-danger !important;
}

@mixin cf-input-has-text {
    border-color: $cf-color-input-has-text-border;
}

@mixin css-shape-arrow-down($color: $cf-color-primary, $size: 5px) {
    border-style: solid;
    border-width: $size $size 0;
    border-color: $color transparent transparent;
}

@mixin error-msg {
    display: block;
    font-size: $cf-font-size-sm;
    color: $cf-color-danger;
    margin-top: 0.5em;

    .cf-u-mb-sm & {
        margin-top: 0;
    }
}

@mixin dw-box-heading {
    min-height: $cf-size-dw-box-heading-height;
    border-bottom: 1px solid $cf-color-gray-01;
    border-radius: 0 0 $cf-radius-sm $cf-radius-sm;
    box-shadow: $cf-c-shadow-default;
    position: relative;
}

@mixin table-style {
    border-bottom: 1px solid $cf-color-gray-01;

    th,
    td {
        padding: $cf-gutter-075;
        border: 1px solid $cf-color-gray-01;
    }

    th {
        font-weight: bold;
        background-color: $cf-color-gray;
    }
}

@mixin list-regular {
    margin-top: $cf-gutter;
    margin-bottom: $cf-gutter;
    padding-left: $cf-gutter-md;
}

@mixin no-content-text {
    padding: $cf-gutter;
    opacity: 0.4;
    text-align: center;
    font-weight: bold;
    color: $cf-color-gray-03;
}

@mixin box-outline(
    $default-border-color: $cf-color-border-main,
    $default-background-color: $cf-color-white
) {
    border: 1px solid $default-border-color;
    border-radius: $cf-radius;
    background-color: $default-background-color;
    box-shadow: $cf-c-shadow-default;
    cursor: default;
    transition: box-shadow $cf-animation-speed-fast;

    &:hover {
        box-shadow: $cf-c-shadow-lg;
    }
}

@mixin media($media) {
    @media only screen and (max-width: $media) {
        @content;
    }
}

@mixin radio-dot-active-selector($selector) {
    #{$selector} {
        .cf-c-radio-dot:after {
            opacity: 1;
        }
    }
}

@mixin data-card-highlight-title {
    font-size: $cf-font-size-sm;
    font-weight: normal;
    line-height: 2;
    color: $cf-color-primary;
    text-transform: uppercase;
}

@mixin data-card-text-default {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    line-height: 1.4;
    color: $cf-color-gray-03;
}

@mixin select-native-right-icon {
    @include flex-center;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: $cf-size-select-native-right-icon-width;
    pointer-events: none;

    &:after {
        content: '';
        @include css-shape-arrow-down;
    }
}

@mixin shape-circle($size: 1.25rem) {
    display: inline-flex;
    flex-shrink: 0;
    width: $size;
    height: $size;
    border-radius: 50%;
    background-color: currentColor;
}

@mixin shape-box($size: 1.25rem) {
    display: inline-flex;
    flex-shrink: 0;
    width: $size;
    height: $size;
    background-color: currentColor;
}

@mixin regular-border(
    $width: 0.125rem,
    $style: solid,
    $color: $cf-color-gray-03
) {
    border: $width $style $color;
}
