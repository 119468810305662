.text {
    margin: 0;
    font-family: $font-open-sans;
    font-size: rem(14);
    line-height: calc(19 / 14);
    font-weight: 400;

    // Color variations
    &--alpha {
        color: $color-primary-beta;
    }
}
