/**
 * @name Conformio - Multiselect
 */

.cf-c-multiselect {
    padding: 0 !important;
    min-width: $cf-size-input-min-width;

    &.cf-c-multiselect--below {
        .multiselect__content-wrapper {
            bottom: auto;
            top: 100%;
        }
    }

    &.cf-c-multiselect--single-activity {
        .multiselect__tags {
            border-radius: $cf-radius-sm;
            box-shadow: $cf-c-shadow-default;
            min-height: 2.5rem;
            padding-top: 0.4rem;

            .multiselect__tags-wrap {
                padding-bottom: 0;

                .multiselect__tag {
                    margin-bottom: 0.4rem;
                    font-size: 1rem;
                }
            }
        }

        .multiselect__content-wrapper {
            margin-bottom: 0;
        }

        .multiselect__select {
            height: 2.5rem;
        }
    }

    &.cf-c-multiselect--editable {
        .multiselect__option--selected,
        .multiselect__input {
            display: block;
            line-height: $cf-font-line-height-stack;
        }
    }

    &.cf-c-multiselect--no-border {
        .multiselect__tags {
            border-color: transparent;
        }
    }

    &.cf-c-multiselect--no-border-radius {
        .multiselect__tags {
            border-radius: 0;
        }

        .multiselect__select {
            width: 2.3rem;
        }
        .multiselect__content-wrapper {
            margin-bottom: 0;
        }
    }

    &.cf-c-multiselect--has-text {
        .multiselect__tags {
            border-color: $cf-color-input-has-text-border-light;

            .multiselect__tags-wrap {
                display: grid;
            }
        }
    }

    &.cf-c-multiselect--no-borders {
        .multiselect__tags {
            border-top: 0;
            border-bottom: 0;
        }
    }

    &.cf-c-multiselect--disabled-custom {
        background: $cf-color-gray;

        .multiselect__select {
            height: 2rem;
            &::before {
                top: 100%;
            }
        }
    }

    &.cf-c-multiselect--no-min-width {
        min-width: 0;
    }

    &.cf-c-multiselect--width-md {
        width: 17rem;
        max-width: 100%;
    }

    &.cf-c-multiselect--width-full {
        width: 100%;
        max-width: 100%;
        min-width: 100%;

        .multiselect {
            width: 100%;
            max-width: 100%;
            min-width: 100%;
        }
    }

    &.cf-c-multiselect--height-md {
        .multiselect {
            min-height: 2.5rem;
        }

        .multiselect__tags {
            min-height: 2.5rem;
            padding: calc(0.5rem - 1px) 3rem 0 0.75rem;
        }

        .multiselect__select {
            width: 2.5rem;
            height: 2.5rem;
        }

        .multiselect__tag {
            margin-bottom: $cf-gutter-xs;
        }

        .multiselect__input {
            margin-bottom: $cf-gutter-xs;
        }
    }

    .multiselect__tag {
        background: $cf-color-primary;
    }

    .multiselect {
        min-height: 3rem;

        &.multiselect--above {
            .multiselect__content-wrapper {
                margin-bottom: 0;
            }
        }
    }

    .multiselect__placeholder {
        @include text-ellipsis;
        margin: 0;
        padding: 0;
        font-size: $cf-font-size-stack;
        max-width: 100%;
    }

    .multiselect__input {
        height: $cf-gutter-md;
        padding-left: 0;
        margin-bottom: $cf-gutter-sm;
    }

    .multiselect__tags-wrap {
        display: flex;
        flex-wrap: wrap;
        margin-top: 0;
        padding-bottom: calc(
            #{$cf-gutter-xs} - 1px
        ); // -1px because border expands default height of 3rem
    }

    .multiselect__tags {
        border-radius: 0;
        min-height: $cf-size-input;
        padding: calc(#{$cf-gutter-075} - 1px) $cf-size-input 0 $cf-gutter-075; // -1px because border expands default height of 3rem
    }

    .multiselect__tag {
        padding: $cf-gutter-xs $cf-gutter-md $cf-gutter-xs $cf-gutter-sm;
        margin-bottom: $cf-gutter-sm;
        margin-right: $cf-gutter-sm;
        font-size: $cf-font-size-stack;
        font-weight: bold;
        border-radius: $cf-radius-pill;
    }

    .multiselect__tag-icon {
        &:after {
            color: $cf-color-white;
            height: 100%;
            font-weight: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $cf-font-size-md;
        }

        &:hover {
            background: $cf-color-primary;
        }
    }

    .multiselect__select {
        height: $cf-size-input;
        width: $cf-size-input;
        padding: $cf-gutter-xs $cf-gutter-sm;
    }

    .multiselect__content-wrapper {
        margin-bottom: $cf-gutter-xxl;
        z-index: $cf-z-multiselect-dropdown-content;
    }

    .multiselect__option {
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: $cf-gutter-075;
        min-height: $cf-size-input;
        line-height: 1rem;

        &:after {
            line-height: $cf-size-input;
            padding-right: $cf-gutter-075;
            padding-left: $cf-gutter-md;
            font-size: $cf-font-size-sm;
            background-color: $cf-color-gray;
        }
    }

    .multiselect__content {
        width: 100%;
    }

    .multiselect__option--selected {
        background-color: $cf-color-gray;
    }

    .multiselect__option--highlight,
    .multiselect__option--highlight:after {
        background-color: $cf-color-primary;
    }

    .multiselect--disabled {
        .multiselect__select {
            background-color: $cf-color-transparent;
        }
    }
}

.multiselect__element--hover {
    & :hover {
        background-color: $cf-color-primary;
        color: $cf-color-white;
    }
}

.multiselect {
    &.cf-c-multiselect--clear-dropdown {
        .multiselect__content-wrapper {
            margin-top: -1px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
            border: 1px solid rgba(60, 60, 60, 0.26);
            border-top-style: none;
            border-radius: 0 0 4px 4px;
        }

        .multiselect__content {
            padding: 5px 0;

            .multiselect__element {
                .multiselect__option {
                    min-height: 0;
                    padding: 3px 20px;
                    font-size: 0.75rem;
                    line-height: 1.43;
                    color: #333;
                    background-color: $cf-color-white;

                    &:hover {
                        &.multiselect__option--highlight {
                            background-color: #5897fb;
                            color: $cf-color-white;
                        }
                    }

                    &:after {
                        display: none;
                    }

                    &.multiselect__option--highlight {
                        &.multiselect__option--highlight {
                            background-color: #5897fb;
                            color: $cf-color-white;

                            &:after {
                                display: none;
                            }
                        }
                    }

                    &.multiselect__option--selected {
                        background-color: inherit;
                        color: inherit;
                        font-weight: 400;
                    }
                }
            }
        }
    }
}

.multiselect__tags--red-border {
    border: 1px solid $cf-color-danger;
}
