/**
 * @name Conformio - Add comment
 *
 * Note: currently it's visible in document wiz / wizard tab / input mode
 *
 */

.cf-c-add-comment {
    margin-top: $cf-gutter-md;
}

.cf-c-add-comment,
.cf-c-add-comment__text-area-wrapp {
    display: flex;
    flex-direction: column;
}

.cf-c-add-comment__text-area-wrapp {
    display: flex;
}

.cf-c-add-comment__actions {
    @include flex-align-center;
    margin-left: auto;
    margin-top: $cf-gutter-sm;
}
