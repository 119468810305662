/**
 * @name Conformio - Onboarding Heading
 *
 */

.cf-c-onboarding-heading {
    margin-bottom: $cf-gutter-lg;
}

.cf-c-onboarding-heading__title {
    font-weight: bold;
    margin-bottom: 0;
    font-size: $cf-font-size-xl;
}

.cf-c-onboarding-heading-top-content {
    display: flex;
    flex-direction: row;
    margin-bottom: $cf-gutter-lg;
    align-items: center;
}

.cf-c-onboarding-heading__back-icon {
    background-color: transparent;
    border-color: transparent;
}
