/**
 * @name Conformio - Components
 *
 * Note: we should have here only one element selector
 *
 */

.cf-c-onboarding-video-wrap {
    max-width: 43rem;
}

.cf-c-list-regular {
    @include list-regular;
}

.cf-c-no-content-text {
    @include no-content-text;
}

.cf-c-textarea {
    @include cf-input;
    @include flex-align-center;
    padding: calc(#{$cf-gutter-sm} - 1px) $cf-gutter-075; // -1px because border expands default height of 2rem
    min-height: $cf-size-input-sm;
    font-size: $cf-font-size-sm;
    line-height: 1rem;
    background-color: $cf-color-white;

    &.cf-has-text {
        @include cf-input-has-text;
    }

    &.cf-c-textarea--full-width {
        width: 100%;
    }

    &.cf-c-textarea--min-height-full {
        min-height: 100%;
    }

    &.cf-c-textarea--min-height-sm-double {
        min-height: $cf-size-input-sm * 2;
    }

    &.cf-c-textarea--min-height-15 {
        min-height: 15rem;
    }

    &.cf-c-textarea--resize-arrow-disabled {
        resize: none;
    }

    &.cf-c-textarea--has-error {
        @include cf-input-has-error;
    }

    &:focus {
        @include focus-default;
    }

    + .char-counter {
        text-align: right;
        line-height: 1rem;

        &.warning {
            color: $cf-color-danger;
        }
    }

    &.cf-c-textarea--background-custom {
        background-color: #f2f4f8;
    }
}

.cf-c-bulk-menu-icon-wrap {
    @include flex-center;
    width: $cf-size-icon-lg;
    height: $cf-size-icon-lg;
}

.cf-c-dw-registers-empty {
    max-width: 24rem;
    height: 100%;
    margin: 15% auto 0;
    padding: 0 $cf-gutter;
}

.cf-c-shortcode {
    background-color: $cf-color-doc-blade-shortcode-bg;
}

.cf-c-error-msg {
    @include error-msg;
}

.cf-c-circle {
    @include shape-circle;
}

.cf-c-box {
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-cube-default {
    @include shape-box(1rem);
}

// currently visible on Security Objectives / All objectives / Objective information / right column
.cf-c-objective-information-responsible-column {
    width: 26.125rem;
    max-width: 60%;
}

.cf-c-my-work-tab {
    color: $cf-color-gray-03;

    &:hover {
        color: $cf-color-gray-04;
    }

    &.is-active {
        color: $cf-color-primary;

        &:hover {
            color: $cf-color-primary-dark;
        }
    }
}

.cf-c-pointer {
    cursor: pointer;
}
