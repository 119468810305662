.cf-l-consultant-select-company {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: $cf-gutter;
}

.cf-l-consultant-select-company__heading-wrap {
    width: 100%;
}

.cf-l-consultant-select-company__tools-wrap {
    width: 100%;
    padding: $cf-gutter 0 $cf-gutter 0;
    text-align: left;

    .cf-l-consultant-select-company__tool-btn {
        margin-right: 1rem;
    }
}

.cf-c-consultant-select-company__container {
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    padding: 0;
}

.cf-c-consultant-select-company__company-box-header {
    display: block;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    padding: 0;
    background-color: #e1edf8;
    h3 {
        margin-top: 0.5em;
    }
}

.cf-c-consultant-select-company__company-box {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    padding: $cf-gutter 0 $cf-gutter 0;
}

.cf-c-consultant-select-company-stats {
    width: 40%;
    text-align: center;
}

.cf-c-consultant-select-company-subscription {
    width: 20%;
    height: 7.55rem;

    .cf-c-consultant-select-company-subscription__title {
        font-weight: bold;
        color: $cf-color-gray-03;
        text-transform: uppercase;
        text-align: center;
    }
}

.cf-c-consultant-select-company-plan {
    font-size: 1.5rem;
    font-weight: bolder;
    text-align: center;
}

.cf-c-consultant-select-company-actions {
    width: 40%;
    text-align: right;
    padding-right: $cf-gutter-25;
}
