/**
 * @name Conformio - Modal table
 *
 */

.cf-c-modal-table {
    width: 100%;
    table-layout: fixed;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-modal-table__td {
    border: 1px solid $cf-color-gray-01;
    height: 2.5rem;

    &:first-child {
        width: 100%;
    }

    &:last-child {
        width: 9rem;
    }
}

.cf-c-modal-table__td-title {
    display: block;
    padding: 0 $cf-gutter-sm;
}

.cf-c-modal-table__action {
    @include flex-align-center;
    justify-content: flex-end;
}

.cf-c-modal-table__action-icon {
    display: flex;
    align-self: stretch;
    position: relative;
    margin-right: $cf-gutter-sm;
    color: $cf-color-gray-02;

    &:hover {
        color: $cf-color-gray-03;
    }
}

.cf-c-modal-table__highlighted-text {
    display: inline-block;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}
