/**
 * Document Wizard - document content styling
 */

.dw-editor__document-wrapper {
    position: relative;

    &:after {
        @include absolute-full;
        content: '';
        display: block;
        border-radius: $cf-radius;
        background-color: $cf-color-doc-blade-overlay;
        opacity: 0;
        pointer-events: none;
        transition: opacity $cf-animation-speed-default;
    }

    &.cf-is-highlighted {
        &:after {
            opacity: 1;
        }
    }
}

.dw-editor__document-content {
    font-family: $cf-font-documents;
    font-size: $cf-font-size-document-text;

    h1,
    h2,
    h3,
    h4,
    h5,
    p {
        margin: 1em 0;
    }

    ol,
    ul {
        @include list-regular;
    }

    table {
        @include table-style;
        margin: 0 0 $cf-gutter 0;
    }

    h2 {
        font-size: $cf-font-size-document-heading-lg;
    }

    h3 {
        font-size: $cf-font-size-document-heading-lg;
    }

    h4 {
        font-size: $cf-font-size-document-heading-md;
    }

    h5 {
        font-size: $cf-font-size-document-text;
    }

    p {
        margin: 0 0 $cf-gutter-sm 0;
    }

    ol {
        &.with-brace {
            list-style-type: none;

            & > li {
                counter-increment: section;

                &:before {
                    content: counter(section, lower-alpha) ') ';
                }
            }
        }
    }

    .header-document,
    .footer-document {
        display: none;
    }

    .preview {
        margin-top: 150px;

        .logo {
            display: block;
            margin: 0 auto;
            margin-bottom: 60px;
        }

        .center {
            text-align: center;
        }

        .subtitle {
            margin-top: -20px;
            margin-bottom: 300px;
        }

        .table-user-data {
            margin-bottom: 150px;
        }

        .document-content {
            margin-top: 150px;
        }
    }
}

.dw-editor__document-highlight {
    position: relative;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: -$cf-gutter-xs;
        right: -$cf-gutter-sm;
        bottom: -$cf-gutter-xs;
        left: -$cf-gutter-sm;
        border-radius: $cf-radius;
        pointer-events: none;
        z-index: -1;
        opacity: 0;
        transition: opacity $cf-animation-speed-default;
    }

    &.cf-is-highlighted {
        &:after {
            opacity: 1;
            transition: opacity $cf-animation-speed-default;
        }
    }

    &.highlight--type-1 {
        z-index: $cf-z-index-highlighted-blade-section;

        &:after {
            background-color: $cf-color-doc-blade-type-1-bg;
        }
    }

    &.highlight--type-2 {
        z-index: $cf-z-index-highlighted-blade-section;

        &:after {
            background-color: $cf-color-doc-blade-type-2-bg;
            border: 2px solid $cf-color-doc-blade-type-2-border;
        }
    }

    &.highlight--type-3 {
        z-index: $cf-z-index-highlighted-blade-section;

        &:after {
            background-color: $cf-color-doc-blade-type-3-bg;
            border: 1px dashed $cf-color-doc-blade-type-3-border;
        }
    }

    &.highlight--type-4 {
        z-index: $cf-z-index-highlighted-blade-section;

        &:after {
            background-color: $cf-color-doc-blade-type-4-bg;
        }
    }

    &.highlight--type-5 {
        z-index: $cf-z-index-highlighted-blade-section;
        cursor: default;

        &:after {
            background-color: $cf-color-doc-blade-type-5-bg;
        }

        &.inline-button {
            &::after {
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
            }
        }
    }
}
