/**
 * @name Conformio - Multiple checklist
 *
 */

.cf-c-multiple-checklist {
    flex: 1 0 0;
}

.cf-c-multiple-checklist__item {
    padding: calc(0.3125rem + 2px) $cf-gutter-xs calc(0.3125rem + 1px) 0;
    border-bottom: 1px solid $cf-color-gray-01;

    &:last-child {
        padding: $cf-gutter-xs $cf-gutter-xs $cf-gutter-xs 0;
        border-bottom: none;
    }
}

.cf-c-multiple-checklist__add-new-wrapper {
    display: flex;
    color: $cf-color-input-placeholder;
}

.cf-c-multiple-checklist__add-new-icon-wrapper {
    @include flex-center;
    width: $cf-size-collapsible-min-height;
    color: $cf-color-primary;
}
