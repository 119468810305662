/**
 * @name Conformio - Basic Dropdown
 *
 */

// overrides

.cf-c-basic-dropdown {
    position: relative;

    &.cf-c-basic-dropdown--alignment-right {
        .multiselect__content-wrapper {
            left: auto;
            right: 0;
        }
    }

    .multiselect {
        min-height: 0;
        color: inherit;
    }

    .multiselect__select {
        display: none;
    }

    .multiselect__tags {
        padding: 0;
        min-height: 0;
        display: block;
        border-radius: 0;
        border: none;
        background: transparent;
        font-size: inherit;
    }

    .multiselect__single {
        padding: 0;
        margin: 0;
    }

    .multiselect__content-wrapper {
        width: auto;
        box-shadow: $cf-c-shadow-app-dropdown;
        background-color: $cf-color-white;
        border-radius: $cf-radius;
        border: none;
        padding: $cf-gutter-sm 0;
        max-height: 1000px !important;
    }

    .multiselect__option {
        min-height: auto;
        line-height: inherit;
        padding: $cf-gutter-sm $cf-gutter-md $cf-gutter-sm $cf-gutter-sm;
    }
}

// custom styles

.cf-c-basic-dropdown__toggler-wrapper {
    white-space: nowrap;
}
