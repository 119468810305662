/**
 * @name Conformio - Registers and Modules custom card
 */

.cf-c-reg-card-wrap {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
}

.cf-c-reg-card {
    @include cf-box();
    display: flex;
    width: calc(100% / 3 - 1rem);
    padding: 1rem 0.5rem;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.cf-c-reg-card__title {
    color: $cf-color-gray-04;
    font-size: $cf-font-size-md;
    text-transform: uppercase;
}

.cf-c-reg-card__icon-wrapper {
    color: $cf-color-primary;
    height: 4.3rem;
    margin-top: $cf-gutter-sm;
}

.cf-c-reg-card__content-info {
    padding: $cf-gutter $cf-gutter-lg $cf-gutter-sm $cf-gutter-lg;
    line-height: 14px;
}

.cf-c-reg-card__action {
    margin-top: auto;
}
