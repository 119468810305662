/**
 * @name Conformio - Table
 *
 */

.cf-c-table {
    @include table-style;
}

.cf-c-parsed-table {
    table-layout: fixed;

    td {
        padding: $cf-gutter-xs !important;
    }

    .cf-c-parsed-table__delete-cell {
        position: absolute;
        top: 1rem;
        right: -3rem;
    }
}

.cf-c-parsed-table-dense.cf-c-parsed-table:focus-within {
    table-layout: auto;

    td:focus-within {
        width: 150px !important;
    }

    input {
        padding: $cf-gutter-xs;
    }
}
