/**
 * @name Conformio - Audit Search
 *
 */

.cf-c-audit-search {
    position: relative;
    box-shadow: $cf-c-shadow-default;

    &.cf-c-audit-search--sm {
        .cf-c-audit-search__field-wrapper {
            height: $cf-size-input-sm;
        }

        .cf-c-audit-search__field {
            font-size: $cf-font-size-sm;
        }
    }

    &.cf-has-text {
        .cf-c-audit-search__field {
            @include cf-input-has-text;
        }
    }
}

.cf-c-audit-search__field-wrapper {
    height: $cf-size-input;
}

.cf-c-audit-search__field {
    @include input-clear;
    @include absolute-full;
    @include cf-input;
    padding: 0 $cf-gutter-sm;
    background-color: $cf-color-primary-lighter;
    border-radius: 2px 2px 0 0;
}

.cf-c-audit-search-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 18rem;
    overflow-x: hidden;
    overflow-y: auto;
    margin-bottom: $cf-gutter-lg;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    list-style: none;
    z-index: $cf-z-index-search-options;
    border: 1px solid $cf-color-border-main;
    border-top: none;
}
