/**
 * @name Conformio - Color box list
 *
 */

.cf-c-color-box-list {
}

.cf-c-color-box-list__item {
    @include flex-align-center;
    margin-bottom: $cf-gutter-xs;
}

.cf-c-color-box-list__box-wrapper {
    margin: 0 $cf-gutter-sm 0 0;
}

.cf-c-color-box-list__label {
    font-size: $cf-font-size-sm;
}
