/**
 * @name Conformio - Checkbox grid list
 *
 */

.cf-c-checkbox-grid-list {
    margin-bottom: -$cf-gutter-075;

    @supports (display: grid) {
        display: grid;
        grid-template-columns: auto 1fr;
    }
}

.cf-c-checkbox-grid-list__item {
    margin-bottom: $cf-gutter-075;
}
