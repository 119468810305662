/**
 * @name Conformio - Document wizard heading info
 *
 */

.cf-c-doc-wiz-heading-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: $cf-gutter-md;
}

.cf-c-doc-wiz-heading-info__section {
    display: flex;
    align-items: center;
    text-transform: uppercase;

    &:not(:last-child) {
        margin-right: $cf-gutter-lg;
    }
}

.cf-c-doc-wiz-heading-info__section-title {
    color: $cf-color-primary;
}

.cf-c-doc-wiz-heading-info__section-val {
    margin-left: $cf-gutter-sm;
    color: $cf-color-warning;
    font-weight: bold;
}
