/**
 * @name Conformio - Document Wizard Map
 *
 */

.cf-c-map {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: $cf-gutter-lg;
    position: relative;
}

.cf-c-map__track {
    position: absolute;
    top: 0;
    left: 50%;
    bottom: 0;
    width: $cf-gutter-sm;
    transform: translateX(-50%);
    border-radius: $cf-radius-pill;
    background-color: $cf-color-gray-01;
}

.cf-c-map__dot {
    position: absolute;
    width: $cf-size-map-dot-size;
    height: $cf-size-map-dot-size;
    background-color: $cf-color-primary;
    border: 1px solid $cf-color-primary;
    border-radius: $cf-radius-pill;
    transition: background-color $cf-animation-speed-fast;
    cursor: pointer;
}
