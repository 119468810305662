/**
 * @name Conformio - Collapsible Right Toggle
 *
 */

.cf-c-collapsible-right {
    @include flex-1;
    font-size: $cf-font-size-sm;

    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }

    &.cf-c-collapsible-right--toggle-full-height {
        .cf-c-collapsible-right__icon-wrap {
            height: auto;
        }
    }

    //parent override
    .cf-c-app-modal-wrapper.cf-c-app-modal-wrapper--approval
        .cf-c-app-modal__body
        > :first-child
        > & {
        flex-grow: 0;
    }
}

.cf-c-collapsible-right__header {
    display: flex;
    min-height: $cf-size-collapsible-min-height;
    color: $cf-color-input-placeholder;
    @include box-outline;
    border-radius: 0;
}

.cf-c-collapsible-right__header-ctn {
    @include flex-align-center;
    flex: 1 1 0;
    align-self: flex-start;
    min-height: $cf-size-collapsible-min-height;
    word-break: break-word;
}

.cf-c-collapsible-right__icon-wrap {
    @include flex-center;
    flex-shrink: 0;
    width: $cf-size-collapsible-min-height;
    height: $cf-size-collapsible-min-height;
    cursor: pointer;
    user-select: none;
    margin-left: -1rem;
}

.cf-c-collapsible-right__icon {
    @include css-shape-arrow-down($size: 0.5rem);
    transition: transform $cf-animation-speed-fast ease-in-out;
}

.cf-is-open {
    .cf-c-collapsible-right__icon {
        transform: rotate(180deg);
    }
}

.cf-c-collapsible-right__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    word-break: break-word;
    padding-right: $cf-gutter;

    > :not(:first-child) {
        padding-top: $cf-gutter-sm;
    }
}
