.c-certification {
    @include cf-box();
    padding: $cf-gutter-lg;
}

.c-certification__title {
    color: $cf-color-primary;
    font-size: $cf-font-size-md;
    padding-bottom: 0.625rem;
    border-bottom: 1px solid $cf-color-primary;
    margin-bottom: $cf-gutter-sm;
}

.c-certification__text {
    font-size: $cf-font-size-stack;
    margin-bottom: $cf-gutter-md;
    line-height: $cf-font-line-height-stack;
}

.c-certification__box {
    border: 1px solid $cf-color-gray-01;
    padding: $cf-gutter-md $cf-gutter;
    margin-bottom: 2.5rem;

    .cf-c-data-card-table {
        display: flex;
        flex-direction: column;

        > tr {
            display: flex;
            justify-content: space-between;
        }

        > :not(:last-child) {
            margin-bottom: $cf-gutter-lg;
        }
    }
}

.c-certification__label {
    color: $cf-color-gray-03;
    letter-spacing: 0;
    line-height: $cf-font-line-height-stack;
    font-weight: 400;
}

.c-certification__status {
    > a {
        display: flex;
        align-items: center;
    }
}

.c-certification__status-positive,
.c-certification__status-negative {
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 100%;
}

.c-certification__status-text-positive,
.c-certification__status-text-negative {
    font-size: $cf-font-size-stack;
    font-weight: bold;
    letter-spacing: 0;
    line-height: $cf-font-line-height-stack;
}

.c-certification__status-text-positive {
    color: $cf-color-secondary;
}

.c-certification__status-text-negative {
    color: $cf-color-danger;
}

.c-certification__status-positive {
    background-color: $cf-color-secondary;
}

.c-certification__status-negative {
    background-color: $cf-color-danger;
}

.c-certification__subtitle {
    line-height: $cf-font-line-height-stack;
    color: $cf-color-primary;
    letter-spacing: 0;
    font-weight: bold;
    margin-bottom: $cf-gutter;
}

.c-certification__list {
    list-style: none;
    max-width: 45.5rem;
    width: 100%;

    > :first-child {
        margin-bottom: $cf-gutter-md;
    }

    > li {
        color: $cf-color-gray-03;
        font-size: $cf-font-size-stack;
        letter-spacing: 0;
        line-height: $cf-font-line-height-stack;

        > span {
            color: $cf-color-primary;
            font-weight: bold;
            margin-right: $cf-gutter-xs;
        }
    }
}

.c-certification__document-list {
    padding: $cf-gutter 0.5rem 1.5rem;

    > div {
        margin-bottom: 1.5rem;
    }

    > :last-child {
        margin-bottom: 0;
    }
}

.c-certification__document-list-heading {
    display: flex;

    div {
        margin-bottom: 0;
    }

    span {
        color: $cf-color-gray-02;
        font-size: $cf-font-size-sm;
        font-weight: bold;
        letter-spacing: 0;
        line-height: $cf-font-line-height-sm;
        margin-bottom: 0;
    }
}

.c-certification__document-list-item {
    display: flex;
}

.c-certification__dropdown-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $cf-gutter-sm $cf-gutter;
    font-weight: bold;

    > p {
        margin-bottom: 0;
    }
}
