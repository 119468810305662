.c-paid-plan__header {
    padding: 1.25rem 1.5rem;
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;

    .c-paid-plan__title {
        margin-bottom: 0;
        font-size: 1.5rem;
        line-height: 1.6;
    }

    .c-paid-plan__header-title-container {
        display: inline-block;
        width: 50%;
    }

    .c-paid-plan__header-contact-container {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        text-align: right;
    }
}

.c-paid-plan__current {
    display: flex;
    padding: 2rem 1.5rem;
    border: 1px solid $cf-color-gray-01;

    .c-paid-plan__current-label {
        font-size: 1.25rem;
        line-height: 1.6;
        color: $cf-color-gray-03;
        margin-bottom: 0;
    }

    .c-paid-plan__current-type {
        margin-left: 2.5rem;
        color: $cf-color-black;
        font-size: inherit;
        line-height: inherit;
    }
}

.c-paid-plan__section {
    padding: $cf-gutter-lg $cf-gutter-md $cf-gutter-md;
    border: 1px solid $cf-color-gray-01;
}

.c-paid-plan__section-title {
    font-size: $cf-font-size-md;
    line-height: 1.6;
    color: $cf-color-gray-03;
    margin-bottom: 1.25rem;
}

.c-paid-plan__subsection {
    &-item {
        display: flex;
        align-items: flex-end;
        margin-bottom: $cf-gutter-md;

        > strong,
        li {
            font-size: 1rem;
            color: $cf-color-gray-02;
            line-height: 1.375;
            min-width: 15rem;
            font-weight: bold;
        }

        > span {
            color: $cf-color-black;
            font-size: $cf-font-size-md;
            line-height: 1.35;
            margin-right: $cf-gutter-md;
        }
    }
    ul {
        padding-left: $cf-gutter-md;
    }
}

.c-paid-plan__section-contact-support {
    display: flex;
    justify-content: flex-end;
}

.c-paid-plan__addons_link,
.c-paid-plan__request-addons {
    position: relative;
}

.c-paid-plan__change-subscription-modal {
    div.plan-block {
        padding: 1rem;

        p.plan-block-header-current {
            color: $cf-color-gray-03;
            font-size: $cf-font-size-md;
            margin-bottom: 0;
        }
        p.plan-block-header-next {
            color: $cf-color-primary;
            font-size: $cf-font-size-md;
            margin-bottom: 0;
        }
        div.plan-data-block {
            display: flex;

            p.plan-block-name {
                font-size: $cf-font-size-md;
                width: 50%;
            }
            p.plan-block-price {
                font-size: $cf-font-size-md;
                width: 50%;
                text-align: right;
            }
        }
    }
    div.plan-block.even {
        background-color: rgba(242, 244, 248, 0.47);
    }
}

.c-paid-plan__cta {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: $cf-gutter-md $cf-gutter-lg;

    .cf-c-btn--quiet-danger-light {
        padding: 0;
    }
}

.c-paid-plan__cancel-modal,
.c-paid-plan__support-modal,
.c-paid-plan__payment-modal {
    > p {
        line-height: 1.5;
        color: $cf-color-black;
        margin-bottom: 0;
    }
}

.c-paid-plan__cancel-modal {
    margin: $cf-gutter-075 $cf-gutter-sm 1.25rem;
}

.c-paid-plan__support-modal {
    margin: $cf-gutter-075 $cf-gutter-sm $cf-gutter-md;
}

.c-paid-plan__payment-modal {
    margin: $cf-gutter-075 $cf-gutter-sm $cf-gutter-md;

    > p {
        margin-bottom: $cf-gutter-md;
    }

    > textarea {
        width: 100%;
        height: 5.5rem;
        resize: none;
        border: 1px solid $cf-color-gray-02;
        border-radius: $cf-radius-sm;
        background-color: $cf-color-white;
    }
}

.c-paid-plan__info {
    padding: 0;
    background-color: transparent;
    height: auto;
    width: auto;
    color: $cf-color-primary;
    font-size: $cf-font-size-stack;
    line-height: 1.375;
    border: none;
    margin-left: 1.25em;

    &:hover,
    &:focus,
    &:active {
        background-color: transparent;
    }
}
