/**
 * @name Conformio - Labeled Checkbox list
 *
 */

.cf-c-labeled-checkboxes__item {
    display: flex;
    flex-direction: column;
    margin-bottom: $cf-gutter-sm;
}
