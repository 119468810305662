.cf-c-corrective-item {
    display: flex;
    background: $cf-color-gray;
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;
    margin: $cf-gutter-sm 0;
    justify-content: space-between;
    padding: 0 $cf-gutter-sm;
    min-height: 3.5rem;
    align-items: center;
}

.cf-c-corrective-item__title {
    font-size: $cf-font-size-stack;
}

.cf-c-corrective-item__desc {
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
    font-weight: normal;
}

.cf-c-corrective-item__state-wrapper {
    height: 100%;
    width: 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: $cf-gutter-md;
}

.cf-c-corrective-item__state-circle {
    @include shape-circle;
    margin-bottom: $cf-gutter-xs;
}
