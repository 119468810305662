/**
 * @name Conformio - Video Responsive
 *
 */

.cf-c-video-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;

    iframe,
    embed,
    video {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    &--16by9 {
        padding-top: 56.25%;
    }
}
