@font-face {
    font-family: 'OpenSans';
    src: url('@src/assets/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('@src/assets/fonts/OpenSans/OpenSans-Medium.woff2') format('woff2');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('@src/assets/fonts/OpenSans/OpenSans-Regular.woff2')
        format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'OpenSans';
    src: url('@src/assets/fonts/OpenSans/OpenSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
