/**
 * @name Conformio - Horizontal Rule With Text (native)
 *
 */

.cf-c-hr-text {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &:before {
        content: '';
        width: 100%;
        position: absolute;
        top: 50%;
        height: 2px;
        background-color: $cf-color-primary;
    }

    .cf-c-hr-text__content {
        position: relative;
        padding: $cf-gutter-075;
        color: $cf-color-primary;
        font-weight: bold;
        background-color: $cf-color-white;
    }
}
