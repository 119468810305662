.c-plan {
    padding: $cf-gutter-lg $cf-gutter-md $cf-gutter-md $cf-gutter-md;
    border-bottom: 1px solid $cf-color-gray-01;
}

.c-plan__free {
    color: $cf-color-primary;
    font-weight: bold;
    font-size: $cf-font-size-lg;
    margin: 0;
    width: 100%;
}

.c-plan__current {
    color: $cf-color-gray-03;
    font-size: $cf-font-size-md;
    margin: 0.5em 0;
    width: 100%;
}

.c-plan__days {
    font-size: $cf-size-progress-bar;
    color: $cf-color-primary-dark;
    margin-bottom: 0;
}
