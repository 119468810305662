/**
 * @name Conformio - Checkbox list
 *
 */

.cf-c-checkbox-list {
    display: flex;
    flex-direction: column;

    &.cf-c-checkbox-list--small {
        > div {
            max-height: 12.4rem;
        }

        .cf-c-checkbox-list__item {
            font-size: $cf-font-size-sm;
            padding: $cf-gutter-xs $cf-gutter-sm;
            line-height: 2;
        }

        .cf-c-checkbox-list__add-new {
            height: 2.25rem;
            padding: 0;
        }

        .cf-c-multiple-checklist__add-new-wrapper {
            > button {
                height: 2.25rem;
                width: 100%;
                display: flex;
                justify-content: flex-start;
                padding: 0;
                font-size: $cf-font-size-sm;
            }
        }
    }
}

.cf-c-checkbox-list__header {
    padding: $cf-gutter-xs $cf-gutter-sm;
    color: $cf-color-gray-03;
    background-color: $cf-color-gray;
    border-bottom: 1px solid $cf-color-gray-01;
}

.cf-c-checkbox-list__item {
    min-width: 0;
    flex-shrink: 0;
    padding: calc(#{$cf-gutter-sm} - 1px) 0 calc(#{$cf-gutter-sm} - 1px)
        $cf-gutter-sm;

    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }

    &:hover {
        cursor: pointer;
    }
}

.cf-c-checkbox-list__empty-list {
    @include flex-center;
    flex: 1 1 0;
    min-height: 2.375rem;
    padding: 0 $cf-gutter;
    text-align: center;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
    opacity: 0.21;
}

.cf-c-checkbox-list__selected {
    background-color: $cf-color-gray;
}

.cf-c-checkbox-list__add-new {
    position: absolute;
    top: calc(100% - 2px);
    left: 0;
    right: 0;
    width: 100%;
    border: 1px solid $cf-color-gray-01 !important;
    background-color: $cf-color-white;
}
