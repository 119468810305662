/**
 * @name Conformio - Select menu (native)
 *
 */

.cf-c-select-native {
    position: relative;
    min-width: $cf-size-input-min-width;

    &.cf-c-select-native--has-error {
        .cf-c-select-native__field {
            @include cf-input-has-error;
        }
    }

    &.cf-c-select-native--has-placeholder {
        .cf-c-select-native__field {
            color: $cf-color-input-placeholder;

            option {
                color: $cf-color-black;
            }
        }
    }

    &.cf-c-select-native--has-text {
        .cf-c-select-native__field {
            border-color: $cf-color-input-has-text-border;
        }
    }

    &.cf-c-select-native--md {
        .cf-c-select-native__ctn {
            height: $cf-size-input-md;
        }
    }

    &.cf-c-select-native--primary {
        .cf-c-select-native__field {
            color: $cf-color-primary;
        }
    }

    &.cf-c-select-native--text-bold {
        .cf-c-select-native__field {
            font-weight: bold;
        }
    }

    &.cf-c-select-native--has-absolute-errors {
        .cf-c-select-native__error {
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
        }
    }

    &.cf-c-select-native--sm {
        .cf-c-select-native__ctn {
            height: $cf-size-input-sm;
        }
    }

    &.cf-c-select-native--auto-width {
        min-width: auto;

        .cf-c-select-native__field {
            width: auto;
            max-width: 100%;
            position: static;
        }
    }

    &.cf-c-select-native--full-width {
        min-width: 100%;
    }

    &.cf-c-select-native--max-width-default {
        .cf-c-select-native__field {
            max-width: $cf-size-input-min-width;
        }
    }

    &.cf-c-select-native--no-borders {
        .cf-c-select-native__field {
            border-color: transparent;
        }
    }

    &.cf-c-select-native--fsz-sm {
        font-size: $cf-font-size-sm;
    }
}

.cf-c-select-native__ctn {
    height: $cf-size-input;
    position: relative;
}

.cf-c-select-native__field {
    @include absolute-full;
    @include cf-input;
    padding: 0 $cf-size-select-native-right-icon-width 0 $cf-gutter-075;
    appearance: none;

    &:focus {
        @include focus-default;
    }
}

.cf-c-select-native__right-icon {
    @include select-native-right-icon;
}

.cf-c-select-native__field[disabled] {
    background-color: $cf-color-input-disabled-bg;
}

.cf-c-select-native__error {
    @include error-msg;
}
