/**
 * @name Conformio - Task Collapsible
 *
 */

.cf-c-task-collapsible {
    border: 1px solid $cf-color-gray-01;

    margin-bottom: $cf-gutter;

    &.cf-is-open {
        > .cf-c-task-collapsible__header {
            .cf-c-task-collapsible__icon {
                transform: rotate(-180deg);
            }
        }
    }
}

.cf-c-task-collapsible__header {
    display: flex;
    min-height: $cf-size-task-collapsible-height;
}

.cf-c-task-collapsible__completed {
    width: $cf-size-task-collapsible-height;
    text-align: center;
}

.cf-c-task-collapsible__completed-txt {
    font-size: $cf-font-size-xs;
    color: $cf-color-gray-03;
    line-height: 1.7;
    display: inline-block;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-task-collapsible__col {
    display: flex;
    flex-direction: column;

    &:last-child {
        @include flex-center;
        width: $cf-size-task-collapsible-height;
        margin-left: auto;
    }

    &:not(:last-child) {
        border-right: 1px solid $cf-color-gray-01;
    }
}

.cf-c-task-collapsible__text-small {
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}

.cf-c-task-collapsible__icon-wrap {
    @include button-clear;
    @include flex-center;
    flex-shrink: 0;
    width: $cf-size-task-collapsible-height;
    height: $cf-size-task-collapsible-height;
    transition: box-shadow $cf-animation-speed-fast;
    cursor: pointer;
    user-select: none;

    &:focus {
        @include focus-default;
    }

    &:active {
        outline: none;
        box-shadow: none;
    }
}

.cf-c-task-collapsible__icon {
    @include css-shape-arrow-down($size: 0.5rem);
    transition: transform $cf-animation-speed-fast ease-in-out;
}

.cf-c-task-collapsible__ctn {
    border-top: 1px solid $cf-color-gray-01;
}

.cf-c-task-collapsible__ctn-indented {
    margin: 0 calc(#{$cf-size-task-collapsible-height} + 1px); // 1px because of border
    padding: 0 $cf-gutter;
}
