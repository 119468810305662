.cf-c-single-activity {
    @include cf-box(0, 0);
    padding: $cf-gutter $cf-gutter-md;
    display: flex;
    flex-direction: column;
    box-shadow: $cf-c-shadow-default;

    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}

.cf-c-single-activity__content {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: $cf-color-gray-03;
    border-bottom: 1px solid $cf-color-gray-01;
    padding-bottom: $cf-gutter;
}

.cf-c-single-activity__title {
    flex: 1 1 0;
    font-size: $cf-font-size-stack;
    line-height: $cf-font-line-height-stack;
    font-weight: bold;
}

.cf-c-single-activity__date {
    @include flex-center;
    width: $cf-width-lg;
    min-width: $cf-width-lg;
    max-width: $cf-width-lg;
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
}

.cf-c-single-activity__reviewers {
    @include flex-center;
    width: $cf-width-lg;
    min-width: $cf-width-lg;
    max-width: $cf-width-lg;
    flex-wrap: wrap;

    > :not(:first-child) {
        margin-top: 0.5rem;
    }
    .cf-c-pill {
        margin-bottom: 0;
        margin-right: 0;
    }
}

.cf-c-single-activity__status {
    @include flex-center;
    width: $cf-width-md;
    min-width: $cf-width-md;
    max-width: $cf-width-md;
}

.cf-c-single-activity__frequency {
    @include flex-center;
    width: $cf-width-xl;
    min-width: $cf-width-xl;
    max-width: $cf-width-xl;
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    text-align: center;
}

.cf-c-single-activity__cta {
    display: flex;
    align-items: center;
    padding-top: 0.875rem;
}

.cf-c-single-activity__cta {
    display: flex;
    align-items: center;
}

.cf-c-single-activity__source {
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    color: $cf-color-black;
    font-weight: bold;
    margin-right: $cf-gutter-md;
}

.cf-c-single-activity__link {
    display: flex;
    align-items: center;
    margin-right: auto;
}

.cf-c-single-activity__link-icon {
    width: 1.5rem;
    height: auto;
}
.cf-c-single-activity__link-label {
    text-decoration: underline;
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    color: $cf-color-primary;
    font-weight: bold;
    margin-left: 0.5rem;
    cursor: pointer;
}

.cf-c-single-activity__column-title {
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    color: $cf-color-black;
    font-weight: bold;
}

.cf-c-single-activity__settings-wrap {
    padding: 0 $cf-gutter;
    margin-bottom: $cf-gutter;

    &:first-child {
        margin-top: $cf-gutter;
    }

    &.cf-c-single-activity__settings-wrap--box {
        @include cf-box(0, 0);
        margin: 0 $cf-gutter;
        padding: 0;
        box-shadow: $cf-c-shadow-default;

        &:first-child {
            margin: $cf-gutter;
        }

        &:not(:first-child) {
            margin-bottom: $cf-gutter;
        }
    }
}

.cf-c-single-activity__settings {
    @include cf-box(0, 0);
    padding: $cf-gutter;
    display: flex;
    flex-wrap: wrap;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

    > div {
        flex: 0 0 50%;
        display: flex;
        flex-direction: column;
    }

    > :first-child {
        padding-right: $cf-gutter;
    }

    > :last-child {
        padding-left: $cf-gutter;

        > :first-child {
            margin-bottom: 0.75rem;
        }
    }

    &.cf-c-single-activity__settings--no-border {
        border: none;
    }
}

.cf-c-single-activity__form {
    display: flex;
    align-items: center;

    &.cf-c-single-activity__form--justify-top {
        .cf-c-reg-section-form-group__title-wrap {
            height: 100%;
        }
    }

    &:not(:last-child) {
        margin-bottom: $cf-gutter-075;
    }

    .cf-c-reg-section-form-group__input-wrap {
        width: 100%;

        .cf-c-pill {
            margin: 0;

            &:not(:first-child) {
                margin-top: 0.5rem;
            }

            .cf-c-pill__text {
                color: $cf-color-white;
                font-size: 1rem;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 1.5;
            }
        }

        > div {
            width: 100%;
        }
    }

    .cf-c-multiselect {
        min-width: 100%;
    }

    .cf-c-reg-section-form-group__title-wrap {
        width: $cf-width-xxl;
        min-width: $cf-width-xxl;
        max-width: $cf-width-xxl;
        padding: 0;
        padding-right: $cf-gutter;
        line-height: $cf-font-line-height-sm;
        color: $cf-color-black;
        text-align: left;
        margin: 0;
    }

    .cf-c-reg-section-form-group__input-wrap {
        padding: 0;
        margin: 0;
        display: flex;

        > span {
            font-size: $cf-font-size-sm;
            line-height: $cf-font-line-height-sm;
            color: $cf-color-black;
            text-align: justify;
        }

        div {
            > span {
                font-size: $cf-font-size-sm;
                line-height: $cf-font-line-height-sm;
                color: $cf-color-black;
                text-align: justify;
            }
        }

        .multiselect__tag {
            > span {
                color: $cf-color-white;
            }
        }

        .cf-c-single-activity__settings-link {
            color: $cf-color-primary;
            font-weight: bold;
            text-align: left;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}

.cf-c-single-activity__footer {
    margin: 0 $cf-gutter;
    padding: $cf-gutter 0;
    border-top: 1px solid $cf-color-gray-01;
    display: flex;
    align-items: center;

    > :first-child {
        color: $cf-color-primary;
        font-size: 0.75rem;
        font-weight: bold;
        letter-spacing: 0;
        line-height: $cf-font-line-height-sm;
        text-align: justify;
        margin-left: auto;
        padding-right: $cf-gutter-lg;
    }
}
