/**
 * @name Conformio - Advanced collapsible subsection
 *
 */

.cf-c-adv-collapsible-subsection {
    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }

    &.cf-c-adv-collapsible-subsection--inline {
        border-bottom: 1px solid $cf-color-gray-01;
        flex: 1 1 0;
        min-width: 0;

        &:not(:last-child) {
            border-right: 1px solid $cf-color-gray-01;
        }

        &.cf-c-adv-collapsible-subsection--danger {
            border: 1px solid $cf-color-danger;
        }
    }
}

.cf-c-adv-collapsible-subsection__title {
    @include flex-align-center;
    min-height: 3.5rem;
    padding: $cf-gutter-xs $cf-gutter;
}

.cf-c-adv-collapsible-subsection__title-txt {
    font-weight: bold;
    color: $cf-color-gray-04;
    text-transform: uppercase;
    width: 100%;
}

.cf-c-adv-collapsible-subsection__ctn {
    padding: 0 $cf-gutter $cf-gutter;
    font-size: $cf-font-size-sm;
    line-height: 1.33;
}
