/**
 * @name Conformio - Modal explorer
 *
 */

.cf-c-modal-explorer {
    border-radius: $cf-radius;
    box-shadow: $cf-c-shadow-default;
    overflow: hidden;
}

.cf-c-modal-explorer__item {
    @include flex-align-center;
    height: 2.5rem;
    border-width: 1px 1px 0 1px;
    border-style: solid;
    border-color: $cf-color-gray;
    cursor: pointer;

    &:not(:last-child) {
        margin-bottom: 1px;
    }
}

.cf-c-modal-explorer__item-icon {
    flex-shrink: 0;
    margin: 0 $cf-gutter-sm;
}

.cf-c-modal-explorer__item-text {
    @include text-ellipsis;
    flex: 1;
    min-width: 0;
}
