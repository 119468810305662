.cf-c-department-card {
    position: relative;
    margin-right: 2.5rem;
    margin-top: $cf-gutter-md;
    background-color: $cf-color-white;
    display: flex;
    width: calc(100% / 3 - 40px);
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    min-width: 17.5rem;
}

.cf-c-department-card__top-wrapper {
    margin-bottom: $cf-gutter-075;
}

.cf-c-department-card__heading {
    font-weight: normal;
    font-size: $cf-font-size-stack;
    color: $cf-color-gray-03;
    margin-bottom: 0;
}

.cf-c-department-card__input-wrapper {
    min-width: unset;
    position: relative;
}

.cf-c-department-card__input {
    &[disabled] {
        background-color: transparent;
        border: 1px solid $cf-color-gray-02;
    }
}

.cf-c-department-card__delete-btn {
    @include flex-center;
    background-color: transparent;
    border-color: transparent;
    width: $cf-gutter-md;
    height: $cf-gutter-md;
    padding: 0;

    &:hover {
        background-color: $cf-color-gray-01;
        border-color: transparent;
    }
}

.cf-c-department-card__delete-btn-img {
    width: 100%;
}

.cf-c-department-card-add {
    height: 21.3rem;
    cursor: pointer;
}

.cf-c-department-card-add__plus,
.cf-c-department-card-add__text {
    color: $cf-color-gray-02;
}

.cf-c-department-card-add__plus {
    font-size: 3.5rem;
}

.cf-c-department__members {
    max-width: 33rem;
}

.cf-c-department__members-form {
    justify-content: space-between;

    @media (max-width: 980px) {
        flex-direction: column;
        margin-bottom: $cf-gutter;
    }
}

.cf-c-department__members-input-group {
    color: $cf-color-primary;
    font-weight: bold;
}

.cf-c-department__members-input--active {
    border: 1px solid $cf-color-gray-02;
}
