.cf-c-icon-wrapper-default {
    height: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: $cf-gutter-sm;
    padding-right: $cf-gutter-sm;
    border: 1px solid $cf-color-gray-02;
    border-radius: $cf-radius-sm;
    background: $cf-color-white;
    cursor: pointer;
}
