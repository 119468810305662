.cf-l-register {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0 $cf-gutter 0 $cf-gutter;
}

.cf-l-register__heading-wrap {
    max-width: $cf-size-main-container;
    width: 100%;
    margin: 0 auto;
    padding: $cf-gutter-35 $cf-gutter 0 $cf-gutter;
    text-align: center;
}

.cf-c-register__form {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    min-width: 46rem;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    padding: $cf-gutter-25 $cf-gutter-xxl $cf-gutter-xl $cf-gutter-xxl;

    .cf-c-input__required {
        font-size: $cf-font-size-stack;
        font-weight: bold;
    }
}

.cf-c-register__input-group-column {
    display: flex;
    flex-direction: column;
}

.cf-c-register__input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin-bottom: $cf-gutter-md;

    .cf-c-main-section__ctn {
        width: 100%;
    }
}

.cf-c-register__terms {
    color: $cf-color-gray-03;
}

.cf-c-register__submit {
    width: 100%;
    height: $cf-size-input;
    margin-top: auto;
}

.cf-c-consultant__form {
    @extend .cf-c-login__form;
    max-width: $cf-size-terms-form-max-width;
}

.cf-c-consultant__header {
    text-align: left !important;
}

.cf-c-consultant-register__terms {
    @extend .cf-c-register__terms;
    text-align: left;
}

.cf-c-consultant__register-form {
    width: 70%;
}
