/**
 * @name Conformio - DMS file and directory explorer
 *
 */

.cf-c-fad-explorer-table__item {
    display: flex;
    border: 1px solid $cf-color-gray-01;
    background-color: $cf-color-white;
    min-height: 4rem;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-fad-explorer-table {
    padding: $cf-gutter;
    background-color: $cf-color-white;
    min-height: 60vh;
}

.cf-c-fad-explorer {
    width: 100%;
    padding: rem($gutter * 2) rem($gutter) rem($gutter);
}

.cf-c-fad-explorer-table-left-wrapper {
    padding: $cf-gutter-sm;
    display: flex;
    flex: 1;
    cursor: pointer;
}

.cf-c-fad-explorer-table-item__folder {
    align-self: center;
    width: 3rem;
    height: 3rem;
    border: none;
    padding: 0;
    background-color: $cf-color-white;

    &:hover {
        background-color: transparent;
        border: none;
    }
}

.cf-c-fad-explorer-table-item__name {
    word-break: break-all;
}

.cf-c-fad-explorer-table-item__folder-img {
    width: 100%;
    height: 100%;
}

.cf-c-fad-explorer-table-item__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.cf-c-fad-explorer-table-item__info {
    display: flex;
    flex-direction: row;

    > li:first-of-type {
        list-style: none;
    }
}

.cf-c-fad-explorer-table-item__info-item {
    font-size: $cf-gutter-075;
    color: $cf-color-gray-03;
}

.cf-c-fad-explorer-table-item__info-appr {
    color: $cf-color-secondary;
    text-transform: uppercase;
    font-weight: bold;
}

.cf-c-fad-explorer-table-item__info-appr--not {
    // put this class in case of approved
    color: $cf-color-danger;
}

.cf-c-fad-explorer-table-item__info-appr--draft {
    // put this class in case of draft
    color: $cf-color-warning;
}

.cf-c-fad-explorer-table-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $cf-gutter;
}

.cf-c-fad-explorer-table-right-action-wrapper {
    @include flex-center;
    width: 2rem;
    height: 2rem;
}

.cf-c-fad-explorer-table-item__dots-container {
    align-items: flex-start;
}

.cf-c-fad-explorer__nav {
    padding: $cf-gutter;
    background-color: $cf-color-white;
    box-shadow: $cf-c-shadow-default;
    position: relative;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
}

.cf-c-fad-explorer__nav-text {
    font-size: $cf-font-size-stack;
    color: $cf-color-gray-03;
    cursor: pointer;
    min-width: 7%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-right: 5px;
}

.cf-c-fad-explorer__nav-text-slash {
    margin: 0 $cf-gutter-xs;
}

.cf-c-fad-explorer__nav-text-wrapper {
    @include flex-align-center;
    flex: 1;
    word-break: break-all;
    min-width: 0;
}

.cf-c-fad-explorer__nav-actions-wrap {
    flex-shrink: 0;
}

.cf-c-fad-explorer-table-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 1px solid $cf-color-gray-01;
    border-right: 1px solid $cf-color-gray-01;
    padding: $cf-gutter-sm;
}

.cf-c-fad-explorer-table-middle__text-version {
    color: $cf-color-gray-03;
    font-size: $cf-font-size-sm;
    font-weight: bold;
}

.cf-c-fad-explorer-table-middle__text-view {
    border: 0;
    font-size: $cf-font-size-sm;
    text-transform: uppercase;
    background-color: transparent;
    color: $cf-color-primary;
    font-weight: bold;
    cursor: pointer;

    &:disabled {
        color: $color-gray-beta;
        cursor: default;
    }
}

.cf-c-fad-explorer-table-middle-content {
    display: flex;
    flex-direction: row;
}
