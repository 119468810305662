/**
 * @name Conformio - Tab content accordion trigger
 *
 */

.cf-c-tab-ctn-accordion-trigger {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $cf-gutter;
    border-width: 1px 0;
    border-style: solid;
    border-color: $cf-color-gray-01;
    margin-top: -1px;
}

.cf-c-tab-ctn-accordion-trigger__text {
    font-weight: bold;
}

.cf-c-tab-ctn-accordion-trigger__state {
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-primary;
}
