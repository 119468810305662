$height-app-header: 80;
$width-app-navigation: 260;
$width-app-navigation-collapsed: 60;

$min-width-app-content: 1100;

$gutter: 15;

$cf-width-md: 5.5rem;
$cf-width-lg: 7rem;
$cf-width-xl: 8.5rem;
$cf-width-xxl: 9.5rem;

$cf-size-main-container: 1440px;
$cf-size-navbar: 3.75rem; // 64px
$cf-size-logo: 2.75rem; //56px
$cf-size-avatar: 2.5rem; // 40px
$cf-size-avatar-sm: 2.375rem; // 38px
$cf-size-sidebar-width: 23.75rem; // 380px
$cf-size-sidebar-item: 2.5rem; // 40px
$cf-size-step-card-min-height: 21.5rem; // 344px
$cf-size-step-card-sec-row-min-height: 4.25rem; // 68px;
$cf-size-btn-default: 35;
$cf-size-btn-icon: 2.2em; // 35.2px
$cf-size-btn-md: 2.5em; // 40px
$cf-size-btn-lg: 3em; // 48px
$cf-size-btn-sm: 2rem; // 24px
$cf-size-progress-bar: 1rem; // 16px
$cf-size-progress-bar-micro: 0.25rem; // 4px
$cf-size-input-lg: 3.5rem;
$cf-size-input: 3rem; // 48px
$cf-size-input-sm: 2rem;
$cf-size-input-md: 2.5rem;
$cf-size-input-xs: 1.5rem;
$cf-size-input-min-width: 17rem;
$cf-size-select-native-right-icon-width: 2.5rem;
$cf-size-radio-card-left-space: 2.5rem;
$cf-size-radio-label-left-space: 2rem;
$cf-size-radio-dot: 1rem;
$cf-size-labeled-input-left-space: 2em;
$cf-size-tabs-height: 4rem;
$cf-size-pill-tabs-height: 2.5rem;
$cf-size-dw-box-heading-height: 4rem;
$cf-size-add-logo: 7.5rem;
$cf-size-main-section-max-width: 38rem;
$cf-size-login-form-max-width: 25rem;
$cf-size-login-card-max-width: 29rem;
$cf-size-multiselect-dropdown-max-width: 15.6rem; //250px

$cf-size-terms-form-max-width: 40rem;

$cf-size-icon-xs: 1rem;
$cf-size-icon-sm: 1.25rem;
$cf-size-icon: 1.5rem;
$cf-size-icon-md: 1.75rem;
$cf-size-icon-lg: 2rem;
$cf-size-icon-xl: 2.75rem;
$cf-size-icon-xxxl: 6rem;

$cf-size-app-cube: 1.5rem;

$cf-form-switch-height: 1.5rem;
$cf-form-switch-height-sm: 1.25rem;
$cf-form-switch-height-lg: 2rem;
$cf-form-switch-width: 3rem;
$cf-form-switch-width-sm: 2.5rem;
$cf-form-switch-width-lg: 4rem;
$cf-form-switch-border-width: 0.25rem;
$cf-form-switch-border-width-sm: 0.175rem;
$cf-form-switch-border-width-lg: 0.25rem;

$cf-switch-height: 1.5rem;
$cf-switch-height-sm: 1.25rem;
$cf-switch-height-xxxl: 3.75rem;
$cf-switch-width: 3rem;
$cf-switch-width-sm: 2.5rem;
$cf-switch-width-xxxl: 20rem;
$cf-switch-border-width: 0.25rem;
$cf-switch-border-width-sm: 0.175rem;

$cf-size-map-dot-size: 0.75rem;

$cf-size-collapsible-min-height: 2.5rem;

$cf-app-tag-height: 1.333em;

$cf-size-task-collapsible-height: 4.5rem;

$cf-preonboarding-width-default: 22.5rem;
$cf-preonboarding-width-max: 35rem;

$cf-gutter: 1rem;
$cf-gutter-xs: $cf-gutter * 0.25;
$cf-gutter-sm: $cf-gutter * 0.5;
$cf-gutter-075: $cf-gutter * 0.75;
$cf-gutter-125: $cf-gutter * 1.25;
$cf-gutter-md: $cf-gutter * 1.5;
$cf-gutter-lg: $cf-gutter * 2;
$cf-gutter-xl: $cf-gutter * 3;
$cf-gutter-xxl: $cf-gutter * 4;
$cf-gutter-25: $cf-gutter * 2.5;
$cf-gutter-35: $cf-gutter * 3.5;

$cf-side-panel-width: 258;
