/**
 * Document Wizard (DW)
 * Rewritten styling for Document Wizard elements
 */

/**
 * DW Heading
 */
.dw-heading {
    margin: $cf-gutter-075 0 $cf-gutter-125 0;

    .page-title {
        margin: 0;
        font-size: rem(20);
        line-height: 2rem;
        min-height: 2rem;
        color: $color-primary-beta;
        font-weight: normal;
    }

    // .dw-heading__info
    &__info {
        // margin: $cf-gutter-xs 0 0 0;
        display: flex;
        font-size: rem(14);
        color: $color-primary-beta;
    }

    // .dw-heading__info-details
    &__info-details {
        display: flex;
        font-weight: bold;
        line-height: 2rem;

        span + span {
            margin-left: $cf-gutter-125;
        }
    }
}

/**
 * DW Sidebar 
 */
.dw-sidebar {
    @include cf-box();
    height: 100%;
    overflow: auto;
}

/**
 * Document editor
 */
.dw-editor {
    @include absolute-full;
    @include cf-box;
    z-index: 1;
    overflow: overlay;
    height: 100%;
    display: flex;
    flex-direction: column;

    // .dw-editor__heading
    &__heading {
        @include dw-box-heading;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: $cf-gutter-075 $cf-gutter-sm;
        row-gap: $cf-gutter-075;
        align-items: center;

        &-left {
            display: flex;
            flex: 1;
        }

        &-right {
            display: flex;
            flex: 1;
            justify-content: flex-end;
        }

        //  .dw-editor__heading-btn
        &-btn {
            margin: 0 0 0 $cf-gutter;
        }

        .btn {
            white-space: nowrap;
        }

        // Custom paragraph switch
        .cf-c-form-switch {
            margin: 0 $cf-gutter-md 0 0;
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            white-space: nowrap;
            font-size: 0.875rem;
            text-transform: uppercase;
            color: $color-primary-beta;
            font-weight: 700;

            .cf-c-form-switch__toggler {
                margin-right: $cf-gutter-sm;
            }
        }
    }

    // .dw-editor__document
    &__document {
        display: block;
        flex: 1;
        overflow: auto;

        // .cd-dw-editor__document-wrapper
        &-wrapper {
            display: flex;
            flex: 1;
            overflow: hidden;
        }

        // .dw-editor__document-content
        &-content {
            position: relative;
            padding: $cf-gutter-lg $cf-gutter-xxl;
        }
    }
}

.vue-pdf-embed {
    canvas {
        width: 100% !important;
        height: 100% !important;
    }
}
