$z-index-app-header: 100;
$z-index-app-navigation: 100;
$z-index-app-side-panel: 105;

$z-index-app-notifications: 90;

$z-index-details-pane: 100;
$z-index-tooltip-small: 10;

$z-index-loader-overlay: 80;

$cf-z-index-highlighted-blade-section: 1;
$cf-z-index-checkout-iframe: 3;
$cf-z-multiselect-dropdown-content: 1;
$cf-z-index-tabs-items: 1;
$cf-z-index-searchable-list: 1;
$cf-z-index-search-options: 1;
$cf-z-index-search-risks-options: 10;
