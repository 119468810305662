/**
 * @name Conformio - Internal audit card
 *
 */

.cf-c-int-audit-card {
    @include cf-box($radius: 0);
    @include flex-align-center;
    min-height: 4rem;
}

.cf-c-int-audit-card__title {
    overflow-wrap: break-word;
    flex: 1 1 0;
    min-width: 0;
    padding: 0 $cf-gutter-sm;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}

.cf-c-int-audit-card__action {
    @include flex-justify-center;
    flex: 0 0 auto;
    width: 7rem;
}
