
@import '@style/sfc.scss';

.cf-c-navbar__ctn-inner-plan {
    padding: rem(8);
    text-align: center;
    background-color: $cf-color-primary-plan;
    color: $cf-color-white;

    p {
        margin: 0;

        span {
            font-weight: bold;
            text-decoration: underline;
            color: $cf-color-white;
            cursor: pointer;
        }
    }
}
