
@import '@style/sfc.scss';

.experta__chat-message {
    // Default styling
    display: inline-block;
    clear: both;
    max-width: 85%;
    margin: 0 0 $cf-gutter;
    padding: rem(8);
    border-radius: 8px;
    font-size: rem(12);

    // Word break settings
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    &:last-child {
        margin: 0;
    }

    &::v-deep {
        // p tag in Experta anwser
        p {
            margin: 0;

            & + p {
                margin: $cf-gutter-sm 0 0 0;
            }
        }
    }

    &--bot {
        float: left;
        background-color: $color-primary-zeta;
        color: $color-primary-beta;
    }

    &--user {
        float: right;
        background-color: $color-primary-epsilon;
        color: $cf-color-white;
    }

    &--question {
        text-decoration: underline;
        cursor: pointer;
    }
}
