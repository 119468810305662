/**
 * @name Conformio - Document wizard header
 *
 */

.cf-c-doc-wiz-header {
    display: flex;
    align-items: center;
    padding-bottom: $cf-gutter-lg;
}

.cf-c-doc-wiz-header__back-icon {
    display: inline-flex;
    margin: 0 $cf-gutter-sm;
}

.cf-c-doc-wiz-header-left__text,
.cf-c-doc-wiz-header-center__text {
    color: $cf-color-gray-03;
    margin-bottom: 0;
}

.cf-c-doc-wiz-header-left__text {
    font-size: $cf-font-size-stack;
    color: $cf-color-primary;
}

.cf-c-doc-wiz-header-center__text {
    font-size: $cf-font-size-xl;
}

.cf-c-doc-wiz-header__text {
    margin-bottom: 0;
}
