/**
 * @name Conformio - My work discussion card
 *
 */

.cf-c-my-work-discussion-card {
    @include cf-box($radius: none);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.cf-c-my-work-discussion-card__checkbox-wrap {
    @include flex-align-center;
    min-height: $cf-size-avatar-sm;
    margin-top: $cf-gutter;
    padding: 0 $cf-gutter;
}

.cf-c-my-work-discussion-card__body {
    flex: 1 1 0;
    padding-right: 3.5rem;
    padding-left: $cf-gutter;
}

.cf-c-my-work-discussion-card__action {
    display: flex;
    justify-content: flex-end;
    flex: 1 1 100%;
    padding: 1px $cf-gutter-sm $cf-gutter-075;
    align-items: center;
}
