/**
 * @name Conformio - Radio group
 *
 */

.cf-c-radio-group__item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0.5em;

    // parent override - currently in doc wiz / properties tab
    .cf-c-dw-sections-wrapper & {
        align-items: stretch;
    }
}

.cf-c-radio-group__addition {
    max-width: 100%;
    margin-top: 0.5em;
}
