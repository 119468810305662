/**
 * @name Conformio - Add file
 *
 */

.cf-c-add-file {
    position: relative;
}

.cf-c-add-file__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

    &:focus ~ .cf-c-add-file__placeholder {
        @include focus-default;
    }
}

.cf-c-add-file__ctn {
    display: block;
    width: $cf-size-add-logo;
    height: $cf-size-add-logo;
    position: relative;
    box-shadow: $cf-c-shadow-default;
    cursor: pointer;

    &:hover {
        .cf-c-add-file__overlay {
            opacity: 0.8;
        }
    }
}

.cf-c-add-file__placeholder,
.cf-c-add-file__img,
.cf-c-add-file__overlay {
    border-radius: $cf-radius;
}

.cf-c-add-file__placeholder {
    @include flex-center;
    @include full-size;
    border: 1px solid $cf-color-primary;
    background-color: $cf-color-primary-light;
    border-radius: $cf-radius;
    color: $cf-color-primary;
    transition: background-color $cf-animation-speed-default;

    &:hover {
        background-color: $cf-color-white;
    }

    &:after {
        content: '+';
        font-size: $cf-font-size-xl;
    }
}

.cf-c-add-file__img {
    @include absolute-full;
    overflow: hidden;
    background-size: cover;
}

.cf-c-add-file__overlay {
    @include absolute-full;
    display: none;
    justify-content: center;
    align-items: center;
    background-color: $cf-color-white;
    color: $cf-color-black;
    opacity: 0;
    pointer-events: none;
    transition: opacity $cf-animation-speed-default;

    .cf-c-add-file__img + & {
        display: flex;
    }
}

.cf-c-add-file__footer {
    @include flex-align-center;
    margin-top: $cf-gutter-xs;
}

.cf-c-add-file__name {
    margin-right: $cf-gutter-075;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
    word-break: break-word;
}

.cf-c-add-file__delete-icon {
    display: flex;
    color: $cf-color-gray-02;

    &:hover {
        color: $cf-color-gray-03;
    }
}
