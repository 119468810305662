/**
 * @name Conformio - Single Training
 *
 */

.cf-c-single-training {
    padding: $cf-gutter-sm $cf-gutter-lg $cf-gutter $cf-gutter-lg;
    border-top: 1px solid $cf-color-gray-01;
    border-right: 1px solid $cf-color-gray-01;
    border-bottom: 1px solid $cf-color-gray-01;
    position: relative;

    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }

    &::before {
        content: '\00a0'; /* Escaped unicode for a non-blank space */
        border-left: $cf-gutter-sm solid;
        height: calc(100% + 1px);
        position: absolute;
        top: -1px;
        left: 0;
    }

    &--primary {
        &::before {
            border-left-color: $cf-color-primary;
        }
    }

    &--secondary-light {
        &::before {
            border-left-color: $cf-color-secondary-light;
        }
    }

    &--gray-canceled {
        &::before {
            border-left-color: $cf-color-gray-canceled;
        }
    }

    &--unassigned {
        &::before {
            border-left-color: $cf-color-unassigned;
        }
    }

    &--amber {
        &::before {
            border-left-color: $cf-color-amber;
        }
    }
}
