.cf-c-input-enable.cf-c-input-enable--type-title {
    .cf-c-input-enable__field {
        outline: none;
        appearance: none;
        border: none;
        font-size: $cf-font-size-md;
        line-height: $cf-font-line-height-stack;
        color: $cf-color-black;
        background-color: $cf-color-white;
    }
}
