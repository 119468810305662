/**
 * @name Conformio - My Work Tasks Card
 *
 */

.cf-c-my-work-card {
    @include cf-box(0, 0);

    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }
}

.cf-c-my-work-card__title {
    color: $cf-color-gray-03;
    font-weight: bold;
}

.cf-c-my-work-card__action-wrapper {
    display: flex;
    justify-content: flex-end;
    color: $cf-color-primary;
    border-bottom: 1px solid $cf-color-gray-01;
    padding: 0 $cf-gutter-sm $cf-gutter-sm $cf-gutter-sm;
}

.cf-c-my-work-card__thead {
    @include flex-align-center;
    justify-content: space-between;
    color: $cf-color-gray-02;
    font-weight: bold;
    font-size: $cf-font-size-sm;
    padding-bottom: $cf-gutter-xs;
    border-bottom: 1px solid $cf-color-gray-01;
}
