.notification {
    &-wrapper {
        width: 100%;
        margin: 0 auto;
        padding: $cf-gutter-sm $cf-gutter-md;
        display: flex;
        align-items: center;
        background-color: #ddf1d8;
        border-left: 8px solid #aedca2;
    }

    &-icon {
        width: 34px;
        height: 34px;
        margin: 0 $cf-gutter 0 0;
    }

    &-content {
        flex: 1;
        font-size: $cf-font-size-14;
        color: rgba(31, 60, 87, 0.8);

        .highlighted {
            display: block;
            font-size: $cf-font-size-stack;
            font-weight: 700;
            color: rgba(31, 60, 87, 1);
        }

        a {
            color: #4788c6;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-dismiss {
        width: 24px;
        height: 24px;
        cursor: pointer;

        .cf-c-icon {
            width: inherit;
            height: inherit;
        }
    }

    &-icon,
    &-dismiss {
        .cf-c-icon {
            width: inherit;
            height: inherit;
        }
    }
}
