/**
 * @name Conformio - Layouts
 */

// App layout
.cf-l-app__ctn {
    width: 100%;
    min-width: #{$min-width-app-content}px;
    flex: 1;
    overflow-y: auto;
    @include thin-scrollbar;

    .app--with-side-panel & {
        min-width: #{$min-width-app-content - $cf-side-panel-width}px;
    }
}

// Main section layout
.cf-l-main__heading {
    padding-bottom: $cf-gutter-lg;
}

.cf-l-main__heading-color {
    color: $cf-color-gray-03;
}

.cf-l-main__content {
    width: 100%;
    min-height: 100%;
    padding: rem($gutter * 2) rem($gutter) rem($gutter);
}

// Grid
.cf-l-grid {
    display: flex;
    flex-wrap: wrap;

    &.cf-l-grid--cols-flex {
        [class^='cf-l-grid__col'] {
            display: flex;
            min-width: 0;
        }
    }

    &.cf-l-grid--has-min-width-280 {
        [class^='cf-l-grid__col'] {
            min-width: 17.5rem; // 280px
        }
    }
}

[class^='cf-l-grid__col'] {
    min-width: 0;
}

.cf-l-grid__col {
    flex: 1;
}

.cf-l-grid__col-6 {
    flex: 6 0 0;
}

.cf-l-grid__col-8 {
    flex: 8 0 0;
}

.cf-l-grid__col-33 {
    flex: 0 1 33.3333%;
}

.cf-l-grid__col-flex-1 {
    flex: 1 0 0;
    min-width: 0;
}

// Document wizard

.cf-l-doc-wiz {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex-grow: 1;
    height: 100%;
}

.cf-l-doc-wiz,
.cf-l-doc-wiz__header {
    width: 100%;
}

.cf-l-doc-wiz-header__left-wrapper {
    display: flex;
    align-items: center;
    width: $cf-size-sidebar-width;
}

.cf-l-doc-wiz__title-wrapper {
    display: flex;
    justify-content: center;
    flex: 1;
}

.cf-l-doc-wiz__ctn {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    min-width: 0;
}

.cf-l-doc-wiz__sidebar,
.cf-l-doc-wiz__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 0;
}

.cf-l-doc-wiz__content {
    flex-grow: 2;
    position: relative;
}

.cf-l-donut {
    display: flex;
    flex-grow: 1;
    justify-content: space-around;
    padding: $cf-gutter-xl;
}

.cf-l-my-work {
    display: flex;
}

.cf-l-my-work__column {
    display: flex;
    flex-direction: column;
    flex: 1;
    min-width: 0;
}

.cf-l-column-33 {
    width: (100% / 3);
}

.cf-l-column-33 {
    width: (100% / 3);
}

// List layout
.cf-l-list {
    list-style: none;
}

.cf-l-list__item {
    &:not(:last-of-type) {
        margin-bottom: $cf-gutter;
    }
}

/**
 * New Document Wizard layout
 */
.dw-layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: rem($gutter * 2) rem($gutter) rem($gutter);

    &__body {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        min-width: 0;
    }

    &__sidebar,
    &__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        flex-basis: 0;
        min-width: 0;
    }

    &__content {
        margin-left: 0.5rem;
        flex-grow: 2;
        position: relative;
    }
}
