/**
 * @name Conformio - App Cube Tag
 *
 */

.cf-c-cube-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $cf-size-app-cube;
    height: $cf-size-app-cube;
    background: $cf-color-primary;
    border-radius: $cf-radius-sm;
    font-weight: bold;
    cursor: pointer;
    position: relative;

    &.cf-c-cube-tag--is-secondary-light {
        background: $cf-color-secondary-light;
    }

    &.cf-c-cube-tag--is-amber {
        background: $cf-color-amber;
    }

    &.cf-c-cube-tag--is-gray-02 {
        background: $cf-color-gray-02;
    }

    &.cf-c-cube-tag--is-gray-03 {
        background: $cf-color-gray-03;
    }

    &.cf-c-cube-tag--is-danger {
        background: $cf-color-danger;
    }

    &.cf-c-cube-tag--is-rose {
        background: $cf-color-rose;
    }
}

.cf-c-cube-tag__label {
    color: $cf-color-white;
}
