/**
 * @name Conformio - List mode list
 *
 * Note: currently it's visible in document wiz / wizard tab / list mode (in pill tab)
 *
 */

.cf-c-lm-list {
    list-style: none;
    padding: 0 $cf-gutter;
}

.cf-c-lm-list__item {
    margin-bottom: $cf-gutter-sm;
}
