$cf-color-white: #ffffff;
$cf-color-black: #000000;
$cf-color-transparent: rgba(0, 0, 0, 0);
$cf-color-gray-transparent: #e5e7e852;
$cf-color-transparent-07: rgba(0, 0, 0, 0.7);
$cf-color-gray: #f2f4f8;
$cf-color-gray-01: #e5e7e8;
$cf-color-gray-02: #9f9ea0;
$cf-color-gray-03: #676767;
$cf-color-gray-04: #444444;
$cf-color-unassigned: #bebebe;
$cf-color-gray-canceled: #818181;
$cf-color-brown-01: #d2b2a3;
$cf-color-primary: #4788c6;
$cf-color-primary-lighter: #f9fdff;
$cf-color-primary-light: #c1e7ff;
$cf-color-primary-dark: #244c73;
$cf-color-primary-plan: #4789c6;
$cf-color-primary-border: #5394ff;
$cf-color-danger: #f82f47;
$cf-color-danger-dark: #c63737;
$cf-color-danger-light: #b97b83;
$cf-color-warning: #f0a122;
$cf-color-warning-dark: #fa6400;
$cf-color-warning-light: #fff01c;
$cf-color-secondary: #52a370;
$cf-color-secondary-light: #aedca2;
$cf-color-secondary-dark: #2e5d3f;
$cf-color-cerise: #d760c1;
$cf-color-teal: #20b8bb;
$cf-color-amber: #f5a623;
$cf-color-gold: #f5e723;
$cf-color-sky-blue: #7fcaf8;
$cf-color-gray-blue: #a5c0d9;
$cf-color-pink: #cf6ebe;
$cf-color-rose: #dc8494;
$cf-color-blue-green: #42799c;
$cf-color-ocean-green: #0f7f7b;
$cf-color-red: red;
$cf-color-background: #f2f4f8;
$cf-color-navbar: $cf-color-primary-dark;
$cf-color-navbar-plan: $cf-color-primary-dark;
$cf-color-outline-gray-light: #fbfbfb;
$cf-color-border-main: #e5e7e8;
$cf-color-input-placeholder: #9f9ea0;
$cf-color-input-has-text-border: #9f9ea0;
$cf-color-input-has-text-border-light: rgba(158, 157, 159, 0.7);
$cf-color-input-disabled-bg: #f2f4f8;
$cf-color-focus: #80d1ff;
$cf-color-sidebar-bg: #ffffff;
$cf-color-sidebar-icon: #e5e7e8;
$cf-color-discussion-card-border-active: #a5c5e3;
$cf-color-discussion-wrap-bg: #e6e8ec;
$cf-color-discussion-wrap-border: #d6d6d6;
$cf-color-replies-left-indent-active: #e8f7ff;
$cf-color-doc-blade-overlay: rgba(0, 0, 0, 0.2);
$cf-color-doc-blade-shortcode-bg: #fce7c4;
$cf-color-doc-blade-type-1-bg: #ffffff;
$cf-color-doc-blade-type-2-bg: #aedca2;
$cf-color-doc-blade-type-2-border: #008833;
$cf-color-doc-blade-type-3-border: #52a370;
$cf-color-doc-blade-type-3-bg: #f6fbf5;
$cf-color-doc-blade-type-4-bg: #e6f4e3;
$cf-color-doc-blade-type-5-bg: #fff;
$cf-color-missing-data-background: rgba(245, 166, 35, 0.1);
$cf-color-material-to-be-reviewed: #0f7f7f;

$color-primary-alpha: #299fce;
$color-primary-beta: #244c73;
$color-primary-gamma: #c1e7ff;
$color-primary-delta: #254c73;
$color-primary-epsilon: #4789c6;
$color-primary-zeta: #e0f3ff;
$color-primary-eta: #eff9ff;

$color-danger-alpha: #e74949;
$color-danger-beta: #f8c8c8;
$color-danger-gamma: #d04242;

$color-success-alpha: #70d788;
$color-success-beta: #c6efcf;
$color-success-gamma: #62b575;

$color-warning-alpha: #f19e1b;
$color-warning-beta: #fbe2bb;
$color-warning-gamma: #ffc700;
$color-warning-delta: #ffeeb2;

$color-gray-alpha: #d4dce6;
$color-gray-beta: #8792a2;
$color-gray-gamma: #f2f4f8;

$color-modal-overlay: rgba(211, 211, 211, 0.5);

$color-white: #ffffff;
$color-black: #000000;
