/**
 * @name Conformio - Subsection primary
 *
 * Note: currently visible on incident register
 */

.cf-c-subsection-primary {
    &:not(:last-child) {
        border-bottom: 1px solid $cf-color-gray-01;
    }
}

.cf-c-subsection-primary__heading {
    display: block;
    padding: $cf-gutter-sm 2.5rem;
    font-size: $cf-font-size-stack;
    color: $cf-color-primary;
}

.cf-c-subsection-primary__ctn {
    padding: $cf-gutter 2.5rem $cf-gutter-lg;
}
