.c-notifications {
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;
    background-color: $cf-color-white;
    padding: $cf-gutter-lg $cf-gutter-lg 2.75rem $cf-gutter-xl;
}

.c-notifications__heading {
    font-size: $cf-font-size-lg;
    color: $cf-color-gray-03;
    margin-bottom: $cf-gutter-lg;
}

.c-notifications__subheading {
    font-size: $cf-font-size-stack;
    line-height: $cf-font-line-height-xs;
    font-weight: bold;
    margin-bottom: $cf-gutter;
}

.c-notifications__settings {
    border-bottom: 1px solid $cf-color-gray-01;
    display: flex;
    flex-direction: column;
    padding: 2.75rem 2.5rem $cf-gutter-sm $cf-gutter-md;

    .cf-c-form-switch {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: $cf-gutter-md;
        color: $cf-color-gray-03;
        font-size: $cf-font-stack;
    }
}

.c-notifications__form {
    padding: $cf-gutter-lg 0 $cf-gutter-lg 0;

    &.c-notifications__form--border {
        border-bottom: 1px solid $cf-color-gray-01;
    }
}

.c-notifications__input-group {
    margin-left: $cf-gutter-md;
    display: flex;
    max-width: 33rem;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $cf-gutter;
}

.c-notifications__submit-wrap {
    display: flex;
    max-width: 33rem;
    width: 100%;
    justify-content: flex-end;
    margin-left: $cf-gutter-md;
}

.c-notification__providers {
    display: flex;
    flex-direction: column;

    .cf-c-form-switch {
        max-width: 10rem;
        display: flex;
        justify-content: space-between;

        &:first-child {
            margin-bottom: $cf-gutter;
        }
    }
}

.c-notification__reminder {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: $cf-font-size-sm;
    line-height: $cf-font-line-height-sm;
    color: $cf-color-black;
}

.c-notifications__settings-li-bl {
    > :last-child {
        border-bottom: 0;
    }
}
