/**
 * @name Conformio - Form switch
 *
 */

$cf-form-switch-stretch-width: calculate-switch-stretch-width(
    $cf-form-switch-height,
    $cf-form-switch-border-width
);

@function calculate-switch-stretch-width($height, $border-width) {
    @return $height - $border-width * 2 + ($height - $border-width) * 0.3;
}

@mixin generate-switch-for-custom-sizing($width, $height, $border-width) {
    $stretch-width: calculate-switch-stretch-width($height, $border-width);

    &:active {
        .cf-c-form-switch__toggler {
            .switch-circle {
                width: $stretch-width;
                transform: translate3d($border-width, $border-width, 0);
            }
        }

        .cf-c-form-switch__input:checked {
            + .cf-c-form-switch__toggler {
                .switch-circle {
                    transform: translate3d(
                        #{$width - $stretch-width - $border-width},
                        $border-width,
                        0
                    );
                }
            }
        }
    }

    .cf-c-form-switch__input {
        &:checked {
            + .cf-c-form-switch__toggler {
                &::before {
                    transform: translate3d(
                            #{$width - $height - $border-width},
                            $border-width,
                            0
                        )
                        scale3d(0, 0, 0);
                }

                .switch-circle {
                    transform: translate3d(
                        #{$width - $height + $border-width},
                        $border-width,
                        0
                    );
                }
            }
        }
    }

    .cf-c-form-switch__toggler {
        width: $width;
        height: $height;

        &::before {
            width: $width - $border-width * 2;
            height: $height - $border-width * 2;
            border-radius: ($height - $border-width * 2) / 2;
            transform: translate3d($border-width, $border-width, 0)
                scale3d(1, 1, 1);
        }

        .switch-circle {
            width: $height - $border-width * 2;
            height: $height - $border-width * 2;
            border-radius: ($height - $border-width * 2) / 2;
            transform: translate3d($border-width, $border-width, 0);
        }
    }
}

.cf-c-form-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    // size small
    &.cf-c-form-switch--sm {
        @include generate-switch-for-custom-sizing(
            $cf-form-switch-width-sm,
            $cf-form-switch-height-sm,
            $cf-form-switch-border-width-sm
        );
    }

    // size large
    &.cf-c-form-switch--lg {
        @include generate-switch-for-custom-sizing(
            $cf-form-switch-width-lg,
            $cf-form-switch-height-lg,
            $cf-form-switch-border-width-lg
        );
    }

    // active color primary
    &.active-primary {
        .cf-c-form-switch__input {
            &:checked {
                + .cf-c-form-switch__toggler {
                    background-color: $cf-color-primary;
                }
            }
        }
    }

    // active color primary epsilon
    &.active-primary-epsilon {
        .cf-c-form-switch__input {
            &:checked {
                + .cf-c-form-switch__toggler {
                    background-color: $color-primary-epsilon;

                    svg {
                        color: $color-primary-epsilon;
                    }
                }
            }
        }
    }

    // active color gray
    &.active-gray {
        .cf-c-form-switch__input {
            &:checked {
                + .cf-c-form-switch__toggler {
                    background-color: $cf-color-gray-02;
                }
            }
        }
    }

    // inactive color primary
    &.inactive-primary {
        .cf-c-form-switch__toggler {
            background-color: $cf-color-primary;

            &::before {
                background-color: $cf-color-primary;
            }
        }
    }

    &.inactive-gray-beta {
        .cf-c-form-switch__toggler {
            background-color: $color-gray-beta;

            &::before {
                background-color: $color-gray-beta;
            }

            svg {
                color: $color-gray-beta;
            }
        }
    }

    // inactive color gray
    &.inactive-light {
        .cf-c-form-switch__toggler {
            background-color: $cf-color-gray-01;

            &::before {
                background-color: $cf-color-gray-01;
            }
        }
    }

    // inactive color danger
    &.inactive-danger {
        .cf-c-form-switch__toggler {
            background-color: $cf-color-danger;

            &::before {
                background-color: $cf-color-danger;
            }
        }
    }

    &.cf-c-form-switch--vertical {
        transform: rotate(90deg);
        margin: $cf-gutter-075 -0.75rem;
    }

    &:active {
        .cf-c-form-switch__toggler {
            .switch-circle {
                width: $cf-form-switch-stretch-width;
                transform: translate3d(
                    $cf-form-switch-border-width,
                    $cf-form-switch-border-width,
                    0
                );
            }
        }

        .cf-c-form-switch__input:checked {
            + .cf-c-form-switch__toggler {
                .switch-circle {
                    transform: translate3d(
                        #{$cf-form-switch-width - $cf-form-switch-stretch-width -
                            $cf-form-switch-border-width},
                        $cf-form-switch-border-width,
                        0
                    );
                }
            }
        }
    }

    &.cf-is-disabled {
        opacity: 0.6;
        pointer-events: none;
    }
}

.cf-c-form-switch__input {
    display: block;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked {
        + .cf-c-form-switch__toggler {
            background-color: $cf-color-secondary;

            &::before {
                transform: translate3d(
                        #{$cf-form-switch-width - $cf-form-switch-height -
                            $cf-form-switch-border-width},
                        $cf-form-switch-border-width,
                        0
                    )
                    scale3d(0, 0, 0);
            }

            .switch-circle {
                transform: translate3d(
                    #{$cf-form-switch-width - $cf-form-switch-height +
                        $cf-form-switch-border-width},
                    $cf-form-switch-border-width,
                    0
                );
            }
        }
    }

    &:focus {
        + .cf-c-form-switch__toggler {
            @include focus-default;
        }
    }
}

.cf-c-form-switch__toggler {
    position: relative;
    display: inline-block;
    width: $cf-form-switch-width;
    height: $cf-form-switch-height;
    background-color: $cf-color-gray-02;
    border-radius: $cf-form-switch-height;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        width: $cf-form-switch-width - $cf-form-switch-border-width * 2;
        height: $cf-form-switch-height - $cf-form-switch-border-width * 2;
        background-color: $cf-color-gray-02;
        border-radius: (
                $cf-form-switch-height - $cf-form-switch-border-width * 2
            ) / 2;
        transform: translate3d(
                $cf-form-switch-border-width,
                $cf-form-switch-border-width,
                0
            )
            scale3d(1, 1, 1);
        transition: all 0.25s linear;
    }

    .switch-circle {
        content: '';
        position: absolute;
        left: 0;
        width: $cf-form-switch-height - $cf-form-switch-border-width * 2;
        height: $cf-form-switch-height - $cf-form-switch-border-width * 2;
        background-color: $cf-color-white;
        border-radius: (
                $cf-form-switch-height - $cf-form-switch-border-width * 2
            ) / 2;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
        transform: translate3d(
            $cf-form-switch-border-width,
            $cf-form-switch-border-width,
            0
        );
        transition: all 0.2s ease-in-out;
    }
}
