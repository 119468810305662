/**
 * @name Conformio - Overrides for single classes/ids
 */

#echeckout-iframe {
    z-index: $cf-z-index-checkout-iframe;
}

// temporary here - will be removed after refactoring of step training component
.cf-c-tags-input-wrapper {
    display: flex;
    flex: 1;

    .ti-input {
        flex: 1;
    }

    .ti-tags {
        align-content: center;
        align-items: center;
    }
}

// popper

.cf-c-popper {
    .popper {
        width: 26rem;
        transform: translate(0, 1.75rem) !important;

        .cf-c-searchable-list__items {
            max-height: auto;
        }

        .cf-c-checkbox-list {
            height: 16.55rem;
        }
    }
}

.cf-c-library-trigger-flex-center {
    & > span {
        &,
        & > span {
            @include flex-center;
        }

        .popper {
            width: 25rem;
            max-width: 25rem;
        }
    }
}

// compliance dropdown override
.cf-c-compliance-item-no-dropdown {
    color: $cf-color-gray-03;
    padding: $cf-gutter-075 $cf-gutter;
}

.cf-c-compliance-item-no-dropdown__title {
    color: $cf-color-gray-03;
}

.cf-c-compliance-item-no-dropdown__icon-wrap {
    width: $cf-size-icon-md;
    height: $cf-size-icon-md;
    cursor: pointer;

    .cf-c-icon {
        width: 100%;
        height: 100%;
    }
}

/*
 * vue-select overrides
 *
 *
 * @see https://vue-select.org/guide/css.html#overriding-default-styles
*/

.vs__clear {
    z-index: 1;
}

.bot-circle.active + .og-chat-box-outer {
    z-index: 0 !important;
}
