/**
 * @name Conformio - switch
 *
 */

$cf-switch-stretch-width: $cf-switch-height - $cf-switch-border-width * 2 +
    ($cf-switch-height - $cf-switch-border-width * 2) * 0.3;

$cf-switch-stretch-width-sm: $cf-switch-height-sm - $cf-switch-border-width-sm *
    2 + ($cf-switch-height-sm - $cf-switch-border-width-sm * 2) * 0.3;

.cf-c-switch {
    display: inline-block;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
    margin-top: 3rem;
}

.cf-c-switch__input {
    display: block;
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:checked {
        + .cf-c-switch__toggler {
            background-color: $cf-color-white;

            &::before {
                transform: translate3d(
                        #{$cf-switch-width-xxxl - $cf-switch-height-xxxl -
                            $cf-switch-border-width},
                        $cf-switch-border-width,
                        0
                    )
                    scale3d(0, 0, 0);
            }

            &::after {
                transform: translate3d(
                    #{$cf-switch-width-xxxl - $cf-switch-height-xxxl +
                        $cf-switch-border-width},
                    $cf-switch-border-width,
                    0
                );
            }
        }
    }

    &:focus {
        + .cf-c-switch__toggler {
            @include focus-default;
        }
    }
}

.cf-c-switch__toggler {
    position: relative;
    display: inline-block;
    width: $cf-switch-width-xxxl;
    height: $cf-switch-height-xxxl;
    background-color: $cf-color-white;
    border: 1px solid $cf-color-primary;
    border-radius: $cf-radius-xxxxl;
    vertical-align: text-bottom;
    transition: all 0.3s linear;

    .cf-c-switch__toggler-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 100%;
        padding-left: 0.188rem;
        padding-right: 0.188rem;
        p {
            margin: 0;
            font-weight: bold;
            display: flex;
            flex-direction: column;
        }
    }

    .cf-c-switch__toggler-button-text {
        width: calc(100% / 2);
        flex-shrink: 0;
        color: $cf-color-primary;
        padding: 1rem 2rem;
        span {
            font-size: $cf-font-size-sm;
        }
    }

    .cf-c-switch__toggler-button-text-format {
        padding: 1rem 0.5rem;
    }

    .cf-c-switch__toggler-button-text-left,
    .cf-c-switch__toggler-button-text-right {
        background-color: $cf-color-primary;
        color: $cf-color-white;
        height: 3.25rem;
        border-radius: 6.25rem;
    }

    .cf-c-switch__toggler-button-text-right {
        display: flex;
        flex-direction: column;
        padding: 1rem 2rem;
        span {
            font-size: $cf-font-size-sm;
        }
    }
}
