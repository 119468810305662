/**
 * @name Conformio - ROR review header
 *
 */

.cf-c-ror-review-toolbar {
    @include flex-align-center;
    justify-content: space-between;
    padding: calc(#{$cf-gutter-sm} - 1px);
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
    background-color: $cf-color-gray;
}

.cf-c-ror-review-toolbar__select-all {
    color: $cf-color-primary;
    font-weight: bold;
    margin-left: $cf-gutter;
}

.cf-c-ror-review-toolbar__toolbar {
    @include flex-align-center;
}
