.cf-c-checkbox-multiselect {
    .multiselect__tags {
        min-height: 3.125rem;
        border: 1px solid $cf-color-gray-02;
        border-radius: $cf-radius-sm;
        display: flex;
        align-items: center;
        padding-top: 0;
    }

    .multiselect__tags-wrap {
        padding-top: 0.5rem;
        padding-bottom: 0;
    }

    .multiselect__select {
        height: 3.125rem;
        top: 0;
        right: 0;
    }

    .multiselect__content-wrapper {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.3);
        margin-bottom: 0;
        border: none;
    }

    .multiselect__placeholder {
        margin: 0;
    }

    .multiselect__element {
        &:not(:last-child) {
            .multiselect__option {
                border-bottom: 1px solid $cf-color-gray-01;
            }
        }
    }

    .multiselect__option {
        min-height: 2.5rem;
        padding: 0.5rem 1.125rem;

        &:after {
            display: none;
        }

        > div {
            width: 100%;
            display: flex;
            align-items: center;

            > div {
                display: flex;
                flex-direction: row-reverse;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
            }
        }

        &.multiselect__option--highlight {
            background-color: transparent;
        }

        &.multiselect__option--selected {
            font-weight: 400;
            background-color: transparent;
        }
    }

    .cf-c-checkbox-multiselect__label {
        font-size: 1rem;
        line-height: 1.5;
        color: $cf-color-black;
    }

    .form-check-input {
        position: relative;
        margin: 0;
        padding: 0;
        margin-right: 0.5rem;
    }

    .cf-c-checkbox-input {
        display: flex;
        align-items: center;
        justify-content: center;

        &:checked {
            &:after {
                top: 0;
                left: 0;
                position: relative;
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
