/**
 * @name Conformio - Attach file
 *
 */

.cf-c-attach-file-wrapper {
    display: flex;
}

.cf-c-attach-file {
    display: flex;
}

.cf-c-attach-file__ctn {
    @include flex-align-center;
    color: $cf-color-primary;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    text-decoration: underline;
    display: flex;
    flex-direction: column;
    margin-top: $cf-gutter-xs;
    cursor: pointer;

    &:hover {
        color: $cf-color-primary-dark;
    }

    .cf-c-add-file__input:focus + & {
        color: $cf-color-primary-dark;
    }
}
