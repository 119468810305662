/**
 * @name Conformio - Related risk
 *
 */

.cf-c-related-risk {
    display: flex;
    position: relative;
    border: 1px solid $cf-color-gray-01;
    box-shadow: $cf-c-shadow-default;
    min-height: 4rem;
    background-color: $cf-color-white;

    &.cf-c-related-risk--checkbox {
        .cf-c-related-risk__id {
            justify-content: flex-start;
            width: 9rem;
        }
    }
}

.cf-c-related-risk__id {
    @include flex-center;
    width: 6.25rem;
    font-size: $cf-font-size-stack;
    border-right: 1px solid $cf-color-gray-01;
}

.cf-c-related-risk__ctn {
    display: flex;
    flex: 1 1 0;
    min-width: 0;
    font-size: $cf-font-size-sm;
    line-height: 1.333;
}

.cf-c-related-risk__ctn-col {
    flex: 1 1 0;
    min-width: 0;
}

.cf-c-related-risk__ctn-header {
    padding: #{$cf-gutter-xs / 2} $cf-gutter-md;
    border-bottom: 1px solid $cf-color-gray-01;
    white-space: nowrap;
}

.cf-c-related-risk__ctn-body {
    padding: 0.375rem $cf-gutter-md;
    color: $cf-color-gray-03;
}

.cf-c-related-risk__preview {
    @include flex-align-center;
    flex-direction: column;
    justify-content: space-around;
    width: 3rem;
    border-left: 1px solid $cf-color-gray-01;
}

.cf-c-related-risk__preview-btn {
    @include button-clear;
    display: flex;
    color: $cf-color-primary;
}
