/**
 * @name Conformio - Adaptation content
 *
 */

.cf-c-adaptation-content {
    display: flex;
    font-size: $cf-font-size-sm;

    &.cf-c-adaptation-content--lg {
        font-size: $cf-font-size-stack;
        min-height: 4rem;

        .cf-c-adaptation-content__main {
            flex-direction: row;
            align-items: center;
            padding: $cf-gutter-sm $cf-gutter-md;
        }

        .cf-c-adaptation-content__ctn {
            flex: 1 1 0;
            line-height: 1.5;
        }

        .cf-c-adaptation-content__link {
            width: 22rem;
            margin-top: 0;
        }

        .cf-c-adaptation-content__action {
            flex-direction: row;
            width: 7.5rem;
        }
    }
}

.cf-c-adaptation-content__main {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    padding: $cf-gutter-sm $cf-gutter;
    line-height: $cf-font-line-height-sm;
}

.cf-c-adaptation-content__link {
    margin-top: $cf-gutter-sm;
    font-weight: bold;
    text-decoration: underline;
    color: $cf-color-primary;
}

.cf-c-adaptation-content__action {
    @include flex-align-center;
    flex-direction: column;
    justify-content: space-around;
    flex-shrink: 0;
    width: 3.25rem;
    margin-left: -$cf-gutter;
    padding: $cf-gutter-sm;
}
