/**
 * @name Conformio - Labeled icon
 *
 */

.cf-c-labeled-icon {
    @include flex-align-center;
    width: 100%;

    &.cf-c-labeled-icon--is-label-bolded {
        .cf-c-labeled-icon__text {
            font-weight: bold;
        }
    }

    &.cf-c-labeled-icon--has-no-truncated-text {
        .cf-c-labeled-icon__text-wrap {
            height: auto;
        }

        .cf-c-labeled-icon__text {
            position: static;
            white-space: normal;
        }
    }

    &.cf-c-labeled-icon--is-color-inherit {
        .cf-c-labeled-icon__text {
            color: inherit;
        }
    }

    &.cf-c-labeled-icon--size-sm {
        .cf-c-labeled-icon__icon-wrap {
            width: $cf-size-icon-sm;
            height: $cf-size-icon-sm;
        }
    }
}

.cf-c-labeled-icon__icon-wrap {
    @include flex-center;
    width: $cf-size-icon;
    height: $cf-size-icon;
    margin-right: $cf-gutter-sm;
    color: $cf-color-primary;
}

.cf-c-labeled-icon__text-wrap {
    display: flex;
    flex: 1;
    min-width: 0;
    height: 1.5em;
}

.cf-c-labeled-icon__text {
    @include text-ellipsis;
    flex: 1;
    vertical-align: middle;
    text-align: left;
    color: $cf-color-gray-03;
}

.cf-c-labeled-icon__text--disabled {
    color: $cf-color-unassigned;
}

.cf-c-labeled-icon__right-caret {
    @include flex-center;
    width: $cf-gutter-lg;

    &:after {
        content: '';
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: $cf-color-gray-03 transparent transparent;

        @at-root {
            .is-up#{&} {
                transform: rotate(180deg);
            }
        }
    }
}

.cf-c-labeled-icon__right-element {
    @include flex-align-center;
    margin-left: $cf-gutter-lg;
}
