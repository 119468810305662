/**
 * @name Conformio - Register section
 *
 */

.cf-c-reg-section {
    display: flex;
    flex-direction: column;
    min-height: 13rem;
    margin-bottom: $cf-gutter-xl;
}

.cf-c-reg-section__heading {
    padding: $cf-gutter-md $cf-gutter-md $cf-gutter-075;
}

.cf-c-reg-section__action {
    @include flex-justify-center;
    margin-top: $cf-gutter-075;
}

.cf-c-reg-section__body {
    margin-top: auto;
    padding: $cf-gutter;
}
