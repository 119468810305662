/**
 * @name Conformio - My Work Single Task
 *
 */

.cf-c-my-work-task {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: $cf-font-size-sm;
    padding: $cf-gutter-sm $cf-gutter;
    @include cf-box(0, 0);

    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }
}

.cf-c-my-work-task__title-wrapper {
    color: $cf-color-gray-03;
    flex: 1 1 0;
    min-width: 0;
    padding-right: $cf-gutter;
}

.cf-c-my-work-task__assignee {
    margin: 0 $cf-gutter-lg 0 $cf-gutter-sm;
    display: flex;
    justify-content: flex-end;

    .cf-c-pill {
        max-width: 5rem;
    }
}

.cf-c-my-work-task__date {
    color: $cf-color-danger;
    min-width: 5rem;
}
