*,
*:after,
*:before {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
}

html,
body {
    min-height: 100vh;
    width: 100%;
    margin: 0;
    padding: 0;
    font-family: $font-open-sans;
    font-size: percentage($em-base / $em-base);
    line-height: $line-height-base;
    background-color: $cf-color-background;
    scroll-behavior: smooth;
}

html.withScaling,
html.withScaling body {
    font-size: percentage($em-base-sm / $em-base);

    @media screen and (min-width: #{$breakpoint-app-navigation-collapsed}px) {
        font-size: percentage($em-base / $em-base);
    }
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

a {
    &:hover {
        text-decoration: none;
    }
}

h1,
h2,
h3,
h4,
h5 {
    margin-bottom: 0.5em;
    line-height: $cf-font-line-height-xs;
}

h1,
h2,
h3,
h4 {
    font-weight: bold;
}

h1 {
    font-size: $cf-font-size-xxl;
}

h2 {
    font-size: $cf-font-size-xl;
}

h3 {
    font-size: $cf-font-size-lg;
}

h4 {
    font-size: $cf-font-size-md;
}

h5 {
    font-size: $cf-font-size-stack;
    margin: 0;
}

p {
    margin-bottom: 1em;
}

label {
    display: inline-block;
    margin-bottom: 0;
}

ol,
ul,
dl {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
}

th,
td {
    padding: 0;
}

th {
    text-align: inherit;
}

input[type='number'] {
    -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

.text-link {
    color: inherit;
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover {
        text-decoration: underline;
        color: $color-primary-epsilon;
    }
}
