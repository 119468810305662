/**
 * @name Conformio - List Item Itself
 *
 */

.cf-c-dropdown-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-weight: bold;

    &.cf-c-dropdown-list-item--item-has-info {
        .cf-c-dropdown-list-item__info-icon {
            position: absolute;
            right: -1.5rem;
            top: 50%;
            transform: translateY(-61%);
            cursor: pointer;
        }
    }
}
