/**
 * @name Conformio - Buttons
 *
 */

.cf-c-btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: $cf-size-btn-default;
    max-width: 300px;
    flex-shrink: 0;
    width: max-content;
    padding: 0 $cf-gutter-075;
    border: 2px solid transparent;
    border-radius: $cf-radius-sm;
    font-weight: 700;
    text-align: center;
    font-size: rem(16);
    transition: all 0.15s ease-in-out;

    &:not(:disabled):not(.disabled) {
        cursor: pointer;
    }

    &:focus {
        @include focus-default;
    }
}

.cf-c-btn--primary {
    color: $cf-color-white;
    background-color: $cf-color-primary;
    border-color: $cf-color-primary;

    &:hover {
        background-color: $cf-color-primary-dark;
        border-color: $cf-color-primary-dark;
    }

    &:disabled,
    &.cf-c-btn--is-disabled {
        background-color: $cf-color-gray-01;
        border-color: $cf-color-gray-01;
    }
}

.cf-c-btn--primary-light-bg {
    color: $cf-color-primary;
    background-color: $cf-color-primary-light;
    border-color: $cf-color-primary;

    &:hover {
        color: $cf-color-white;
        background-color: $cf-color-primary;
        border-color: $cf-color-primary;
    }

    &:disabled,
    &.cf-c-btn--is-disabled {
        background-color: $cf-color-gray-01;
        border-color: $cf-color-gray-01;
    }
}

.cf-c-btn--min-width-10 {
    min-width: 10.25rem;
}

.cf-c-btn--danger {
    color: $cf-color-white;
    background-color: $cf-color-danger;
    border-color: $cf-color-danger;

    &:hover {
        background-color: $cf-color-danger-dark;
        border-color: $cf-color-danger-dark;
    }

    &:disabled,
    &.cf-c-btn--is-disabled {
        background-color: $cf-color-gray-01;
        border-color: $cf-color-gray-01;
    }
}

.cf-c-btn--secondary {
    color: $cf-color-white;
    background-color: $cf-color-secondary;
    border-color: $cf-color-secondary;

    &:hover {
        background-color: $cf-color-secondary-dark;
        border-color: $cf-color-secondary-dark;
    }

    &:disabled,
    &.cf-c-btn--is-disabled {
        background-color: $cf-color-gray-01;
        border-color: $cf-color-gray-01;
    }
}

.cf-c-btn--primary-outline {
    color: $cf-color-primary;
    border-color: $cf-color-primary;
    background-color: transparent;

    &:hover {
        color: $cf-color-white;
        background-color: $cf-color-primary;
        border-color: $cf-color-primary;
    }
    &--no-hover {
        pointer-events: none;
    }
}

.cf-c-btn--disabled {
    color: $cf-color-gray-02;
    border-color: $cf-color-gray-02;
    background-color: transparent;
    pointer-events: none;
}

.cf-c-btn--quiet {
    color: $cf-color-primary;
    border-color: transparent;
    background-color: transparent;

    &:hover {
        color: $cf-color-primary-dark;
    }
}

.cf-c-btn--quiet-danger {
    color: $cf-color-danger;
    border-color: transparent;
    background-color: transparent;

    &:hover {
        color: $cf-color-danger-dark;
    }
}

.cf-c-btn--quiet-black {
    color: $cf-color-black;
    border-color: transparent;
    background-color: transparent;

    &:hover {
        color: $cf-color-primary;
    }
}

.cf-c-btn--danger-light {
    color: $cf-color-white;
    border-color: transparent;
    background-color: $cf-color-danger-light;

    &:hover {
        background-color: $cf-color-danger-dark;
    }

    &:disabled,
    &.cf-c-btn--is-disabled {
        background-color: $cf-color-gray-02;
        border-color: $cf-color-gray-02;
    }
}

.cf-c-btn--quiet-danger-light {
    color: $cf-color-danger-light;
    border-color: transparent;
    background-color: transparent;

    &:disabled,
    &.cf-c-btn--is-disabled {
        color: $cf-color-gray-02;
    }

    &:hover {
        color: $cf-color-danger-dark;
    }
}

.cf-c-btn--clear {
    height: auto;
    padding: 0;
    border: none;
    background-color: transparent;
    color: inherit;

    &:hover {
        border-color: transparent;
        background-color: transparent;
    }
}

// Button with icon
.cf-c-btn--with-icon {
    padding: 0 $cf-font-size-stack 0 0; // 0.053 vertical align fix
}

.cf-c-btn--with-icon__icon {
    @include flex-align-center;
    margin: 0 $cf-gutter-075;
}

// Size variations
.cf-c-btn--sm {
    min-height: $cf-size-btn-sm;
    padding-left: $cf-gutter;
    padding-right: $cf-gutter;
}

.cf-c-btn--md {
    min-height: rem($cf-size-btn-default);
    padding-left: $cf-gutter;
    padding-right: $cf-gutter;
}

.cf-c-btn--lg {
    min-height: $cf-size-btn-lg;
    padding-left: $cf-gutter;
    padding-right: $cf-gutter;
}

.cf-c-btn--auto {
    display: block;
    height: auto;
    font-size: $cf-font-size-sm;
    line-height: 1;
    padding: 0.15rem 1.25rem;
}

.cf-c-btn--full {
    width: 100%;
}

.cf-c-btn--square-md {
    width: $cf-gutter-md;
    height: $cf-gutter-md;
}

.cf-c-btn--capitalized {
    text-transform: capitalize;
}

.cf-c-btn--uppercased {
    text-transform: uppercase;
}

// Padding variations

.cf-c-btn--pad-hor-sm {
    padding-left: $cf-gutter-sm;
    padding-right: $cf-gutter-sm;
}

.cf-c-btn--pad-hor-md {
    padding-left: $cf-gutter-md;
    padding-right: $cf-gutter-md;
}

.cf-c-btn--pad-hor-lg {
    padding-left: $cf-gutter-lg;
    padding-right: $cf-gutter-lg;
}

.cf-c-btn--pad-hor-xl {
    padding-left: $cf-gutter-xl;
    padding-right: $cf-gutter-xl;
}

.button--disabled,
.cf-c-step-card__btn--disabled {
    pointer-events: none;
    background-color: gray;
    border: 1px solid grey;
}

.cf-c-step--disabled,
.section--disabled {
    pointer-events: none;
    color: gray;
    opacity: 0.7;
}

.cf-c-btn--font-size-09 {
    font-size: $cf-font-size-09;
}

.cf-l-app__ctn.company-disabled {
    input,
    textarea,
    select,
    label,
    .v-select,
    .cf-c-reg-section-form-group__input-wrap,
    .cf-c-date-picker,
    .cf-c-soa-item__justification,
    .cf-c-soa-item__implementation,
    .cf-c-multiselect {
        pointer-events: none;
        color: gray !important;
    }

    label {
        opacity: 0.7;
        pointer-events: none;
    }

    button:disabled {
        color: gray !important;
    }

    .v-select * {
        pointer-events: none !important;
        color: gray !important;
    }
}

.cf-l-app__ctn.company-disabled {
    .c-subscription {
        label:not(.cf-is-disabled) {
            pointer-events: all !important;
            opacity: unset !important;
        }
    }
}

.button--disabled,
.cf-c-step-card__btn--disabled {
    pointer-events: none;
    background-color: gray;
    border: 1px solid grey;
}

.cf-c-step-card__link--disabled {
    pointer-events: none;
    color: gray;
}

.cf-c-step-card__link__btn--disabled {
    opacity: 0.5;
    pointer-events: none;
}

.--link-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.--link-disabled-with-tooltip {
    opacity: 0.5;
}

.cf-c-btn--no-padding {
    padding: 0;
}

.print-button {
    height: auto;
    min-height: auto;
    padding: 0;
    background-color: #f2f4f8;
    border: none;
    color: #4788c6;
    font-weight: normal;
}

.btn {
    display: flex;
    padding: rem(7) rem(8);
    gap: rem(8);
    border: 1px solid transparent;
    border-radius: rem(4);
    font-size: rem(14);
    font-weight: 700;
    line-height: calc(18 / 14);
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    transition: all 0.25s ease;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        background-color: $color-gray-alpha;
        border-color: $color-gray-beta;
        color: $color-gray-beta;
        opacity: 1;
        cursor: default;
    }

    &--alpha {
        background-color: $color-primary-epsilon;
        border-color: $color-primary-epsilon;
        color: $color-white;

        &:not([disabled]):hover {
            background-color: $color-primary-beta;
            border-color: $color-primary-beta;
            color: $color-white;
        }
    }

    &--beta {
        background-color: $color-white;
        border-color: $color-gray-alpha;
        color: $color-primary-beta;

        &:not([disabled]):hover {
            background-color: $color-primary-gamma;
            border-color: $color-primary-gamma;
            color: $color-primary-beta;
        }
    }

    &--gamma {
        background-color: $color-white;
        border-color: $color-primary-epsilon;
        color: $color-primary-epsilon;

        &:not([disabled]):hover {
            background-color: $color-primary-epsilon;
            border-color: $color-primary-gamma;
            color: $color-white;
        }
    }

    &--danger {
        background-color: $color-danger-alpha;
        border-color: $color-danger-alpha;
        color: $color-white;

        &:not([disabled]):hover {
            background-color: $color-danger-gamma;
            border-color: $color-danger-gamma;
            color: $color-white;
        }
    }

    &--danger-outline {
        background-color: $color-white;
        border-color: $color-danger-alpha;
        color: $color-danger-alpha;

        &:not([disabled]):hover {
            background-color: $color-white;
            border-color: $color-danger-gamma;
            color: $color-danger-gamma;
        }
    }

    &--success {
        background-color: $color-success-alpha;
        border-color: $color-success-alpha;
        color: $color-white;

        &:not([disabled]):hover {
            background-color: $color-success-gamma;
            border-color: $color-success-gamma;
            color: $color-white;
        }
    }
}

.btn-icon {
    display: flex;
    margin: 0;
    padding: 0;
    border: 0;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    transition: all 0.25s ease;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        color: $color-gray-beta;
        cursor: default;
    }

    &--filled {
        padding: rem(6) rem(8);
        border-radius: rem(4);

        &:disabled {
            background-color: $color-gray-alpha;
            border-color: $color-gray-beta;
        }
    }

    &--alpha {
        color: $color-primary-beta;

        &:not([disabled]):hover {
            color: $color-primary-epsilon;
        }
    }

    &--danger {
        color: $color-danger-alpha;

        &:not([disabled]):hover {
            color: $color-danger-gamma;
        }
    }

    &--danger-filled {
        background-color: $color-danger-alpha;
        color: $color-white;

        &:not([disabled]):hover {
            background-color: $color-danger-gamma;
            color: $color-danger-gamma;
        }
    }

    &--success {
        color: $color-success-alpha;

        &:not([disabled]):hover {
            color: $color-success-gamma;
        }
    }
}

.btn-link {
    display: flex;
    padding: 0;
    gap: rem(8);
    border: none;
    font-weight: 700;
    font-size: rem(14);
    line-height: calc(20 / 14);
    text-decoration: underline;
    align-items: center;
    background: none;
    transition: color 0.25s ease;

    &:focus,
    &:active {
        box-shadow: none;
        outline: none;
    }

    &:disabled {
        color: $color-gray-beta;
        cursor: default;
    }

    &--alpha {
        color: $color-primary-epsilon;

        &:not([disabled]):hover {
            color: $color-primary-beta;
        }
    }
}
