/**
 * @name Conformio - Step Bar
 *
 */

.cf-c-step-bar {
    display: flex;
    flex: 1 1 0;
    min-width: 0;
}

.cf-c-step-bar__item {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 0;
    max-width: calc(100% / 4);

    &.cf-c-step-bar__item--inactive {
        .cf-c-step-bar__progress {
            &:before {
                background-color: $cf-color-gray-01;
            }
        }

        .cf-c-step-bar__dot {
            border: 1px solid $cf-color-gray-01;
            background-color: $cf-color-white;
            box-shadow: $cf-c-shadow-default;
        }
    }

    &.cf-is-passed {
        .cf-c-step-bar__progress {
            &:before {
                background-color: $cf-color-primary-light !important;
            }
        }
    }

    &.cf-is-active {
        .cf-c-step-bar__dot {
            width: 1rem;
            height: 1rem;
            border: 4px solid $cf-color-primary;
            background-color: $cf-color-primary;
        }

        .cf-c-step-bar__progress {
            &:before {
                background-color: $cf-color-gray-01;
            }
        }

        & ~ .cf-c-step-bar__item {
            .cf-c-step-bar__progress {
                &:before {
                    background-color: $cf-color-gray-01;
                }
            }

            .cf-c-step-bar__dot {
                border: 1px solid $cf-color-gray-01;
                background-color: $cf-color-white;
            }
        }
    }

    &:last-child {
        .cf-c-step-bar__progress {
            &:before {
                display: none;
            }
        }
    }
}

.cf-c-step-bar__progress {
    @include flex-center;
    position: relative;
    height: 1rem;

    &:before {
        content: '';
        display: block;
        height: 4px;
        background-color: $cf-color-primary-light;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateX(50%);
    }
}

.cf-c-step-bar__dot {
    width: 0.75rem;
    height: 0.75rem;
    position: relative;
    border-radius: 50%;
    background-color: $cf-color-primary-light;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-step-bar__label {
    display: flex;
    margin-top: $cf-gutter-xs;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
    text-align: center;
    line-height: 1.5;
    justify-content: center;
    flex-wrap: wrap;

    &--active {
        color: $cf-color-primary;
    }

    &--relative {
        position: relative;
    }
}

.cf-c-step-bar__label-text {
    @include text-ellipsis;
    max-width: calc(100% - 30px);
    margin: 0;
}

.cf-c-step-bar__sub-label {
    width: 100%;
    font-size: 10px;
    color: #676767;
}

.cf-c-step-bar__icon {
    position: relative;
    top: -5px;
    cursor: pointer;
    flex-shrink: 0;
}

.cf-c-step-bar__layout {
    border-radius: $cf-radius;
    background-color: $cf-color-white;
    margin-bottom: $cf-gutter-sm;
}

.cf-c-step-bar__over-width {
    width: 150%;
}
