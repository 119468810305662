.cf-c-suggested-card {
    box-shadow: $cf-c-shadow-default;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;
    margin-bottom: $cf-gutter;
}

.cf-c-suggested-card__heading {
    display: flex;
    border-bottom: 1px solid $cf-color-gray-01;
}

.cf-c-suggested-card__info-wrap {
    flex-grow: 1;
    padding: $cf-gutter-sm $cf-gutter;
}

.cf-c-suggested-card__checkbox-wrap {
    width: 4rem;
    border-left: 1px solid $cf-color-gray-01;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cf-c-suggested-card__body {
    padding: $cf-gutter;
}

.cf-c-suggested-card__row {
    display: flex;
    align-items: center;
    padding: $cf-gutter-075 0;
}

.cf-c-suggested-card__row-label {
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
    margin-bottom: 0;
    min-width: 8rem;
}
