/**
 * @name Conformio - Collapsible with checkbox
 *
 */

.cf-c-chk-collapsible {
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;

    &:not(:last-child) {
        margin-bottom: $cf-gutter-sm;
    }

    &.cf-is-open {
        .cf-c-chk-collapsible__trigger {
            &:after {
                transform: rotate(-180deg);
            }
        }
    }

    &.cf-c-chk-collapsible--single {
        .cf-c-chk-collapsible__trigger {
            height: 100%;
        }

        .cf-c-chk-collapsible__checkbox {
            display: none;
        }
    }
}

.cf-c-chk-collapsible__header {
    @include flex-1;
    display: flex;
}

.cf-c-chk-collapsible__icon-wrapper {
    border-right: 1px solid $cf-color-gray-01;
}

.cf-c-chk-collapsible__trigger,
.cf-c-chk-collapsible__checkbox {
    @include flex-center;
    height: 2.6rem;
    width: 2.5rem;
}

.cf-c-chk-collapsible__trigger {
    border-bottom: 1px solid $cf-color-gray-01;
    cursor: pointer;
    user-select: none;

    &:after {
        @include css-shape-arrow-down($size: 0.5rem);
        content: '';
        display: block;
        transition: transform $cf-animation-speed-fast ease-in-out;
    }
}

.cf-c-chk-collapsible__ctn {
    border-top: 1px solid $cf-color-gray-01;
}
