.app-tooltip {
    .tooltip-inner {
        padding: rem(16) rem(12);
        border-radius: rem(4);
        font-size: rem(12);
        font-weight: 700;
        line-height: calc(19 / 12);
        text-align: left;
        box-shadow: 0 rem(4) rem(4) rgba(0, 0, 0, 0.25);
    }

    .arrow {
        display: none;
    }

    &--alpha {
        .tooltip-inner {
            background-color: $color-primary-beta;
            color: $color-white;
        }
    }

    &--md {
        .tooltip-inner {
            min-width: rem(350);
        }
    }

    &--with-smaller-z-index {
        z-index: $z-index-tooltip-small;
    }
}

.tooltip.app-tooltip {
    opacity: 1;
}
