/**
 * @name Conformio - Data Card section
 *
 */

.cf-c-data-card-section__title {
    @include data-card-highlight-title;
}

.cf-c-data-card-section__ctn {
    margin-bottom: $cf-gutter;
}

.cf-c-data-card-section__ctn-item {
    @include data-card-text-default;
    display: block;
    word-break: break-word;
}
