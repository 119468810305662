/**
 * @name Conformio - Radio card
 *
 */

.cf-c-radio-card {
    flex: 1;
    position: relative;

    &.cf-c-radio-card--descriptive {
        width: 20rem;
        max-width: 100%;
    }
}

.cf-c-radio-card__field {
    position: absolute;
    opacity: 0;

    &:checked ~ .cf-c-radio-card__appearance {
        border: 2px solid $cf-color-primary;
    }

    &:checked ~ .cf-c-radio-card__ctn {
        .cf-c-radio-card__label-text {
            font-weight: bold;
        }

        .cf-c-radio-card__dot {
            &:after {
                @include absolute-center;
                opacity: 1;
            }
        }
    }

    &:focus ~ .cf-c-radio-card__ctn {
        .cf-c-radio-card__dot {
            @include focus-default;
        }
    }
}

.cf-c-radio-card__appearance {
    @include absolute-full;
    @include cf-box(none);
    pointer-events: none;

    .cf-c-radio-card--disabled & {
        background-color: $cf-color-input-disabled-bg;
    }
}

.cf-c-radio-card__ctn {
    position: relative;
}

.cf-c-radio-card__icon-wrap {
    @include flex-justify-center;
    padding-top: $cf-gutter;
}

.cf-c-radio-card__icon {
    width: $cf-size-icon-xl;
    height: $cf-size-icon-xl;
    color: $cf-color-primary;
}

.cf-c-radio-card__label {
    display: flex;
    position: relative;
    font-size: $cf-font-size-stack;
    padding: $cf-gutter $cf-gutter $cf-gutter $cf-size-radio-card-left-space;
}

.cf-c-radio-card__dot-wrapper {
    @include flex-center;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: $cf-size-radio-card-left-space;
}

.cf-c-radio-card__dot {
    @include flex-center;
    width: 1rem;
    height: 1rem;
    border: 2px solid $cf-color-primary;
    border-radius: $cf-radius-round;
    pointer-events: none;

    &:after {
        content: '';
        display: block;
        width: 0.5rem;
        height: 0.5rem;
        border-radius: $cf-radius-round;
        background-color: $cf-color-primary;
        opacity: 0;
    }
}

.cf-c-radio-card__desc {
    padding: 0 $cf-gutter $cf-gutter-lg $cf-size-radio-card-left-space;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}

// list styles

.cf-c-radio-card-list-inline {
    display: flex;
    flex-wrap: wrap;
}

.cf-c-radio-card-list-inline__item {
    display: inline-flex;
    min-width: 0;
    margin-right: $cf-gutter * 0.5;
    margin-bottom: $cf-gutter-sm;
}
