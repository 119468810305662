/**
 * @name Conformio - Document wizard sidebar
 *
 */

.cf-c-sidebar-doc-wiz {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-right: $cf-gutter-sm;
}

.cf-c-sidebar-doc-wiz__heading {
    min-height: 3.75rem;
    margin: 0;
}

.cf-c-sidebar-doc-wiz__content {
    flex-grow: 1;
    position: relative;
}

.cf-c-sidebar-doc-wiz__content-inner {
    @include absolute-full;
    @include cf-box();
    overflow: auto;
}
