/**
 * @name Conformio - SASS variables
 */

/* Font */

$cf-font-stack: 'OpenSans', sans-serif;
$cf-font-documents: 'Carlito', 'OpenSans', sans-serif;

$cf-font-size-stack: 1rem;
$cf-font-size-xxl: 1rem * 2.5; // 40px;
$cf-font-size-34: 1rem * 2.125; // 34px;
$cf-font-size-xl: 1rem * 2; // 32px;
$cf-font-size-31: 1rem * 1.93;
$cf-font-size-lg: 1rem * 1.5; // 24px;
$cf-font-size-md: 1rem * 1.25; // 20px;
$cf-font-size-09: 1rem * 0.9;
$cf-font-size-14: 1rem * 0.875;
$cf-font-size-sm: 1rem * 0.75; // 12px;
$cf-font-size-xs: 1rem * 0.625; // 10px;

$cf-font-size-document-text: 1rem; // 16px
$cf-font-size-document-heading-lg: 1rem * 1.5; // 24px
$cf-font-size-document-heading-md: 1rem * 1.125; // 18px

$cf-font-line-height-stack: 1.5;
$cf-font-line-height-sm: 1.3;
$cf-font-line-height-xs: 1.2;

/* Radius */

$cf-radius-round: 50%;
$cf-radius-pill: 100px;
$cf-radius: 4px;
$cf-radius-sm: 2px;
$cf-radius-lg: 0.5rem;
$cf-radius-xl: 0.75rem;
$cf-radius-xxl: 1.5rem;
$cf-radius-xxxl: 2rem;
$cf-radius-xxxxl: 4rem;

/* Shadows */

$cf-c-shadow-default: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$cf-c-shadow-inset: inset 0 4px 0 rgba(0, 0, 0, 0.05);
$cf-c-shadow-lg: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
$cf-c-shadow-progress-bar-micro: 0 2px 4px 0 rgba(197, 184, 184, 0.1);
$cf-c-shadow-app-dropdown: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px 0 rgba(0, 0, 0, 0.2);
$cf-c-shadow-modal-box: 0 4px 10px 0 rgba(0, 0, 0, 0.3),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
$cf-c-shadow-accordion-item: 0 -4px 6px 0 rgba(0, 0, 0, 0.1);
$cf-c-shadow-centred: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

/* Animation speeds */

$cf-animation-speed-default: 0.6s;
$cf-animation-speed-fast: 0.3s;
