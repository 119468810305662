/**
 * @name Conformio - Discussion discussion card
 *
 */

.cf-c-single-discussion-card {
    // inherited modifiers
    .cf-c-discussions-box.cf-is-active & {
        .cf-c-single-discussion-card__username {
            color: $cf-color-primary;
        }

        .cf-c-single-discussion-card__comment {
            border: 1px solid $cf-color-discussion-card-border-active;
        }

        .cf-c-single-discussion-card__main {
            &:before {
                background-color: $cf-color-replies-left-indent-active;
            }
        }
    }

    .cf-c-discussions-box__replies & {
        .cf-c-single-discussion-card__main {
            position: relative;
            padding-left: $cf-gutter;

            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 0;
                bottom: -$cf-gutter;
                left: $cf-gutter-sm;
                width: $cf-gutter-sm;
                background-color: $cf-color-gray;
            }
        }
    }

    .cf-c-discussions-box__item:first-child & {
        .cf-c-single-discussion-card__main {
            &:before {
                border-top-left-radius: $cf-radius;
                border-top-right-radius: $cf-radius;
            }
        }
    }

    .cf-c-discussions-box__item:last-child & {
        .cf-c-single-discussion-card__main {
            &:before {
                bottom: 0;
                border-bottom-right-radius: $cf-radius;
                border-bottom-left-radius: $cf-radius;
            }
        }
    }
    // end of inherited modifiers
}

.cf-c-single-discussion-card__heading {
    display: flex;
    justify-content: space-between;
    min-height: 1.5rem;
    margin: $cf-gutter-sm;
}

.cf-c-single-discussion-card__username {
    min-width: 0;
    margin-bottom: 0;
    padding: $cf-gutter-xs 0;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
    font-weight: bold;
    word-break: break-word;
}

.cf-c-single-discussion-card__add-comment-title {
    color: $cf-color-gray-03;
    font-weight: bold;
}

.cf-c-single-discussion-card__heading-action {
    @include flex-align-center;
    align-self: flex-start;
    color: $cf-color-primary;
    margin-left: $cf-gutter-md;
}

.cf-c-single-discussion-card__comment {
    border: 1px solid $cf-color-border-main;
    border-radius: $cf-radius;
    padding: $cf-gutter-sm;
    margin: 0 $cf-gutter-sm $cf-gutter-sm;
    font-size: $cf-font-size-sm;
    word-break: break-word;
}

.cf-c-single-discussion-card__date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: $cf-gutter;
    padding: 0 $cf-gutter-sm;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}

.cf-c-single-discussion-card__add-comment {
    padding: 0 $cf-gutter-sm $cf-gutter-sm;
}
