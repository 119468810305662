/**
 * @name Conformio - Modal explorer breadcrumbs
 *
 */

.cf-c-modal-explorer-breadcrumbs {
    @include flex-align-center;
    padding: $cf-gutter-xs;
    background-color: $cf-color-gray;
}

.cf-c-modal-explorer-breadcrumbs__item {
    @include flex-align-center;
    color: $cf-color-gray-03;
}

.cf-c-modal-explorer-breadcrumbs__separator {
    width: 0.25rem;
    height: 0.25rem;
    border-radius: 0.25rem;
    background-color: $cf-color-gray-03;
    margin: 0 $cf-gutter-sm;
}
