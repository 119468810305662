.custom-paragraph {
    margin: 1rem 0;

    &__box {
        padding: $cf-gutter-125;
    }

    &__paragraph {
        white-space: pre-line;
        line-break: anywhere;

        &-text {
            display: block;

            &.add-background {
                background-color: $color-primary-zeta;
            }
        }

        .custom-paragraph__action-block {
            margin: $cf-gutter-sm 0 0 0;
        }

        .btn {
            z-index: 1;
        }
    }

    &__text-label {
        display: block;
        color: $color-primary-beta;
        font-weight: 700;
    }

    // Min height should be equal to 3 * line height + bottom and top padding + border
    $text-block-min-height: calc(
        (3 * #{$cf-font-line-height-sm} * #{$cf-font-size-stack}) +
            (2 * #{$cf-gutter-sm}) + 2px
    );

    &__text-block {
        min-height: $text-block-min-height;
        line-height: $cf-font-line-height-sm;
        padding: $cf-gutter-sm $cf-gutter-075;
        border: 1px solid $color-primary-beta;
        border-radius: 4px;
        white-space: pre-line;
    }

    &__textarea {
        textarea {
            min-height: $text-block-min-height;
            padding: $cf-gutter-sm $cf-gutter-075;
            border: 1px solid $color-gray-alpha;
            border-radius: 4px;
            font-size: inherit;
            line-height: $cf-font-line-height-sm;

            &:focus {
                color: $color-primary-epsilon;
                border-color: $color-primary-gamma;
                box-shadow: none;
            }

            &::placeholder {
                color: $color-gray-beta;
            }
        }

        .char-counter {
            margin: $cf-gutter-xs 0 0 0;
        }
    }

    &__action-block {
        margin: $cf-gutter-125 0 0 0;
        display: flex;
        justify-content: flex-end;

        &.flex-start {
            justify-content: flex-start;
        }

        &.space-between {
            justify-content: space-between;
        }
    }

    &__btn-group {
        display: flex;

        button + button {
            margin-left: $cf-gutter-125;
        }
    }
}
