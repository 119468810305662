.cf-c-preonboarding {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.cf-c-preonboarding__heading {
    margin-top: 3.5rem;
    text-align: center;
}

.cf-c-preonboarding__title {
    color: $cf-color-gray-03;
    font-family: 'OpenSans';
    font-size: $cf-font-size-xl;
    letter-spacing: 0;
    line-height: $cf-font-line-height-stack;
    margin-bottom: $cf-gutter;
}

.cf-c-preonboarding__subtitle {
    font-size: $cf-font-size-lg;
    line-height: 1.6;
    color: $cf-color-black;
    margin-bottom: 2.5rem;
}

.cf-c-preonboarding__form-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: $cf-gutter-lg $cf-gutter;
    box-shadow: $cf-c-shadow-default;
    background-color: $cf-color-white;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-gray-01;

    .cf-c-preonboarding__company {
        .cf-c-preonboarding__next {
            padding: $cf-gutter-xs $cf-gutter;
        }
    }

    .cf-c-preonboarding__next {
        padding: $cf-gutter-xs 2.5rem;
        font-size: $cf-font-size-stack;
        margin: 0 auto;
    }

    .cf-c-main-section__title {
        font-size: $cf-font-size-stack;
        margin-bottom: $cf-gutter-sm;
    }

    form {
        display: flex;
        flex-direction: column;

        &.cf-c-preonboarding__select-language {
            max-width: $cf-preonboarding-width-default;
            width: 100%;
        }

        .cf-c-main-section {
            &.cf-u-box-danger {
                margin: -$cf-gutter 0 $cf-gutter 0;
            }
        }

        .cf-c-select-native {
            width: 100%;
        }
    }
}

.cf-c-preonboarding__register {
    display: flex;
    flex-direction: column;

    .cf-c-main-section {
        margin-bottom: $cf-gutter-md;

        .cf-c-main-section__title {
            font-size: $cf-font-size-stack;
        }
    }
}

.cf-c-preonboarding__select-language,
.cf-c-preonboarding__register,
.cf-c-preonboarding__create-company {
    padding: 0;
    width: $cf-preonboarding-width-default;

    .cf-c-input,
    .cf-c-input__field {
        width: 100%;
    }
}

.cf-c-preonboarding__setup-company {
    width: $cf-preonboarding-width-max;

    .country-input {
        .cf-c-select-native {
            max-width: calc(#{$cf-preonboarding-width-default} - 2rem);
        }
    }

    .cf-c-main-section__title {
        max-width: none;
    }
}

.cf-c-preonboarding__thank-you {
    padding: 3.5rem 0;
    text-align: center;

    > h1 {
        font-size: $cf-font-size-xl;
        line-height: 1.5;
        color: $cf-color-gray-03;
        margin-bottom: 5rem;
    }

    > h2 {
        text-align: center;
        font-size: $cf-font-size-stack;
        line-height: 1.5;
        font-weight: 400;
        max-width: 30rem;
    }
}
