/**
 * @name Conformio - Vertical switch options
 *
 */

.cf-c-v-switch-options {
    display: flex;
}

.cf-c-v-switch-options__items {
    display: flex;
    flex-direction: column;
    margin-right: $cf-gutter-075;
}

.cf-c-v-switch-options__item {
    @include flex-align-center;
    flex: 1 0 0;
    cursor: pointer;
    user-select: none;
    min-width: 5.75rem;

    &.cf-is-selected {
        font-weight: bold;
    }
}
