/**
 * @name Conformio - Underlined collapsible
 *
 */

.cf-c-underlined-collapsible {
    &:not(:last-child) {
        margin-bottom: $cf-gutter;
    }

    &.cf-c-underlined-collapsible--height-sm {
        .cf-c-underlined-collapsible__header {
            min-height: 1.8rem;
        }
    }
}

.cf-c-underlined-collapsible__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 2.5rem;
    border-bottom: 1px solid $cf-color-primary;
}

.cf-c-underlined-collapsible__header-text,
.cf-c-underlined-collapsible__header-action {
    color: $cf-color-primary;
    font-weight: bold;
}

.cf-c-underlined-collapsible__header-action {
    @include button-clear;
    display: flex;

    &:active {
        outline: none;
    }
}
