/**
 * @name Conformio - Pagination
 *
 */

.cf-c-pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0 $cf-gutter;
    background-color: $cf-color-primary-light;
}

.cf-c-pagination__info {
    margin: $cf-gutter-sm;
}

.cf-c-pagination__actions {
    @include flex-align-center;
    flex-wrap: wrap;
}

.cf-c-pagination__action-item {
    padding: $cf-gutter $cf-gutter-sm;
}
