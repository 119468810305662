/**
 * @name Conformio - Draggable item
 *
 */

.cf-c-draggable-item {
    display: flex;
    border: 1px solid $cf-color-gray-01;

    &.cf-c-draggable-item--lg {
        .cf-c-draggable-item__drag-area {
            width: 1.5rem;
        }
    }

    &.cf-c-draggable-item--shadowed {
        box-shadow: $cf-c-shadow-default;
    }
}

.cf-c-draggable-item__drag-area {
    @include button-clear;
    @include flex-center;
    flex-shrink: 0;
    width: 1rem;
    background-color: $cf-color-gray-01;
    overflow: hidden;
    color: $cf-color-gray-02;

    &:not(:disabled) {
        cursor: move;
    }

    &:active,
    &:focus {
        outline: none;
    }
}

.cf-c-draggable-item__ctn {
    flex: 1 1 0;
    min-width: 0;
}
