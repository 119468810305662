/**
 * @name Conformio - Adaptation section
 *
 */

.cf-c-adaptation-section__header {
    @include flex-align-center;
    justify-content: space-between;
    padding: $cf-gutter-xs $cf-gutter-sm;
    background-color: $cf-color-gray-01;
}

.cf-c-adaptation-section__header-title {
    display: block;
    margin-right: $cf-gutter-sm;
    @include text-ellipsis;
}

.cf-c-adaptation-section__action {
    @include flex-center;
    color: $cf-color-gray-02;
    width: 3.5rem;
}
