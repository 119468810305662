/**
 * Old document wizard class
 * No longer used in document wizard, but it is used in some other components across app
 */

.cf-c-dw-doc {
    display: flex;
    flex-direction: column;
    height: 100%;

    &.cf-c-dw-doc--heading-clear {
        .cf-c-dw-doc__heading {
            justify-content: flex-start;
            align-items: stretch;
            padding: 0;
        }
    }
}

.cf-c-dw-doc__heading {
    @include dw-box-heading;
    @include flex-align-center;
    justify-content: flex-end;
    padding: 0 $cf-gutter;
}

.cf-c-dw-doc__ctn {
    flex: 1;
    display: flex;
    overflow: hidden;
}

.cf-c-dw-doc__body {
    overflow: auto;
    flex: 1;
}

.cf-c-dw-doc__heading-action {
    @include flex-align-center;
    margin-left: rem(20);
}

.cf-c-dw-doc__heading-action-btn {
    &:not(:last-child) {
        margin-right: $cf-gutter-075;
    }
}
