.color-success-alpha {
    color: $color-success-alpha;
}

.color-gray-beta {
    color: $color-gray-beta;
}

.color-warning-alpha {
    color: $color-warning-alpha;
}

.color-primary-epsilon {
    color: $color-primary-epsilon;
}

.color-warning-gamma {
    color: $color-warning-gamma;
}

.color-primary-beta {
    color: $color-primary-beta;
}

.color-danger-alpha {
    color: $color-danger-alpha;
}

.background-primary-epsilon {
    background-color: $color-primary-epsilon;
}

.background-success-alpha {
    background-color: $color-success-alpha;
}

.background-gray-beta {
    background-color: $color-gray-beta;
}

.background-warning-alpha {
    background-color: $color-warning-alpha;
}

.background-warning-gamma {
    background-color: $color-warning-gamma;
}

.background-danger-alpha {
    background-color: $color-danger-alpha;
}
