/**
 * @name Conformio - Searchable list
 *
 */

.cf-c-searchable-list {
    background-color: $cf-color-white;
    position: relative;

    &.cf-c-searchable-list--shadowed {
        padding: $cf-gutter;
        border: 1px solid $cf-color-gray-01;
        border-radius: $cf-radius-lg;
        box-shadow: $cf-c-shadow-lg;
    }

    &.cf-c-searchable-list--p-sm {
        padding: $cf-gutter-sm;
    }

    &.cf-c-searchable-list--flying-list {
        .cf-c-searchable-list__items {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            z-index: $cf-z-index-searchable-list;
        }
    }

    &.cf-c-searchable-list--vulnerability {
        display: flex;
        flex-direction: column;

        .cf-c-searchable-list__search {
            .cf-c-input__field {
                font-size: 0.75rem;
                line-height: 1.33;
                border-radius: 2px 2px 0 0;
                border: 1px solid $cf-color-gray-02;
                background-color: $cf-color-gray;

                &::placeholder {
                    font-size: 0.75rem;
                    line-height: 1.33;
                }

                &:focus {
                    box-shadow: none;
                    border-color: $cf-color-focus;
                }
            }
        }

        .cf-c-searchable-list__items {
            max-height: 12.5rem;
            margin-top: -1px;
            border-radius: 0 0 2px 2px;
        }
    }
}

.cf-c-searchable-list__items {
    background-color: $cf-color-white;
    border-radius: $cf-radius-sm;
    box-shadow: $cf-c-shadow-default;
    border: 1px solid $cf-color-gray-01;
    max-height: 18rem;
    overflow-y: auto;
    overflow-x: hidden;

    &.cf-c-searchable-list__items--5items {
        max-height: 10.5rem;
    }
}
