/**
 * @name Conformio - Discussion tab content
 *
 */

.cf-c-discussions-tab-ctn {
    @include absolute-full;
    overflow: auto;
}

.cf-c-discussions-tab-ctn__header {
    @include flex-align-center;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding: $cf-gutter;
}

.cf-c-discussions-tab-ctn__header-text {
    margin-right: $cf-gutter-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
}

.cf-c-discussions-tab-ctn__body {
    padding: 0 $cf-gutter;
}

.cf-c-discussions-tab-ctn__comments {
    padding: $cf-gutter;
}

.cf-c-discussions-tab-ctn__add-comment {
    padding: $cf-gutter-075 $cf-gutter-sm;
    font-size: $cf-font-size-sm;
}
.cf-c-discussions-tab-ctn__footer-text {
    padding: 0 $cf-gutter-sm;
    font-size: $cf-font-size-sm;
    color: $cf-color-gray-03;
}
