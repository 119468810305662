/**
 * @name Conformio - Collapsible
 *
 */

.cf-c-collapsible {
    font-size: $cf-font-size-sm;
    border: 1px solid $cf-color-gray-01;

    & + & {
        border-top: none;
    }

    &.cf-c-collapsible--border-top {
        & + & {
            border-top: 1px solid $cf-color-gray-01;
        }
    }

    &.cf-c-collapsible--no-checkbox {
        & + & {
            border-top: 1px solid $cf-color-gray-01;
        }

        .cf-c-chk-collapsible__header {
            box-shadow: $cf-c-shadow-default;
        }

        .cf-c-chk-collapsible__icon-wrapper {
            border: none;
        }

        .cf-c-chk-collapsible__trigger {
            height: 100%;
            border: none;
        }

        .cf-c-chk-collapsible__checkbox {
            display: none;
        }

        .cf-c-chk-collapsible__ctn {
            .cf-c-btn {
                margin-left: auto;
            }
        }
    }

    &.cf-c-collapsible--highlighted {
        > .cf-c-collapsible__header {
            background-color: $cf-color-gray;
            font-size: $cf-font-size-stack;
            font-weight: bold;
        }
    }

    &.cf-c-collapsible--toggle-full-height {
        .cf-c-collapsible__icon-wrap {
            height: auto;
        }
    }

    &.cf-c-collapsible--horizontal {
        display: flex;

        > .cf-c-collapsible__content {
            border-top: none;
            border-left: 1px solid $cf-color-gray-01;
        }
    }

    &.cf-c-collapsible--shadow {
        border: none;
        margin-bottom: $cf-gutter-md;

        .cf-c-collapsible__header {
            box-shadow: $cf-c-shadow-default;
            border: 1px solid $cf-color-gray-01;
            padding-left: $cf-gutter-sm;
            flex-direction: row-reverse;
            font-size: $cf-font-size-stack;
            font-weight: bold;
        }

        .cf-c-collapsible__content {
            > div {
                border-bottom: 1px solid $cf-color-gray-01;
                padding: $cf-gutter 0 $cf-gutter $cf-gutter-sm;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: $cf-color-black;
                font-size: $cf-font-size-stack;
            }
        }
    }

    &.cf-is-open {
        > .cf-c-collapsible__header {
            .cf-c-collapsible__icon {
                transform: rotate(-180deg);
            }
        }
    }

    // nested borders handling (if it is the child of .cf-c-collapsible__content remove the borders)
    .cf-c-collapsible__content > & {
        border: none;
        border-bottom: 1px solid $cf-color-gray-01;

        &:last-child {
            margin-bottom: -1px;
        }
    }
}

.cf-c-collapsible__header {
    display: flex;
    min-height: $cf-size-collapsible-min-height;
    color: $cf-color-gray-03;
}

.cf-c-collapsible__icon-wrap {
    @include flex-center;
    flex-shrink: 0;
    width: $cf-size-collapsible-min-height;
    height: $cf-size-collapsible-min-height;
    cursor: pointer;
    user-select: none;
}

.cf-c-collapsible__icon {
    @include css-shape-arrow-down($size: 0.5rem);
    transition: transform $cf-animation-speed-fast ease-in-out;
}

.cf-c-collapsible__header-ctn {
    @include flex-align-center;
    flex: 1 1 0;
    align-self: flex-start;
    min-height: $cf-size-collapsible-min-height;
    padding: $cf-gutter-sm $cf-gutter-sm $cf-gutter-sm 0;
    word-break: break-word;
}

.cf-c-collapsible__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;
    border-top: 1px solid $cf-color-gray-01;
    word-break: break-word;
}

.cf-c-collapsible__empty-ctn {
    @include flex-center;
    flex: 1 1 0;
    min-height: $cf-size-collapsible-min-height;
    padding: 0 $cf-gutter;
    text-align: center;
    font-size: $cf-font-size-sm;
    font-weight: bold;
    color: $cf-color-gray-03;
    opacity: 0.21;
}

.cf-c-incident-collapsible-content-padding {
    padding: 0.875rem 0 0 1.125rem;
}

.cf-c-incident-collapsible-content-full-padding {
    padding: 0.875rem 1.125rem;
}
