/**
 * @name Conformio - Pill Tabs
 *
 */

.cf-c-pill-tabs {
    &.cf-c-pill-tabs--full-height {
        height: 100%;
        display: flex;
        flex-direction: column;

        .cf-c-pill-tabs__content {
            flex: 1;
            overflow: auto;
        }
    }
}

.cf-c-pill-tabs__items {
    display: flex;
    border-bottom: 1px solid $cf-color-gray-01;
    border-radius: $cf-radius-lg;
    overflow: hidden;
    box-shadow: $cf-c-shadow-default;
}

.cf-c-pill-tabs__item {
    @include flex-center;
    flex: 1;
    height: $cf-size-pill-tabs-height;
    color: $cf-color-gray-03;
    background-color: $cf-color-gray;

    &:hover {
        color: $cf-color-primary;
    }

    &.cf-is-active {
        color: $cf-color-primary;
        background-color: $cf-color-primary-light;
        font-weight: bold;
    }
}

.cf-c-pill-tabs__content {
    position: relative;
}
