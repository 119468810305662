/**
 * @name Conformio - Box Card
 *
 * Note: currently it's visible in document wiz / wizard tab / input mode if there is some discussion
 *
 */

.cf-c-box-card {
    padding: $cf-gutter-sm;
    background-color: $cf-color-discussion-wrap-bg;
    border-radius: $cf-radius;
    border: 1px solid $cf-color-discussion-wrap-border;

    &.cf-c-box-card--history {
        margin: $cf-gutter;
    }
}

.cf-c-box-card__title {
    margin: $cf-gutter-xs 0 $cf-gutter-075;
    text-align: center;
}
